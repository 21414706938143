import PropTypes from 'prop-types';
import React from 'react';
import { Suspense } from 'react';

import Loader from '../components/loader';
import AppRoutes from './AppRoutes';

// eslint-disable-next-line react/function-component-definition
export default function RouterComponent({ user }) {
  return (
    <Suspense fallback={<Loader />}>
      <AppRoutes user={user} />
    </Suspense>
  );
}

RouterComponent.propTypes = {
  user: PropTypes.shape({}).isRequired,
};
