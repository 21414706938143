/* eslint-disable react/no-unknown-property */
import { Canvas, extend as fiberExtend, useFrame, useLoader, useThree } from '@react-three/fiber';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ThreeMFLoader } from 'three/examples/jsm/loaders/3MFLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

fiberExtend({ OrbitControls });

const FileTypeLoaderMap = {
  stl: STLLoader,
  '3mf': ThreeMFLoader,
};

const materialColor = 0x8d5d02;
const materialEmissiveColor = 0x8d5d02;
const ambientLightColor = 0x727270;

const CustomOrbitControls = () => {
  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();

  useEffect(() => {
    controls.current = new OrbitControls(camera, domElement);
    return () => {
      controls.current.dispose();
    };
  }, [camera, domElement]);
  useFrame(() => controls.current.update());
  return null;
};

/**
 * 3D model viewer.
 */
const ModelViewer = ({ model }) => {
  const size = new Vector3();

  const cameraRef = useRef();
  const sceneRef = useRef();

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [gridSize, setGridSize] = useState(10);

  const canvasHeightForViewportSize = useMemo(() => {
    const breakpoints = { tablet: 1024, mobile: 768 };
    return viewportWidth >= breakpoints.tablet
      ? 600
      : viewportWidth >= breakpoints.mobile
        ? 500
        : 300;
  }, [viewportWidth]);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onMeshRenderUpdate = mesh => {
    if (!mesh) return;

    geometry.computeBoundingBox();
    const boundingBox = geometry.boundingBox.clone();

    boundingBox.getSize(size); // Confusing syntax, but it means get the size and assign it to `size` variable above.
    const x = -(boundingBox.max.x + boundingBox.min.x) / 2;
    const y = -(boundingBox.max.y + boundingBox.min.y) / 2;
    const z = -boundingBox.min.z;
    geometry.translate(x, y, z); // Make the model sit on the grid.

    // Get the biggest possible bounding box axis so the grid cannot possibly be smaller than the model.
    const biggestAxis = Math.max(size.x, size.y, size.z);
    setGridSize(biggestAxis);
  };

  /**
   * As the name suggests, simply gets the size of the model and adjusts the camera position so the model
   * is properly in view with a slight elevation.
   */
  const fitCameraToObject = () => {
    geometry.computeBoundingBox();
    const boundingBox = geometry.boundingBox.clone();

    const x = -(boundingBox.max.x + boundingBox.min.x) / 2;
    const y = -(boundingBox.max.y + boundingBox.min.y) / 2;
    const z = -boundingBox.min.z;

    // Automatically adjust the camera based on the size of the object
    const distance = Math.max(size.x, size.y, size.z) * 1.5;
    const cameraAngle = Math.PI / 4; // Set camera at a 45 degree angle

    cameraRef.current.position.set(
      x + distance * Math.sin(cameraAngle),
      y + distance * 0.8, // Make it slightly elevated.
      z + distance * Math.cos(cameraAngle)
    );
    cameraRef.current.lookAt(new Vector3(x, y, z)); // Focus the camera on the model.
  };

  const { content, type: fileType } = model || {};
  const geometry = useLoader(FileTypeLoaderMap[fileType], content);

  return (
    <Canvas
      camera={{
        fov: 100,
      }}
      style={{ height: canvasHeightForViewportSize }}
      onCreated={({ camera, scene }) => {
        if (scene) {
          sceneRef.current = scene; // Assign this current scene to the ref to access globally.
        }
        cameraRef.current = camera; // Assign the camera to its ref.
        if (cameraRef.current) {
          fitCameraToObject(); // Fit the object into view when the camera ref is present.
        }
      }}
    >
      <axesHelper args={[5]} />
      <gridHelper args={[gridSize * 2, gridSize * 2]} />
      <directionalLight position={[1, 0, 0]} intensity={1} />
      <directionalLight position={[0, 1, 0]} intensity={3} />
      <directionalLight position={[0, 0, 1]} intensity={3} />
      <ambientLight color={ambientLightColor} intensity={5} />
      {model && (
        <mesh
          rotation={[Math.PI / 2, Math.PI, Math.PI]}
          position={[0, 0, 0]}
          geometry={geometry}
          onUpdate={onMeshRenderUpdate}
        >
          <meshStandardMaterial color={materialColor} emissive={materialEmissiveColor} />
        </mesh>
      )}
      <CustomOrbitControls />
    </Canvas>
  );
};

ModelViewer.propTypes = {
  model: PropTypes.shape({
    content: PropTypes.string,
  }),
};

export default ModelViewer;
