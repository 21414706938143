import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  PERMANENT_CONTAINER_ACTIONS,
} from 'src/utils/constants';
import { getRouteURI } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../../utils/routes';
import PermanentContainerActionPageWrapper from './_action-wrapper';


const RelocatePermanentContainer = ({ user }) => {
  const { uuid: permanentContainerUUID } = useParams();
  const [permanentContainer, setPermanentContainer] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isLoadingSubLocations, setIsLoadingSubLocations] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [locationsState, setLocationsState] = useState({
    location: undefined,
    subLocation: undefined,
  });
  const [locations, setLocations] = useState([]);
  const [subLocations, setSubLocations] = useState([]);

  const navigate = useNavigate();

  const getInitialData = async () => {
    try {
      const getPermanentContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${permanentContainerUUID}/`).json();
      const getLocations = await api.get(`${API_RESOURCES.LOCATION}/`).json();
      const [permanentContainer, { resources: locations }] = await Promise.all([getPermanentContainer, getLocations]);
      const { resources: subLocations } = await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
        searchParams: {
          'filter[location]': permanentContainer.location,
        },
      }).json();

      setPermanentContainer(permanentContainer);

      setLocations(locations);
      setSubLocations(subLocations);
      setLocationsState({
        location: permanentContainer?.location,
        subLocation: permanentContainer?.sub_location,
      });

    } catch (error) {
      if (error.name === 'HTTPError' && error.response.status === 404) {
        setError('Failed to find batch.');
      } else {
        setError(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => void getInitialData(), []);

  const onRelocatePermanentContainer = async (permanentContainerUri, locationUri, subLocationUri) => {
    const subLocation = subLocations.find((subLocation) => subLocation.uri === locationsState.subLocation)?.name;
    const location = locations.find((location) => location.uri === locationsState.location)?.name;

    try {
      /* eslint-disable camelcase */
      const payload = {
        metadata: {
          destination_location: locationUri,
          destination_sub_location: subLocationUri,
        },
        action_type: PERMANENT_CONTAINER_ACTIONS.RELOCATE,
        source_material_container: permanentContainerUri,
      };

      setSubmitting(true);
      await api.post('material-container-action/', {
        json: payload,
      }).json();
    } catch (error_) {
      console.error(error_);
      setSubmitError(true);
      setSubmitting(false);
      return;
    }


    navigate(getRouteURI(routes.permanentContainerSuccess,
      { uuid: permanentContainerUUID },
      {
        action: PERMANENT_CONTAINER_ACTIONS.RELOCATE,
        permanentContainer: permanentContainer.uri,
        subLocation,
        location,
      }));

    return new Promise(() => {
    });
  };

  async function fetchSubLocations(locationUri) {
    try {
      setIsLoadingSubLocations(true);
      const { resources: subLocations } = await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
        searchParams: {
          'filter[location]': locationUri,
        },
      }).json();
      setSubLocations(subLocations);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingSubLocations(false);
    }
  }

  const handleChangeLocation = async (locationUri) => {
    await fetchSubLocations(locationUri);

    setLocationsState((previous) => ({
      ...previous,
      location: locationUri,
      subLocation: undefined,
    }));
  };

  const handleChangeSubLocation = async (subLocationUri) => {
    setLocationsState((previous) => ({
      ...previous,
      subLocation: subLocationUri,
    }));
  };

  return (
    <PermanentContainerActionPageWrapper
      id={permanentContainerUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={PERMANENT_CONTAINER_ACTIONS.RELOCATE}
      isLoading={isLoading}
    >
      <h2 className="header-margin">
        Relocate Permanent Container <span className="badge badge-secondary">{permanentContainer?.name}</span>
      </h2>

      <h5 className="header-margin">Select a Location and Sub-Location to store this permanent container.</h5>

      <div>
        <select
          className="form-control mt-4 mb-4"
          value={locationsState.location}
          onChange={(event) => handleChangeLocation(event.target.value)}
        >
          {locations.map((location) => (
            <option key={location?.uri} value={location?.uri}>{location?.name}</option>
          ))}
        </select>


        {isLoadingSubLocations ?
          <Loader inline className="mb-4" />
          : (
            <select
              className="form-control mb-4"
              value={locationsState.subLocation}
              disabled={!subLocations.length || isLoadingSubLocations}
              onChange={(event) => handleChangeSubLocation(event.target.value)}
            >
              <option value="">Choose...</option>
              {subLocations.map((subLocation) => (
                <option key={subLocation?.uri} value={subLocation?.uri}>{subLocation?.name}</option>
              ))}
            </select>
          )}

      </div>


      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isLoadingSubLocations || isSubmitting || !locationsState.subLocation || !subLocations.length}
        onClick={() => onRelocatePermanentContainer(permanentContainer?.uri, locationsState.location, locationsState.subLocation)}
      >
        <div className="d-flex align-items-center justify-content-center">
          Confirm
          {isSubmitting && <Loader inline className="spacer-left" showText={false} />}
        </div>

      </button>
      <Link to={getRouteURI(routes.permanentContainerDetails,
        { uuid: permanentContainerUUID })}
      >
        <button type="button" disabled={isSubmitting} className="btn btn-default btn-action">
          Cancel
        </button>
      </Link>
    </PermanentContainerActionPageWrapper>
  );
};

RelocatePermanentContainer.propTypes = {
  user: userPropType,
};

export default RelocatePermanentContainer;
