import { faCheck, faEye, faListUl, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

const NCRNotification = ({
  singleGroup,
  sendNCReviewForPiece,
  print,
  runTransformation,
  sentToNCRItems,
  setSentToNCRItems,
  printsToSendToNCR,
  splitRuns,
  wasNCRHighlighted,
  setHighlightedNCRSteps,
  currentInstruction,
  wasSentToNCR,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);
  const [requestPending, setRequestPending] = useState(false);

  const { addToast } = useToasts();

  const handleCheckPrintsSentToNCR = () => {
    /* We have some prints sent to NCR (by source run) and we should check 2 cases:

      1. Current "Instruction Group" is collapsed (singleGroup === {...}),
      and we should check if the prints inside (uris) includes in the current runTransformation.prints.
      2. Current "Instruction Group" is expanded (singleGroup === undefined), we can compare each "print" (prop)
      in the "runTransformation.prints" */

    if (singleGroup?.prints) {
      const isPrintsSentToNCR = singleGroup.prints.every(print =>
        sentToNCRItems.includes(print.uri)
      );
      return setIsSubmitted(isPrintsSentToNCR);
    }

    // Case 2: Check each print if it has been sent to the NCR.
    const isPrintSentToNCR = sentToNCRItems.includes(print.uri);
    return setIsSubmitted(isPrintSentToNCR);
  };

  useEffect(() => {
    handleCheckPrintsSentToNCR();
  }, [runTransformation, singleGroup, sentToNCRItems]);

  const handleSendNCReview = async () => {
    if (singleGroup?.collapsed && printsToSendToNCR?.length) {
      // If the current "Instruction Group" is collapsed, we should send all the prints inside (uris).
      if (sentToNCRItems.length) {
        // But of some prints have been already sent - we should exclude them.
        const printsToSend = printsToSendToNCR.filter(uri => !sentToNCRItems.includes(uri));
        await sendNCReviewForPiece(printsToSend, singleGroup?.prints[0]?.run, splitRuns);
        setHighlightedNCRSteps(prev => prev.filter(item => item !== currentInstruction.uuid));
        setSentToNCRItems(prev => [...prev, ...printsToSend]);
        return setIsSubmitted(true);
      } else {
        await sendNCReviewForPiece(printsToSendToNCR, singleGroup?.prints[0]?.run, splitRuns);
        setHighlightedNCRSteps(prev => prev.filter(item => item !== currentInstruction.uuid));
        return setSentToNCRItems(prev => [...prev, ...printsToSendToNCR]);
      }
    }

    // If the current "Instruction Group" is expanded, we should send only the current print.
    await sendNCReviewForPiece([print.uri], print.run, splitRuns);
    setHighlightedNCRSteps(prev => prev.filter(item => item !== currentInstruction.uuid));
    return setSentToNCRItems(prev => [...prev, print.uri]);
  };

  const handleSwitchDismissed = () => setIsDismissed(prev => !prev);
  const handleConfirm = async () => {
    try {
      setRequestPending(true);
      await handleSendNCReview();
      setIsSubmitted(true);

      if (singleGroup?.collapsed) {
        addToast(`These pieces have been sent to NC Review`, { appearance: 'success' });
      } else {
        addToast(`This piece has been sent to NC Review`, { appearance: 'success' });
      }
    } catch (e) {
      console.error(e);
      addToast(`Error: ${e.message}`, { appearance: 'error' });
    } finally {
      setRequestPending(false);
    }
  };

  const renderNotificationTitle = () => {
    if (isSubmitted) {
      return (
        <div className='notification-header notification-header-confirmed'>
          <FontAwesomeIcon icon={faCheck} />
          <span>Sent to NC</span>
        </div>
      );
    }

    if (isDismissed) {
      return (
        <div className='notification-header notification-header-dismissed'>
          <span>View hidden message</span>
          <FontAwesomeIcon icon={faEye} className='mr5' onClick={handleSwitchDismissed} />
        </div>
      );
    }

    return (
      <div className='notification-header notification-header-confirmed'>
        <FontAwesomeIcon icon={faListUl} />
        <span>Send to NC</span>
      </div>
    );
  };

  const renderNotificationContent = () => {
    if (isDismissed) {
      return null;
    }

    if (isSubmitted) {
      return (
        <div className='notification-content'>
          {singleGroup?.collapsed ? (
            <p>These pieces have been sent to NC Review</p>
          ) : (
            <p>This piece has been sent to NC Review</p>
          )}
        </div>
      );
    }

    return (
      <div className='notification-content'>
        <p>
          The entered value falls outside the defined threshold.
          {singleGroup?.collapsed
            ? ' These pieces will be sent to NC Review board.'
            : ' This piece will be sent to NC Review board.'}
        </p>
        <div className='d-flex justify-content-end mb10'>
          <button type='button' className='btn btn-danger mr10' onClick={handleSwitchDismissed}>
            Hide
          </button>
          <button
            type='button'
            className='btn btn-success mr15'
            disabled={requestPending}
            onClick={handleConfirm}
          >
            {requestPending ? <FontAwesomeIcon icon={faSpinner} className='mr-1' /> : 'Send'}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`notification ${wasNCRHighlighted && !wasSentToNCR ? 'ncr-highlighted-notification' : ''}`}
      style={singleGroup?.collapsed ? { left: '10%' } : {}}
    >
      {renderNotificationTitle()}
      {renderNotificationContent()}
    </div>
  );
};

NCRNotification.defaultProps = {
  singleGroup: {},
  print: {},
  printsToSendToNCR: [],
  splitRuns: true,
};

NCRNotification.propTypes = {
  singleGroup: PropTypes.shape({
    collapsed: PropTypes.bool,
    prints: PropTypes.arrayOf(
      PropTypes.shape({
        run: PropTypes.string,
      })
    ),
  }),
  print: PropTypes.shape({
    uri: PropTypes.string,
    run: PropTypes.string,
  }),
  sendNCReviewForPiece: PropTypes.func.isRequired,
  runTransformation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sentToNCRItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSentToNCRItems: PropTypes.func.isRequired,
  printsToSendToNCR: PropTypes.arrayOf(PropTypes.string),
  splitRuns: PropTypes.bool,
  wasNCRHighlighted: PropTypes.bool.isRequired,
  setHighlightedNCRSteps: PropTypes.func.isRequired,
  currentInstruction: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  wasSentToNCR: PropTypes.bool.isRequired,
};

export default NCRNotification;
