import PropTypes from 'prop-types';
import React from 'react';

import Alert from './alert.jsx';

const NotFound = ({ id, text, ...props }) => {
  return (
    <Alert variant='danger' {...props}>
      {text || <>The item was not found. You might not have permission to view it.</>}
      <br />
      {id && <small>{id}</small>}
    </Alert>
  );
};

NotFound.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
};

NotFound.defaultProps = {
  id: '',
  text: null,
};

export default NotFound;
