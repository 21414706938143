export const WORK_INSTRUCTION_THRESHOLD_ACTIONS = {
  WARNING: 'warning',
  ERROR: 'error',
  NCR_WARNING: 'warning_prompt_ncr',
  REMFG_WARNING: 'warning_prompt_remfg',
};

export const WORK_INSTRUCTION_THRESHOLD_TYPES = {
  BETWEEN: 'between',
  NOT_BETWEEN: 'not-between',
  TOLERANCE: 'tolerance',
  LESS_THAN: 'less-than',
  LESS_THAN_OR_EQUALS_TO: 'less-than-or-equals-to',
  GREATER_THAN: 'greater-than',
  GREATER_THAN_OR_EQUALS_TO: 'greater-than-or-equals-to',
  EQUALS_TO: 'equals-to',
  NOT_EQUALS_TO: 'not-equals-to',
  CONTAINS: 'contains',
  NOT_CONTAINS: 'not-contains',
};

const isBetween = (value, min, max) => {
  return value >= min && value <= max;
};

export const isWorkInstructionValidByThreshold = (value, threshold, thresholdType) => {
  let isValid = true;

  const isStringThresholdValue = threshold.value && typeof threshold.value === 'string';
  const valueToCheck = isStringThresholdValue ? String(value) : Number(value);

  switch (thresholdType) {
    case WORK_INSTRUCTION_THRESHOLD_TYPES.BETWEEN:
      isValid = isBetween(valueToCheck, threshold.lower_bound, threshold.upper_bound);
      break;
    case WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_BETWEEN:
      isValid = !isBetween(valueToCheck, threshold.lower_bound, threshold.upper_bound);
      break;
    case WORK_INSTRUCTION_THRESHOLD_TYPES.TOLERANCE:
      isValid = isBetween(
        valueToCheck,
        threshold.value - threshold.deviation,
        threshold.value + threshold.deviation
      );
      break;
    case WORK_INSTRUCTION_THRESHOLD_TYPES.LESS_THAN:
      isValid = valueToCheck < threshold.value;
      break;
    case WORK_INSTRUCTION_THRESHOLD_TYPES.LESS_THAN_OR_EQUALS_TO:
      isValid = valueToCheck <= threshold.value;
      break;
    case WORK_INSTRUCTION_THRESHOLD_TYPES.GREATER_THAN:
      isValid = valueToCheck > threshold.value;
      break;
    case WORK_INSTRUCTION_THRESHOLD_TYPES.GREATER_THAN_OR_EQUALS_TO:
      isValid = valueToCheck >= threshold.value;
      break;
    case WORK_INSTRUCTION_THRESHOLD_TYPES.EQUALS_TO:
      isValid = valueToCheck === threshold.value;
      break;
    case WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_EQUALS_TO:
      isValid = valueToCheck !== threshold.value;
      break;
    case WORK_INSTRUCTION_THRESHOLD_TYPES.CONTAINS:
      isValid = valueToCheck.includes(threshold.value);
      break;
    case WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_CONTAINS:
      isValid = !valueToCheck.includes(threshold.value);
      break;
  }

  return isValid;
};
