import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from 'src/context/UserContext';

import routes from '../utils/routes';

const PublicRoute = ({ Component, ...rest }) => {
  const { user } = useContext(UserContext);
  if (user) {
    // Redirect to the home or scan page depending on initialLogin flag
    const initialRoute = user.initialLogin ? routes.scan : routes.home;
    return <Navigate replace to={initialRoute} />;
  }

  return <Component user={user} {...rest} />;
};

PublicRoute.propTypes = {
  Component: PropTypes.elementType.isRequired,
};

export default PublicRoute;
