import PropTypes from 'prop-types';
import React from 'react';

import { api } from '../utils/api';
import { keyArrayBy } from '../utils/array';
import { API_RESOURCES, RUN_STATUS } from '../utils/constants';
import { getResourceUri } from '../utils/url';
import { useAsync } from '../utils/use-async-with-sentry';
import ActivityItem from './activity-item.jsx';
import Alert from './alert.jsx';
import Loader from './loader.jsx';
import NotFound from './not-found.jsx';

const getData = async ({ lineItemUuid, copy, filter, user }) => {
  const lineItemUri = getResourceUri(API_RESOURCES.LINE_ITEM, lineItemUuid);

  const { resources: prints } = await api
    .get('print/', {
      searchParams: {
        'filter[copy]': copy,
        // Line Item may already be deleted,
        // but prints will still be returned by uri filter
        'filter[line_item]': lineItemUri,
      },
    })
    .json();

  const runUris = prints.filter(({ run }) => run).map(({ run }) => run);

  if (runUris.length === 0) {
    return {
      events: [],
      runs: {},
      prints: {},
      users: {},
    };
  }

  const uniqueRunUris = [...new Set(runUris)];

  // All the prints are from the same piece, so those will have same workflow also
  const workflowUri = prints[0] && getResourceUri(API_RESOURCES.WORKFLOW, prints[0].workflow);

  const [{ resources: events }, { resources: runs }, processSteps] = await Promise.all([
    api
      .get('event/', {
        searchParams: {
          'filter[reference]': uniqueRunUris.join(','),
        },
      })
      .json(),
    api
      .get('run/', {
        searchParams: {
          'filter[uri]': uniqueRunUris.join(','),
        },
      })
      .json(),
    workflowUri
      ? api
          .get('process-step/', {
            searchParams: {
              'filter[workflow]': workflowUri,
            },
          })
          .json()
          .then(({ resources: processSteps }) =>
            Promise.all(
              processSteps.map(async processStep => {
                // eslint-disable-next-line camelcase
                processStep.process_type = await api
                  .get(processStep.workstation_type_uri, {
                    prefixUrl: false,
                  })
                  .json();
                return processStep;
              })
            )
          )
      : Promise.resolve([]),
  ]);

  const processStepsByUri = keyArrayBy(processSteps, 'uri');

  const printsWithProcessSteps = prints.map(print => {
    const processStepUri = print.process_step;
    // eslint-disable-next-line camelcase
    print.process_step = processStepsByUri[processStepUri];
    return print;
  });

  let users = [user];

  if (filter === 'all') {
    const uniqueUsers = [...new Set(events.map(({ user }) => user))];

    const userResponses = await Promise.all(
      uniqueUsers.map(uri => {
        return api
          .get(uri, {
            prefixUrl: false,
          })
          .json();
      })
    );

    users = userResponses;
  }

  const printEvents = events.filter(({ key, current_value: currentValue, user: eventUser }) => {
    const isStatusChange = key === 'status';
    const isProgressValue = [RUN_STATUS.inProgress, RUN_STATUS.complete].includes(currentValue);

    const isShowableEvent = isStatusChange && isProgressValue;

    if (filter === 'me') {
      return eventUser === user.uri && isShowableEvent;
    }

    return isShowableEvent;
  });

  printEvents.sort((a, b) => new Date(b.created) - new Date(a.created));

  return {
    events: printEvents,
    runs: keyArrayBy(runs, 'uri'),
    prints: keyArrayBy(printsWithProcessSteps, 'uri'),
    users: keyArrayBy(users, 'uri'),
  };
};

const ActivityItems = ({ lineItemUuid, copy, user, filter, ...props }) => {
  const { data, error, isLoading, reload } = useAsync(getData, {
    lineItemUuid,
    copy,
    filter,
    user,
    watch: filter,
  });

  if (isLoading) {
    return (
      <ol className='list-group' {...props}>
        <li className='list-group-item'>
          <Loader inline text='activity' className='justify-content-center text-muted' />
        </li>
      </ol>
    );
  }

  if (error) {
    if (error.name === 'HTTPError' && error.response.status === 404) {
      return <NotFound id={lineItemUuid} />;
    }

    return (
      <Alert variant='danger' className='small' name='processStepError'>
        There was a problem loading activity.{' '}
        <button type='button' className='btn btn-link btn-sm alert-link' onClick={reload}>
          Retry
        </button>
      </Alert>
    );
  }

  if (data.events.length === 0) {
    return (
      <ol className='list-group' {...props}>
        <li className='list-group-item'>No recent activity.</li>
      </ol>
    );
  }

  return (
    <ol className='list-group' {...props}>
      {data.events.map(event => (
        <ActivityItem
          key={event.uri}
          event={event}
          run={data.runs[event.reference]}
          user={filter === 'all' && data.users[event.user]}
          prints={data.prints}
        />
      ))}
    </ol>
  );
};

ActivityItems.propTypes = {
  lineItemUuid: PropTypes.string.isRequired,
  copy: PropTypes.number.isRequired,
  user: PropTypes.shape({}).isRequired,
  filter: PropTypes.oneOf(['all', 'me']).isRequired,
};

export default ActivityItems;
