/* eslint-disable camelcase */
import 'react-mde/lib/styles/css/react-mde-all.css';

import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';
import { useToasts } from 'react-toast-notifications';
import { api } from 'src/utils/api';
import { API_RESOURCES } from 'src/utils/constants';
import { getUuid } from 'src/utils/url';

import UserPicker from './user-picker';

const NewCommentForm = ({ updateComments, runUri, user }) => {
  const [comment, setComment] = useState('');
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const [showUserPicker, setShowUserPicker] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedTab, setSelectedTab] = React.useState('write');

  const { addToast } = useToasts();

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await api.get(`${API_RESOURCES.USERS}/`).json();
        setUsers(result?.resources || []);
      } catch (err) {
        console.error(err);
      }
    }

    fetchData();
  }, []);

  const updateMentionedUsers = (username, isNewUser) => {
    if (!isNewUser) {
      setMentionedUsers([...mentionedUsers, username]);
      setComment(`@${username} ${comment}`);
    } else {
      setMentionedUsers([...mentionedUsers.filter(user => user !== username)]);
      setComment(comment.replace(`@${username} `, ''));
    }
  };

  const postComment = async () => {
    const mentioned_users = users
      .filter(user => mentionedUsers.includes(user.username))
      .map(user => ({ uri: user.uri }));

    const payload = {
      mentioned_users,
      related_table_name: 'run',
      related_uuid: getUuid(runUri),
      text: comment,
      ...(mentioned_users.length && {
        comment_action: {
          assignee_user: mentioned_users[0]?.uri,
        },
      }),
    };

    try {
      await api
        .post(`${API_RESOURCES.COMMENT}/`, {
          json: payload,
        })
        .json();

      updateComments({
        author_name: user?.name || '',
        username: user?.username || '',
        text: comment,
        related_table_name: 'run',
        related_uuid: getUuid(runUri),
        created: new Date(),
      });

      setComment('');
      setMentionedUsers([]);
      addToast(`Comment saved`, { appearance: 'success' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='new-comment__wrapper'>
      <div className='d-flex flex-direction-row align-items-end w-100'>
        {selectedTab === 'write' && (
          <span
            className='mention-users__icon spacer-right'
            onClick={() => setShowUserPicker(true)}
          >
            @
          </span>
        )}

        <ReactMde
          className='new-comment__input'
          value={comment}
          // eslint-disable-next-line react/jsx-sort-props
          onChange={setComment}
          selectedTab={selectedTab}
          // eslint-disable-next-line react/jsx-sort-props
          onTabChange={setSelectedTab}
          generateMarkdownPreview={() => Promise.resolve(<ReactMarkdown>{comment}</ReactMarkdown>)}
          toolbarCommands={[
            ['bold', 'italic', 'quote'],
            ['ordered-list', 'unordered-list'],
          ]}
          childProps={{
            writeButton: {
              tabIndex: -1,
            },
          }}
        />

        <button
          type='button'
          className='btn btn-success new-form__submit spacer-left'
          onClick={postComment}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>

      {showUserPicker && (
        <UserPicker
          users={users}
          mentionedUsers={mentionedUsers}
          handleHide={() => setShowUserPicker(false)}
          updateMentionedUsers={updateMentionedUsers}
        />
      )}
    </div>
  );
};

NewCommentForm.propTypes = {
  updateComments: PropTypes.func.isRequired,
  runUri: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }),
};

export default NewCommentForm;
