import PropTypes from 'prop-types';
import React from 'react';
import config from 'src/utils/config';

import Alert from '../components/alert.jsx';
import Sentry from '../utils/sentry';


const ErrorPage = ({ statusCode, error, eventId, userName, userEmail }) => {
  // const error = useRouteError()
  console.error(error);
  const showReportButton = config.sentryDsn && !statusCode;
  const defaultMessage = 'Please hit the back button and try again. If you continue to have issues, please contact us.';

  return (
    <main role="main">
      <Alert name="error-page" variant="danger" className="text-left">
        <strong>Something went wrong.</strong>{' '}
        {error && (
          <div className="mt-2 mb-0">{error.message || defaultMessage}</div>
        )}
        {statusCode && (
          <div className="mt-2 mb-0">Status code: {statusCode}</div>
        )}
        {showReportButton && (
          <>
            <p className="mt-2">We’ve been automatically notified, but if you’d like to provide us with more details,
              please use the button below to submit a report.
            </p>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => Sentry.showReportDialog({
                title: 'Something went wrong.',
                subtitle: 'Our team has been notified. If you’d like to help, tell us what happened below.',
                subtitle2: '',
                labelSubmit: 'Submit Report',
                eventId,
                user: {
                  name: userName,
                  email: userEmail,
                },
              })}
            >
              Submit Report
            </button>
          </>
        )}
      </Alert>
    </main>
  );
};

ErrorPage.propTypes = {
  statusCode: PropTypes.number,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  error: PropTypes.instanceOf(Error),
  eventId: PropTypes.string,
};

ErrorPage.defaultProps = {
  statusCode: null,
  userName: '',
  userEmail: '',
  error: null,
  eventId: '',
};

export default ErrorPage;
