import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import ActionError from 'src/components/action-error';
import ActionSpinner from 'src/components/loaders/action-spinner';
import SuccessCheckmarkAnimation from 'src/components/success-checkmark-animation';
import useMediaQuery from 'src/hooks/useMediaQuery';

const ActionPanel = ({
  isVisible,
  title,
  isSubmitting,
  successMessage,
  closeActionPanel,
  errorMessage,
  children,
}) => {
  const timeoutRef = useRef(null);
  const isMobileView = useMediaQuery('(min-width: 300px) and (max-width: 600px)');

  const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const panelVariants = {
    hidden: { y: '100%' },
    visible: { y: 0 },
    exit: { y: '100%' },
  };

  const desktopPanelVariants = {
    ...panelVariants,
    hidden: { ...panelVariants.hidden, x: 0 },
    visible: { ...panelVariants.visible, x: 0 },
  };

  const variants = isMobileView ? panelVariants : desktopPanelVariants;

  useEffect(() => {
    if (!isSubmitting && successMessage) {
      // Set timeout to close the panel after 2.5 seconds
      timeoutRef.current = setTimeout(() => {
        closeActionPanel();
      }, 2500);
    }

    // Clear the timeout when component unmounts or dependencies change
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isSubmitting, successMessage, closeActionPanel]);

  const renderPanelHeader = () => {
    if (errorMessage) {
      return (
        <>
          <div>Error</div>
          <button
            type='button'
            className='btn btn-default btn-action action-panel-close-btn'
            onClick={closeActionPanel}
          >
            <FontAwesomeIcon className='action-panel-close-btn-icon' icon={faXmark} />
          </button>
        </>
      );
    }

    return title;
  };

  const renderPanelBody = () => {
    if (isSubmitting) {
      return <ActionSpinner />;
    }

    if (!isSubmitting && successMessage) {
      return (
        <>
          <SuccessCheckmarkAnimation />
          <div className='action-panel-success-message'>{successMessage}</div>
        </>
      );
    }

    if (errorMessage) {
      return (
        <>
          <ActionError />
          <div className='action-panel-error-message'>
            {errorMessage || 'Something went wrong. Please try again.'}
          </div>
        </>
      );
    }

    return children;
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <>
          <motion.div
            className='action-panel-backdrop'
            initial='hidden'
            animate='visible'
            exit='hidden'
            variants={backdrop}
            transition={{ duration: 0.3 }}
            onClick={closeActionPanel}
          />
          <motion.div
            className='action-panel'
            initial='hidden'
            animate='visible'
            exit='exit'
            variants={variants}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          >
            <div className='action-panel-header'>{renderPanelHeader()}</div>
            <div className='action-panel-body'>{renderPanelBody()}</div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

ActionPanel.defaultProps = {
  successMessage: 'Success!',
  errorMessage: '',
};

ActionPanel.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  closeActionPanel: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ActionPanel;
