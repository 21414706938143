import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';


const Thumbnail = ({ thumbnail, onClick = null }) => {
  if (!thumbnail) {
    return null;
  }

  return (
    <div role={onClick ? 'button' : 'img'} className={`thumbnail-box rounded-sm ${onClick ? 'cursor-pointer' : ''}`} onClick={onClick}>
      {onClick && (
        <div style={{ position: 'absolute', right: 5, top: 5, zIndex: 10, gap: 5 }} className="d-flex gap-2">
          <p className='font-bold'>3D</p>
          <FontAwesomeIcon style={{ marginTop: 5 }} icon={faArrowsAlt} size="sm" />
        </div>
      )}
      <div className="thumbnail-box-inside">
        <img className="thumbnail" src={thumbnail} alt="thumbnail" />
      </div>
    </div>
  );
};

Thumbnail.defaultProps = {
  onClick: null,
};

Thumbnail.propTypes = {
  thumbnail: PropTypes.string,
  onClick: PropTypes.func,
};

export default Thumbnail;
