// warning about the machine not containing any batch loaded onto it.
import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'src/components/modal.jsx';

const WarningModal = ({ onSubmit, onClose }) => {

  const onModalDismiss = () => {
    onClose();
  };

  const onModalConfirm = async () => {
    try {
      await onSubmit();
      onClose();
    } catch (error) {
      return;
    }
  };

  return (
    <Modal
      isOpen
      title="Warning"
      dismissText="Cancel"
      confirmText="Submit"
      onConfirm={onModalConfirm}
      onDismiss={onModalDismiss}
    >
      <label className="form-check-label">
        You are about to begin a run on a machine that does not have a loaded material batch.
        Do you still want to continue?
      </label>
    </Modal>
  );
};

WarningModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WarningModal;
