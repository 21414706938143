import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Alert from 'src/components/alert';
import Loader from 'src/components/loader';
import Modal from 'src/components/modal';
import RunCard from 'src/components/run-card';
import { api } from 'src/utils/api';
import {
  ALLOWED_RUN_STATUSES_FOR_TOOLING_STOCK_SCAN,
  API_RESOURCES, TOOLING_STOCK_ACTION_TYPES, TOOLING_STOCK_STATUSES,
} from 'src/utils/constants';
import routes from 'src/utils/routes';
import useQueryParams, { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import ToolActionPageWrapper from './_action-wrapper';

const ScanRun = ({ user }) => {
  const { uuid: toolingStockUUID } = useParams();
  const {
    runUUID,
  } = useQueryParams();
  const [pageState, setPageState] = useState({
    toolingStock: null,
    toolingStockHasNewStatus: false,
    toolingStockLocation: null,
    run: null,
    runLocation: null,
    runPrinter: null,
  });
  const [showRelocateModal, setShowRelocateModal] = useState(false);
  const [confirmingScannedRun, setConfirmingScannedRun] = useState(false);
  const [runInappropriateStatus, setRunInappropriateStatus] = useState(false);
  const [toolingStockHasNewStatus, setToolingStockHasNewStatus] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  const navigate = useNavigate();

  const getInitialData = async () => {
    if (!toolingStockUUID || !runUUID) return null;
    try {
      const currentRun = await api.get(`${API_RESOURCES.RUN}/${runUUID}/`).json();

      if (!currentRun) return null;

      setRunInappropriateStatus(!ALLOWED_RUN_STATUSES_FOR_TOOLING_STOCK_SCAN.includes(currentRun.status));

      const toolingStock = toolingStockUUID
        ? await api.get(`${API_RESOURCES.TOOLING_STOCK}/${toolingStockUUID}/`).json()
        : null;

      const promises = [
        api.get(`${API_RESOURCES.LOCATION}/${getUuid(currentRun.location)}/`).json(),
      ];

      if (toolingStock.location) {
        promises.push(api.get(`${API_RESOURCES.LOCATION}/${getUuid(toolingStock.location)}/`).json());
      }

      if (currentRun.printer) {
        promises.push(api.get(`${API_RESOURCES.PRINTER}/${getUuid(currentRun.printer)}/`).json());
      }

      const [
        runLocation,
        toolingStockLocation,
        runPrinter,
      ] = await Promise.all(promises);

      setToolingStockHasNewStatus(
        toolingStock.status === TOOLING_STOCK_STATUSES.NEW
      );

      setPageState({
        toolingStock,
        toolingStockHasNewStatus,
        toolingStockLocation,
        run: currentRun,
        runLocation,
        runPrinter,
      });

      setIsLoading(false);
    } catch (error) {
      if (error.name === 'HTTPError' && error.response.status === 404) {
        setError('Failed to find batch.');
      } else {
        setError(error);
      }
    }
  };

  const checkLocationMismatch = () => {
    if (!pageState.runLocation?.uri) return null;
    const toolingStockLocation = pageState.toolingStock.location;
    return toolingStockLocation !== pageState.runLocation.uri;
  };

  const handleAssignRun = async (shouldRelocate) => {
    try {
      setConfirmingScannedRun(true);

      /* eslint-disable camelcase */
      const payload = {
        metadata: {
          run: pageState.run.uri,
          relocate: shouldRelocate,
        },
        tooling_stock: pageState.toolingStock.uri,
        action_type: TOOLING_STOCK_ACTION_TYPES.PUT_INTO_USE,
      };

      // If tooling stock is new, we want to move to Ready For Use first
      // BE doesn't allow moving directly from New -> In Use
      if (toolingStockHasNewStatus) {
        const newPayload = {
          tooling_stock: pageState.toolingStock.uri,
          action_type: TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE,
          metadata: {
            destination_location: pageState.run.location,
            destination_sub_location: null,
          },
        };

        await api.post(`${API_RESOURCES.TOOLING_STOCK_ACTION}/`, {
          json: newPayload,
        }).json();
      }

      await api.post(`${API_RESOURCES.TOOLING_STOCK_ACTION}/`, {
        json: payload,
      }).json();

    } catch (error_) {
      console.error(error_);
      setError(error_.message);
      setSubmitError(true);
      setConfirmingScannedRun(false);
      return;
    }
    setConfirmingScannedRun(false);
    navigate(getRouteURI(routes.run,
      { uuid: getUuid(pageState.run.uri) }));

    return new Promise(() => {
    });
  };

  const onConfirmScannedRun = async () => {
    if (pageState.run && pageState.toolingStock) {
      const shouldSuggestRelocate = checkLocationMismatch();

      if (shouldSuggestRelocate) {
        setShowRelocateModal(true);
        return;
      }
      return handleAssignRun(false);
    }
  };

  const onRelocateModalConfirm = async () =>
    await handleAssignRun(true);

  const onRelocateModalDismiss = async () =>
    await handleAssignRun(false);

  const handleRefetchRunData = async () => {
    setIsRefetching(true);
    await getInitialData();
    setIsRefetching(false);
  };

  const renderLocationMismatchText = () =>
    (
      <div>
        <p>You are trying to assign a Run to the Tool, but there is a Locations mismatch. The
          Run <strong>{pageState.run?.name} ({getShortUuid(pageState.run?.uri)})</strong> is
          in <strong>{pageState.runLocation?.name}</strong> and the Tooling
          Stock <strong>{pageState.toolingStock?.name}</strong> ({getShortUuid(pageState.toolingStock?.uri)}) is
          in <strong>{pageState.toolingStockLocation?.name}</strong>.
        </p>

        <p className="mb0">
          You can <strong>Relocate and Continue</strong> to change the Tool&apos;s location
          or <strong>Continue</strong> to
          proceed without
          relocation.
        </p>
      </div>
    );

  useEffect(() => void getInitialData(), []);

  return (
    <ToolActionPageWrapper
      id={runUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={TOOLING_STOCK_ACTION_TYPES.PUT_INTO_USE}
      isLoading={isLoading}
    >
      <h2 className="header-margin">
        Scanned Run
      </h2>

      {isRefetching ?
        <Loader /> :
        <RunCard
          run={pageState.run}
          location={pageState.toolingStockLocation?.name}
          workstation={pageState.runPrinter}
        />}

      <h4 className="header-margin">Would you like to proceed with this run?</h4>

      {runInappropriateStatus && (
        <Alert name="qr-instructions" variant="warning" className="text-left d-flex align-items-center">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr15 warning-color-small" />
          <div>
            <p className="header-margin">
              The scanned run must have statuses: &apos;Queued&apos;, &apos;In Progress&apos;, &apos;Paused&apos;,
              before before the tool can be assigned to this run.
            </p>
            <p className="mb0 font-size-14">Run status changed? Please click&nbsp;
              <button
                type="button"
                className="btn btn-link btn-sm alert-link"
                onClick={handleRefetchRunData}
              >
                here
              </button>
              &nbsp;to refresh.
            </p>
          </div>
        </Alert>
      )}
      {toolingStockHasNewStatus && (
        <Alert name="qr-instructions" variant="warning" className="text-left d-flex align-items-center">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr15 warning-color-small" />
          <p>This tool has a status of <strong>New</strong>, rather than <strong>Ready for Use</strong>.
            Are you sure you want to continue?
          </p>
        </Alert>
      )}

      <Link to={getRouteURI(routes.scan,
        {},
        {
          toolUUID: toolingStockUUID,
          action: TOOLING_STOCK_ACTION_TYPES.PUT_INTO_USE,
          entity: API_RESOURCES.SUMMARY,
        })}
      >
        <button type="button" disabled={confirmingScannedRun} className="btn btn-default btn-action">
          Rescan
        </button>
      </Link>

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={confirmingScannedRun || runInappropriateStatus}
        onClick={onConfirmScannedRun}
      >
        <div className="d-flex align-items-center justify-content-center">
          Confirm
          {confirmingScannedRun && <Loader inline className="spacer-left" showText={false} />}
        </div>
      </button>

      <Modal
        isOpen={showRelocateModal}
        title="Locations Mismatch"
        dismissText="Continue"
        confirmText="Relocate and Continue"
        onConfirm={onRelocateModalConfirm}
        onDismiss={onRelocateModalDismiss}
      >
        {renderLocationMismatchText()}
      </Modal>
    </ToolActionPageWrapper>
  );
};

ScanRun.propTypes = {
  user: userPropType,
};

export default ScanRun;
