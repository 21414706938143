import { faCheckCircle, faExclamationTriangle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import {
  isWorkInstructionValidByThreshold, WORK_INSTRUCTION_THRESHOLD_ACTIONS,
} from 'src/utils/thresholds';

const WorkInstructionThreshold = ({ value, thresholdData }) => {
  // Proceed for instructions with non empty value,
  // and when threshold is set
  if (!value || !thresholdData.threshold) {
    return null;
  }

  const isValid = isWorkInstructionValidByThreshold(value, thresholdData.threshold, thresholdData.type);

  if (isValid) {
    return <FontAwesomeIcon icon={faCheckCircle} className="text-success" />;
  }

  if (thresholdData.action === WORK_INSTRUCTION_THRESHOLD_ACTIONS.WARNING) {
    return (
      <div className="text-warning">
        <FontAwesomeIcon icon={faExclamationTriangle} />
        <div>
          Warning: The entered value falls outside of the defined thresholds. Proceed with caution.
        </div>
      </div>
    );
  }

  if (thresholdData.action === WORK_INSTRUCTION_THRESHOLD_ACTIONS.ERROR) {
    return (
      <div className="text-danger">
        <FontAwesomeIcon icon={faTimesCircle} />
        <div>
          The entered value falls outside of the defined thresholds.
        </div>
      </div>
    );
  }

  return null;
};

WorkInstructionThreshold.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.number,
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })]),
  thresholdData: PropTypes.shape({
    threshold: PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      deviation: PropTypes.number,
      // eslint-disable-next-line camelcase
      lower_bound: PropTypes.number,
      // eslint-disable-next-line camelcase
      upper_bound: PropTypes.number,
    }),
    action: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

WorkInstructionThreshold.defaultProps = {
  value: '',
};

export default WorkInstructionThreshold;
