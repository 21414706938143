import { faDownLeftAndUpRightToCenter, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faExpandAlt, faToolbox } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import StatusColorCode from 'src/components/status-color-dot';
import TruncatedTitle from 'src/components/TruncatedTitle';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { STATUS_COLOR_CODE_TYPES, TOOLING_STOCK_STATUS_VERBOSE } from 'src/utils/constants';
import { getShortUuid } from 'src/utils/url';

export const ToolingStockCardSmall = ({ toolingStock, toolingType, togglePreview }) => {
  return (
    <div className='tooling-stock-card-sm'>
      <div className='tooling-stock-card-details-sm'>
        <FontAwesomeIcon icon={faToolbox} className='mr-1 mt5 tooling-stock-card-tooling-icon-sm' />
        <div className='tooling-stock-card-main-info-sm'>
          <div className='d-flex align-items-center justify-content-between'>
            <TruncatedTitle
              title={toolingStock.name}
              titleClassName='tooling-stock-card-title-sm'
              titleAdditionalTextNoTruncate={`(${getShortUuid(toolingStock.uri)})`}
              maxTextLength={28}
              maxTextLengthMobile={22}
            />
            <div className='tooling-stock-card-expand'>
              <FontAwesomeIcon icon={faExpandAlt} onClick={togglePreview} />
            </div>
          </div>
          <TruncatedTitle
            title={toolingType.name}
            titleClassName='tooling-stock-card-title-secondary-sm'
            maxTextLength={40}
            maxTextLengthMobile={32}
          />
        </div>
      </div>
    </div>
  );
};

ToolingStockCardSmall.propTypes = {
  toolingStock: PropTypes.shape({
    status: PropTypes.string,
    uri: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  toolingType: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  togglePreview: PropTypes.func.isRequired,
};

export const ToolingStockCardPreview = ({
  toolingStock,
  toolingType,
  location,
  subLocation,
  expanded: defaultExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const togglePreview = () => setExpanded(prev => !prev);

  return expanded ? (
    <ToolingStockCardFull
      location={location}
      toolingType={toolingType}
      subLocation={subLocation}
      toolingStock={toolingStock}
      togglePreview={togglePreview}
    />
  ) : (
    <ToolingStockCardSmall
      toolingStock={toolingStock}
      toolingType={toolingType}
      togglePreview={togglePreview}
    />
  );
};

ToolingStockCardPreview.defaultProps = {
  expanded: false,
};

ToolingStockCardPreview.propTypes = {
  toolingStock: PropTypes.shape({
    status: PropTypes.string,
    uri: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  toolingType: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  subLocation: PropTypes.shape({}).isRequired,
  expanded: PropTypes.bool,
};

export const ToolingStockCardFull = ({
  toolingStock,
  toolingType,
  location,
  subLocation,
  togglePreview,
}) => {
  const [locationShown, setLocationShown] = useState(false);
  const isMobileDevice = useMediaQuery('(min-width: 300px) and (max-width: 600px)');

  if (!toolingStock || !toolingType) return null;

  const toggleLocation = () => setLocationShown(previous => !previous);

  return (
    <div className='tooling-stock-card'>
      <div className='tooling-stock-card-details'>
        <FontAwesomeIcon icon={faToolbox} className='mr-1 mt5 tooling-stock-card-tooling-icon' />
        <div
          className='tooling-stock-card-main-info'
          style={togglePreview ? { width: '100%' } : {}}
        >
          <div
            className={`${togglePreview ? 'd-flex align-items-center justify-content-between' : ''}`}
          >
            <TruncatedTitle
              title={toolingStock.name}
              titleClassName={`tooling-stock-card-title ${togglePreview ? 'font-size-18' : ''}`}
              titleAdditionalTextNoTruncate={`(${getShortUuid(toolingStock.uri)})`}
              maxTextLength={25}
              maxTextLengthMobile={22}
            />
            {togglePreview && (
              <div className='tooling-stock-card-collapse'>
                <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} onClick={togglePreview} />
              </div>
            )}
          </div>
          <TruncatedTitle
            title={toolingType.name}
            titleClassName='tooling-stock-card-title-secondary'
            maxTextLength={40}
            maxTextLengthMobile={32}
          />
        </div>
      </div>
      <div className='tooling-stock-card-secondary-info'>
        {!isMobileDevice ? (
          <div className='d-flex'>
            <FontAwesomeIcon
              icon={faLocationDot}
              className='mr-1 tooling-stock-card-location-icon'
            />
            {location ? (
              <div className='tooling-stock-card-location-details'>
                <TruncatedTitle
                  title={location}
                  titleClassName='mb0 font-medium'
                  maxTextLength={25}
                  maxTextLengthMobile={20}
                />
                <TruncatedTitle
                  title={subLocation}
                  titleClassName='mb0'
                  maxTextLength={35}
                  maxTextLengthMobile={30}
                />
              </div>
            ) : (
              'N/A'
            )}
          </div>
        ) : (
          <div className='text-left'>
            <div className='cursor-pointer'>
              <p className='mb0 badge badge-secondary' onClick={toggleLocation}>
                {!locationShown ? 'Show' : 'Hide'} Location
              </p>
            </div>
            {locationShown ? (
              <div className='tooling-stock-card-location-mobile'>
                {location ? (
                  <div>
                    <div className='d-flex align-items-center'>
                      <FontAwesomeIcon className='spacer-right' icon={faLocationDot} />
                      <TruncatedTitle
                        title={location}
                        titleClassName='mb0 font-medium'
                        maxTextLength={25}
                        maxTextLengthMobile={50}
                      />
                    </div>
                    <p className='badge tooling-stock-card-sub-location-mobile'>
                      <TruncatedTitle
                        title={subLocation}
                        titleClassName='mb0'
                        maxTextLength={35}
                        maxTextLengthMobile={60}
                      />
                    </p>
                  </div>
                ) : (
                  'N/A'
                )}
              </div>
            ) : null}
          </div>
        )}
        <div className='d-flex align-items-center'>
          <p className='mb0 d-flex align-items-center'>
            <StatusColorCode
              status={toolingStock.status}
              type={STATUS_COLOR_CODE_TYPES.TOOLING_STOCK_STATUS}
              sizeClassName='small'
            />
          </p>
          <p className='mb0 spacer-left'>{TOOLING_STOCK_STATUS_VERBOSE[toolingStock.status]}</p>
        </div>
      </div>
    </div>
  );
};

ToolingStockCardFull.defaultProps = {
  togglePreview: null,
};

ToolingStockCardFull.propTypes = {
  toolingStock: PropTypes.shape({
    status: PropTypes.string,
    uri: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  toolingType: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  subLocation: PropTypes.shape({}).isRequired,
  togglePreview: PropTypes.func,
};
