import PropTypes from 'prop-types';

const IntlNumber = ({ number, locale }) => {
  const formatted = new Intl.NumberFormat(locale).format(number);
  return formatted;
};

IntlNumber.propTypes = {
  number: PropTypes.number.isRequired,
  locale: PropTypes.string,
};

IntlNumber.defaultProps = {
  locale: 'default',
};

export default IntlNumber;
