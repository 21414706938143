import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ScannedContainerPage from 'src/pages/material-container/[uuid]';
import CreateInitialBatchPage from 'src/pages/material-container/[uuid]/create-initial-batch';
import PrivateRoute from 'src/routes/PrivateRoute';

import ContainerActionPage from '../pages/material-container';
import NotFoundPage from '../pages/not-found';

const MaterialContainerRoutes = () => (
  <Routes>
    <Route index path='/' element={<PrivateRoute Component={ContainerActionPage} />} />
    <Route path='/:uuid' element={<PrivateRoute Component={ScannedContainerPage} />} />
    <Route
      path='/:uuid/create-initial-batch'
      element={<PrivateRoute Component={CreateInitialBatchPage} />}
    />
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default MaterialContainerRoutes;
