import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { isString } from 'lodash';
import PropTypes, { arrayOf } from 'prop-types';
import React, { useState } from 'react';
import WorkInstructionsCarousel from 'src/components/work-instructions-carousel';
import { WORK_INSTRUCTION_TYPES } from 'src/utils/constants';
import Sentry from 'src/utils/sentry';

import WorkInstruction from './_work-instruction.jsx';


const WorkInstructions = ({
                            instructions,
                            values,
                            beforeChange,
                            onStepInput,
                            uploading,
                            singleGroup,
                            sendNCReviewForPiece,
                            print,
                            runTransformation,
                            allPrints,
                            setSentToNCRItems,
                            sentToNCRItems,
                            savedReportsByPrintUri,
                            hasIncorrectValues,
                            savedValues,
                            setIsAbleToCompleteRun,
                            reportFilesByPrint,
                            isFileUploading,
                          }) => {
  const stepUUIDs = Object.keys(values || {});
  const lastCompletedStep = stepUUIDs[stepUUIDs.length - 1];
  const lastCompletedStepIndex = lastCompletedStep ? instructions.findIndex(
    ({ uuid }) => uuid === lastCompletedStep
  ) : 0;
  const initialIndex = Math.min((instructions.length - 1), (lastCompletedStep ? lastCompletedStepIndex + 1 : 0));
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const currentInstruction = instructions[activeIndex];
  const savedValue = savedValues ? savedValues[currentInstruction?.uuid] : null;
  const hasDifferentValue = (currentValue, savedValue) =>
    /* This function checks if the current value of the Work Instruction
       is different from the saved value of the Work Instruction.

       String check is needed (as strict comparison) to remove the quotes from
       the "Value" and "Saved Value" fields as when we put the new value ->
       it can be as string but "Saved Value" is number.
    */

    isString(currentValue) ?
      JSON.stringify(savedValue)?.replace(/"/g, '') !==
      JSON.stringify(currentValue)?.replace(/"/g, '') :
      JSON.stringify(savedValue) !== JSON.stringify(currentValue);

  if (instructions.length === 0) {
    Sentry.captureMessage('WorkInstructions component MUST not be rendered with 0 instructions', 'error');
    return null;
  }

  return (
    <WorkInstructionsCarousel
      workInstructions={instructions}
      values={values}
      handleSubmit={beforeChange}
      uploading={uploading}
      singleGroup={singleGroup}
      sendNCReviewForPiece={sendNCReviewForPiece}
      print={print}
      runTransformation={runTransformation}
      allPrints={allPrints}
      setSentToNCRItems={setSentToNCRItems}
      sentToNCRItems={sentToNCRItems}
      savedReportsByPrintUri={savedReportsByPrintUri}
      hasIncorrectValues={hasIncorrectValues}
      savedValues={savedValues}
      setIsAbleToCompleteRun={setIsAbleToCompleteRun}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      currentInstruction={currentInstruction}
      checkDifferentValue={hasDifferentValue}
      isFileUploading={isFileUploading}
    >
      {instructions.map((instruction) => {
        // Find the last report file for this instruction
        const lastFile = reportFilesByPrint && reportFilesByPrint[reportFilesByPrint.length - 1];
        const report = lastFile?.work_instruction === instruction?.uuid ? lastFile : null;
        const reportFile = report?.report_file;

        return (
          <WorkInstruction
            key={instruction.uuid}
            value={values ? values[instruction.uuid] : null}
            uuid={instruction.uuid}
            type={instruction.report_type}
            description={instruction.description}
            required={instruction.required}
            uploading={uploading?.instruction === instruction.uuid ? uploading : false}
            thresholdData={({
              threshold: instruction.threshold,
              action: instruction.threshold_action,
              type: instruction.threshold_type,
            })}
            reportUnits={instruction.report_units}
            choices={instruction.choices}
            additionalInstructionUrl={instruction.additional_instruction_url}
            savedValue={savedValue}
            currentInstruction={currentInstruction}
            checkDifferentValue={hasDifferentValue}
            reportFile={reportFile?.filename}
            onChange={(value) => onStepInput(instruction.uuid, value)}
          />
        );
      })}

    </WorkInstructionsCarousel>
  );
};


WorkInstructions.propTypes = {
  values: PropTypes.object.isRequired,
  instructions: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    report_type: PropTypes.oneOf(Object.values(WORK_INSTRUCTION_TYPES)).isRequired,
    position: PropTypes.number.isRequired,
    required: PropTypes.bool.isRequired,
    description: PropTypes.string,
    threshold: PropTypes.shape({}),
    // eslint-disable-next-line camelcase
    threshold_type: PropTypes.string,
    // eslint-disable-next-line camelcase
    threshold_action: PropTypes.string,
    // eslint-disable-next-line camelcase
    report_units: PropTypes.string,
    choices: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  beforeChange: PropTypes.func,
  onStepInput: PropTypes.func,
  uploading: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
    instruction: PropTypes.string.isRequired,
    file: PropTypes.object.isRequired,
    progress: PropTypes.number.isRequired,
  })]),
  singleGroup: PropTypes.shape({}),
  sendNCReviewForPiece: PropTypes.func.isRequired,
  print: PropTypes.shape({}),
  runTransformation: arrayOf(PropTypes.shape({})),
  allPrints: PropTypes.arrayOf(PropTypes.shape({})),
  setSentToNCRItems: PropTypes.func.isRequired,
  sentToNCRItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  savedReportsByPrintUri: PropTypes.shape({}).isRequired,
  hasIncorrectValues: PropTypes.bool.isRequired,
  savedValues: PropTypes.shape({}).isRequired,
  setIsAbleToCompleteRun: PropTypes.func.isRequired,
  reportFilesByPrint: PropTypes.arrayOf(PropTypes.shape({})),
  isFileUploading: PropTypes.bool,
};

WorkInstructions.defaultProps = {
  uploading: false,
  beforeChange: () => {
  },
  onStepInput: () => {
  },
  singleGroup: {},
  print: {},
  runTransformation: [],
  allPrints: [],
  reportFilesByPrint: [],
  isFileUploading: false,
};

export default WorkInstructions;
