import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faCommentDots } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { api } from 'src/utils/api';
import { API_RESOURCES } from 'src/utils/constants';
import { getUuid } from 'src/utils/url';

import NewCommentForm from './new-comment-form';
import SingleComment from './single-comment';

const CommentsContainer = ({ piecesByPrintUri, user, run }) => {
  const [showComments, setShowComments] = useState(false);
  const [commentsOrder, setCommentsOrder] = useState({
    order: 'desc',
    orderBy: 'created_at',
    verbose: 'Oldest First',
  });
  const [comments, setComments] = useState([]);
  const [hideOrderRelatedComments, setHideOrderRelatedComments] = useState(true);
  const nonOrderComments = comments.filter(comment => comment.related_table_name !== 'order');

  useEffect(() => {
    async function fetchData(commentRelatedUris) {
      try {
        const result = await api
          .get(`${API_RESOURCES.COMMENT}/`, {
            searchParams: {
              'filter[related_uuid]': commentRelatedUris.map(uri => getUuid(uri)),
              sort: commentsOrder.order === 'desc' ? 'created' : '-created',
            },
          })
          .json();
        setComments(result?.resources);
      } catch (err) {
        console.error(err);
      }
    }

    if (piecesByPrintUri) {
      const commentRelatedUris = [run.uri];
      const printUris = Object.keys(piecesByPrintUri);
      const pieces = printUris.map(uri => piecesByPrintUri[uri]);
      pieces.forEach(piece => {
        if (!commentRelatedUris.includes(piece.order)) {
          commentRelatedUris.push(piece.order);
        }
      });
      fetchData(commentRelatedUris);
    }
  }, [piecesByPrintUri, commentsOrder]);

  const addNewComments = newComment => {
    setComments([...comments, newComment]);
  };

  return (
    <>
      <div className='comments-icon' onClick={() => setShowComments(!showComments)}>
        <FontAwesomeIcon icon={faCommentDots} size='2x' />
      </div>
      {showComments && (
        <div className='comments-container'>
          <div className='comments-close-top' onClick={() => setShowComments(false)} />
          <div className='comments-content'>
            <div className='comments-container__header'>
              <h3 className='comments-container__header__title'>Comments:</h3>
              <Dropdown alignRight className='float-right'>
                {hideOrderRelatedComments ? (
                  <Button
                    className='spacer-right'
                    variant='primary'
                    size='sm'
                    onClick={() => setHideOrderRelatedComments(prev => !prev)}
                  >
                    <FontAwesomeIcon icon={faEye} className='spacer-right' />
                    Show Order Comments{' '}
                    <em>({Math.abs(nonOrderComments.length - comments.length)} hidden)</em>
                  </Button>
                ) : (
                  <Button
                    className='spacer-right'
                    variant='primary'
                    size='sm'
                    onClick={() => setHideOrderRelatedComments(prev => !prev)}
                  >
                    <FontAwesomeIcon icon={faEyeSlash} className='spacer-right' />
                    Hide Order Comments
                  </Button>
                )}

                <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                  {commentsOrder.verbose}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      setCommentsOrder({
                        order: 'asc',
                        orderBy: 'created_at',
                        verbose: 'Newest First',
                      })
                    }
                  >
                    Newest First
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      setCommentsOrder({
                        order: 'desc',
                        orderBy: 'created_at',
                        verbose: 'Oldest First',
                      })
                    }
                  >
                    Oldest First
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className='comments-container__body'>
              {hideOrderRelatedComments
                ? nonOrderComments.map(comment => (
                    // eslint-disable-next-line prettier/prettier
                  <SingleComment key={comment.uri} comment={comment} />
                  ))
                : comments.map(comment => <SingleComment key={comment.uri} comment={comment} />)}
            </div>
          </div>
          <NewCommentForm user={user} updateComments={addNewComments} runUri={run.uri} />
        </div>
      )}
    </>
  );
};

CommentsContainer.propTypes = {
  piecesByPrintUri: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({}),
  run: PropTypes.shape({
    orders: PropTypes.arrayOf(PropTypes.string),
    uri: PropTypes.string.isRequired,
  }),
};

export default CommentsContainer;
