import classNames from 'classnames';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ActivityItems from 'src/components/activity-items.jsx';
import Header from 'src/components/header.jsx';
import userPropType from 'src/utils/user-prop-type';


const ActivityPage = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { line_item: id, copy } = Object.fromEntries(searchParams.entries()) ?? {};
  const [filter, setFilter] = useState('me');

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <>
      <Header title="Previous Activity" user={user} />
      <main role="main">
        <div className="text-center mb-3">
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label
              className={classNames('btn', 'btn-outline-primary', {
                active: filter === 'me',
              })}
            >
              <input
                checked={filter === 'me'}
                type="radio"
                name="filter"
                value="me"
                autoComplete="off"
                onChange={handleChange}
              />
              {' '}Me
            </label>
            <label
              className={classNames('btn', 'btn-outline-primary', {
                active: filter === 'all',
              })}
            >
              <input
                checked={filter === 'all'}
                type="radio"
                name="filter"
                value="all"
                autoComplete="off"
                onChange={handleChange}
              />
              {' '}All
            </label>
          </div>
        </div>

        <ActivityItems user={user} filter={filter} lineItemUuid={id} copy={parseInt(copy, 10)} />
      </main>
    </>
  );
};

ActivityPage.propTypes = {
  user: userPropType.isRequired,
};

export default ActivityPage;
