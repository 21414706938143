import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  PERMANENT_CONTAINER_ACTIONS,
} from 'src/utils/constants';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../../utils/routes';
import PermanentContainerActionPageWrapper from './_action-wrapper';

/* This component is not ready as the BE work is not done yet,
   but the only what is left - to refactor and integrate the API
   So leaving this component with comments and not fully completed
   for now as it is already linked to the rest of the code. */
const TransferMaterial = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { uuid: permanentContainerUUID } = useParams();
  const { batch: batchUri } = Object.fromEntries(searchParams.entries()) ?? {};
  const [batch, setBatch] = useState(undefined);
  const [subLocation, setSubLocation] = useState(undefined);
  const [permanentContainer, setPermanentContainer] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);

  // eslint-disable-next-line no-unused-vars
  const [isSubmitting, setSubmitting] = useState(false);
  const [transferMaterialValue, setTransferMaterialValue] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [submitError, setSubmitError] = useState(null);
  const [remainingQuantity, setRemainingQuantity] = useState(undefined);

  const navigate = useNavigate();

  const fetchInitialData = async () => {
    try {
      const permanentContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${permanentContainerUUID}/`).json();
      const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(batchUri)}/`).json();
      const subLocation = await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batch.sub_location)}/`).json();
      setBatch(batch);
      setSubLocation(subLocation);
      setPermanentContainer(permanentContainer);
      setRemainingQuantity(batch.quantity);
    } catch (error) {
      setError(error.name);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => void fetchInitialData(), []);

  const setRemainingQuantityHandler = (changeEvent) => {
    const inputValue = changeEvent.target.value;

    if (inputValue >= 0) {
      const inputQuantity = inputValue === '' ? '' : parseFloat(inputValue);
      setTransferMaterialValue(inputQuantity);
      setRemainingQuantity(parseFloat((batch.quantity - (parseFloat(inputValue) || 0)).toFixed(2)));
    }

    // setInputValue(inputQuantity);
    // setRemainingQuantity(parseFloat((batch.quantity - inputQuantity).toFixed(2)));
  };

  const onActionSieve = async (batchUri, value) => {
    // try {
    //   const payload = {
    //     /* eslint-disable camelcase */
    //     metadata: {
    //       remaining_quantity: value,
    //     },
    //     action_type: MATERIAL_BATCH_ACTIONS.SIEVE,
    //     source_batch: batchUri,
    //     /* eslint-enable camelcase */
    //   };
    //
    //   setSubmitting(true);
    //   await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
    //     json: payload,
    //   });
    // } catch (error_) {
    //   const { errors } = await error_.response.json();
    //   setSubmitError(errors[0].title);
    //   setSubmitting(false);
    //   return;
    // }

    navigate(getRouteURI(routes.permanentContainerSuccess,
      { uuid: permanentContainerUUID },
      {
        action: PERMANENT_CONTAINER_ACTIONS.TRANSFER_MATERIAL,
        batch: getUuid(batchUri),
        quantity: value,
        subLocation: subLocation.name,
      }));


    return new Promise(() => {
    });
  };

  const remainingPercentage = batch?.quantity ? Math.round(remainingQuantity / batch.quantity * 100) : undefined;

  return (
    <PermanentContainerActionPageWrapper
      id={getUuid(batchUri)}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={PERMANENT_CONTAINER_ACTIONS.TRANSFER_MATERIAL}
      isLoading={isLoading}
    >
      <div className="d-flex align-items-center justify-content-center alert alert-info" role="alert">
        <b>Please specify material amount to be transferred to</b>
      </div>
      <p className="mb0">
        <strong>{permanentContainer?.name}</strong>
      </p>
      <div className="alert">
        <div>
          <label>Quantity:</label>
          &nbsp;<input
            name="remainingQuantity"
            min="0"
            max={batch?.quantity}
            type="number"
            value={transferMaterialValue}
            onChange={setRemainingQuantityHandler}
        />
          &nbsp;<span>{batch?.units} ({remainingPercentage}%)</span>
        </div>
        <div>
          <span>0 ({batch?.units})</span>
          &nbsp;<input
            name="remainingQuantityRange"
            min="0"
            max={batch?.quantity}
            type="range"
            value={transferMaterialValue}
            onChange={setRemainingQuantityHandler}
        />
          &nbsp;<span>{batch?.quantity} ({batch?.units})</span>
        </div>
      </div>


      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isSubmitting}
        onClick={() => onActionSieve(batch.uri, transferMaterialValue)}
      >
        Confirm
      </button>
      <Link to={getRouteURI(routes.permanentContainerDetails, { uuid: permanentContainerUUID })}>
        <button type="button" className="btn btn-default btn-action">
          Cancel
        </button>
      </Link>
    </PermanentContainerActionPageWrapper>
  );
};

TransferMaterial.propTypes = {
  user: userPropType,
};

TransferMaterial.defaultProps = {
  user: null,
};

export default TransferMaterial;
