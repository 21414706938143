import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { SCAN_BUTTON_STATES } from 'src/utils/constants';

const getButtonText = state => {
  switch (state) {
    case SCAN_BUTTON_STATES.loading:
      return (
        <>
          <div className='spinner-border spinner-border-sm mr-1' role='status' aria-hidden='true' />
          Loading...
        </>
      );
    case SCAN_BUTTON_STATES.success:
      return (
        <>
          <FontAwesomeIcon icon={faCheck} className='mr-1' />
          Scanned
        </>
      );
    default:
      return 'Scan';
  }
};

const ScanButton = ({ state, isLoading, onSubmit }) => {
  const buttonText = getButtonText(state);
  const buttonClass = state === SCAN_BUTTON_STATES.success ? 'btn-outline-success' : 'btn-primary';

  return (
    <button
      className={`btn ${buttonClass}`}
      type='button'
      id='submit-uuid'
      disabled={isLoading}
      onMouseDown={event => event.preventDefault()}
      onClick={onSubmit}
    >
      {buttonText}
    </button>
  );
};

ScanButton.propTypes = {
  state: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(ScanButton);
