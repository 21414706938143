import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import Loader from 'src/components/loader';
import { api } from 'src/utils/api';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'src/utils/constants';
import { getUuid } from 'src/utils/url';

import CollapsableCard from '../collapsable-card';
import WorkflowStep from './_workflow-step';


/**
 * Contains workflow steps for a piece
 */
const Workflow = ({ pieceURI, workflowID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [workflow, setWorkflow] = useState(null);
  const [prints, setPrints] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const [workflow, { resources: prints }] = await Promise.all([
          api.get(`${API_RESOURCES.WORKFLOW}/${workflowID}`).json(),
          api.get(`${API_RESOURCES.PRINT}/`, {
              searchParams: {
                'filter[piece]': pieceURI,
                'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
              },
            }
          ).json(),
        ]);

        setWorkflow(workflow);
        setPrints(prints);
      } catch (error) {
        addToast('There was an error. Please try again.', { appearance: 'error' });
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  if (!workflow || !prints || isLoading) {
    return <Loader />;
  }

  return (
    <CollapsableCard title={`Workflow Overview: ${workflow.name}`}>
      <Table striped bordered className="mb-0 align-left">
        <thead>
          <tr>
            <th>#</th>
            <th>Workstation</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          {
          // eslint-disable-next-line camelcase
          workflow.process_steps.map((processStep, index) => {
            const stepId = getUuid(processStep);
            const print = prints.find((print) => print.process_step === processStep);
            return <WorkflowStep key={stepId} status={print?.status} index={index} stepID={stepId} />;
          })
        }
        </tbody>
      </Table>
    </CollapsableCard>
  );
};

Workflow.propTypes = {
  pieceURI: PropTypes.string.isRequired,
  workflowID: PropTypes.string.isRequired,
};

export default Workflow;
