import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Loader from './loader';

const SubmitButton = ({ disabled, children, onClick, className, ...props }) => {
  const [loading, setLoading] = useState(false);

  const handleOnClick = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  const buttonClassNames = classNames('btn', className);

  return (
    <button
      {...props}
      type='button'
      className={buttonClassNames}
      disabled={loading || disabled}
      onClick={handleOnClick}
    >
      {loading ? <Loader inline /> : children}
    </button>
  );
};

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SubmitButton.defaultProps = {
  className: '',
  disabled: false,
};

export default SubmitButton;
