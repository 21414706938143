export const checkSupportedMaterialsMismatchForPrinter = (batch, printerType) => {
  if (!batch?.materials || !printerType?.materials) {
    return [];
  }

  const batchMaterials = batch.materials.map(material => ({
    uri: material.uri,
    name: material.name,
  }));

  let currentUnsupportedMaterials = [];

  for (const batchMaterial of batchMaterials) {
    if (!printerType.materials.some(printerMaterial => printerMaterial === batchMaterial.uri)) {
      currentUnsupportedMaterials.push(batchMaterial.name);
    }
  }

  // If we have at least one unsupported material, return the name of the materials.
  if (currentUnsupportedMaterials.length) {
    return currentUnsupportedMaterials;
  }

  // Return [] if there are no unsupported materials.
  return [];
};
