import { faFastBackward } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import ActionContext from 'src/components/action-context';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import MaterialBatchSuccessCardDetails from 'src/components/material-batch-success-card-details';
import NotFound from 'src/components/not-found';
import ScanButton from 'src/components/scan-button';
import { ActionLink } from 'src/pages/material-batch/[uuid]/success';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS, PERMANENT_CONTAINER_ACTIONS,
  PERMANENT_CONTAINER_ACTIONS_VERBOSE,
} from 'src/utils/constants';
import routes from 'src/utils/routes';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';


const PermanentContainerSuccessPage = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { uuid: permanentContainerUUID } = useParams();
  const {
    batch: batchUUID,
    action,
    sampleName,
    subLocation,
    location,
    printer: printerUri,
  } = Object.fromEntries(searchParams.entries()) ?? {};
  const [isLoading, setIsLoading] = useState(true);
  const [pageState, setPageState] = useState({
    id: batchUUID,
    batch: null,
    batchLoadedToPrinter: null,
    scannedContainerBatches: null,
    fetchedContainersSubLocations: null,
    fetchedPermanentContainersLocations: null,
    fetchedContainers: [],
    printer: null,
    error: null,
  });

  const {
    containerActionData: { permanentContainersActionState },
  } = useContext(ActionContext);


  const UNLOAD_ACTIONS = [
    PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL,
    PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL,
    PERMANENT_CONTAINER_ACTIONS.TRANSFER_MATERIAL,
  ];

  const PERMANENT_CONTAINER_ACTIONS_LIST = [
    PERMANENT_CONTAINER_ACTIONS.RELOCATE,
    PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
    PERMANENT_CONTAINER_ACTIONS.TOP_OFF,
    PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH,
  ];

  const isUnloadAction = UNLOAD_ACTIONS.includes(action);
  const isBatchSieveAction = action === PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH;
  const isPermanentContainerAction = PERMANENT_CONTAINER_ACTIONS_LIST.includes(action);
  const shouldShowViewPermanentContainerButton = (isPermanentContainerAction || isUnloadAction) && !isBatchSieveAction;

  const actionTitle = action === MATERIAL_BATCH_ACTIONS.RELOCATE ? 'Relocate' : 'Permanent Container';

  function getBatchApiUrl(permanentContainer = pageState?.permanentContainer) {
    if (batchUUID) return `${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`;
    if (permanentContainer?.current_batch) return `${API_RESOURCES.MATERIAL_BATCH}/${getUuid(permanentContainer.current_batch)}/`;
    return null;
  }

  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      const permanentContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${permanentContainerUUID}/`).json();
      const batchUrl = getBatchApiUrl(permanentContainer);
      const batch = batchUrl ? await api.get(batchUrl).json() : null;
      const batchSubLocation = !subLocation ? await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batch.sub_location)}/`).json() : null;
      const printer = printerUri ? await api.get(`${API_RESOURCES.PRINTER}/${getUuid(printerUri)}/`).json() : null;

      const printerBatches = printer && isUnloadAction ? await api.get(`${API_RESOURCES.MATERIAL_BATCH}/`, {
        searchParams: {
          'filter[at_machine]': printer.uri,
        },
      }).json() : null;

      const permanentContainersScanned = Object.values(permanentContainersActionState.containersScanned);

      const fetchedContainers = (isUnloadAction || isBatchSieveAction) && permanentContainersScanned?.length ?
        await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
          searchParams: {
            'filter[uri]': permanentContainersScanned.map((container) => container.uri).join(','),
          },
        }).json() :
        null;

      const scannedContainerBatches = fetchedContainers?.resources?.length ?
        await api.get(`${API_RESOURCES.MATERIAL_BATCH}/`, {
          searchParams: {
            'filter[uri]': fetchedContainers.resources.map((container) => container.current_batch).join(','),
          },
        }).json() : null;

      const fetchedPermanentContainersLocations = fetchedContainers?.resources?.length ?
        await api.get(`${API_RESOURCES.LOCATION}/`, {
          searchParams: {
            'filter[uri]': fetchedContainers.resources.map((container) => container.location).join(','),
          },
        }).json() : null;

      const fetchedPermanentContainersSubLocations = fetchedContainers?.resources?.length ?
        await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
          searchParams: {
            'filter[uri]': fetchedContainers.resources.map((container) => container.sub_location).join(','),
          },
        }).json() : null;

      setPageState({
        id: permanentContainerUUID,
        batch,
        batchLoadedToPrinter: printerBatches?.resources?.[0] || null,
        printer,
        permanentContainer,
        fetchedContainers: fetchedContainers?.resources || [],
        scannedContainerBatches: scannedContainerBatches?.resources || null,
        fetchedContainersSubLocations: fetchedPermanentContainersSubLocations?.resources || null,
        fetchedPermanentContainersLocations: fetchedPermanentContainersLocations?.resources || null,
        action,
        sampleName,
        subLocation: subLocation || batchSubLocation?.name,
        error: null,
      });
    } catch (error) {
      if (error.name === 'HTTPError') {
        setPageState((prevState) => ({
          ...prevState,
          id: batchUUID,
          error: {
            name: error.name,
            status: error.response && error.response.status,
          },
        }));
      }

    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, [batchUUID]);

  const getBatchLink = (batch = pageState.batch) => batch
    ? getRouteURI(routes.materialContainer, {}, { batch: getUuid(batch.uri) })
    : null;

  const getPermanentContainerLink = (permanentContainer = pageState.permanentContainer) => permanentContainer
    ? getRouteURI(routes.permanentContainerDetails, { uuid: getUuid(permanentContainer.uri) })
    : null;

  const ACTION_SUCCESS_MESSAGES = {
    [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: () => (
      <p className="success-action-text">
        Batch
        <ActionLink
          text={getShortUuid(pageState.batch.uri)}
          link={getBatchLink()}
        />
        was successfully loaded into permanent container
        <ActionLink
          text={pageState.permanentContainer?.name}
          link={getPermanentContainerLink()}
        />
      </p>
    ),
    [PERMANENT_CONTAINER_ACTIONS.RELOCATE]: () => (
      <p className="success-action-text">
        Permanent Container
        <ActionLink
          text={pageState.permanentContainer?.name}
          link={getPermanentContainerLink()}
        />
        was successfully relocated to&nbsp;
        <span className="badge badge-secondary">{location}</span> /&nbsp;
        <span className="badge badge-secondary">{subLocation}</span>
      </p>
    ),
    [PERMANENT_CONTAINER_ACTIONS.TOP_OFF]: () => (
      <p className="success-action-text">
        Batch
        <ActionLink
          text={getShortUuid(pageState.batch.uri)}
          link={getBatchLink()}
        />
        successfully
        topped up into permanent container
        <ActionLink
          text={pageState.permanentContainer?.name}
          link={getPermanentContainerLink()}
        />
      </p>
    ),
    [PERMANENT_CONTAINER_ACTIONS.TRANSFER_MATERIAL]: () => (
      <p className="success-action-text">
        Material was successfully transferred into permanent container
        <ActionLink
          text={pageState.permanentContainer?.name}
          link={getPermanentContainerLink()}
        />
      </p>
    ),
    [PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL]: () => (
      <p className="success-action-text">
        <strong>{pageState.batch.quantity} {pageState.batch.units}&nbsp;</strong>
        of <strong>{pageState.batch.materials[0].name}&nbsp;</strong>
        successfully unloaded from the hopper of Machine
        <ActionLink
          text={pageState.printer?.name}
          link={getRouteURI(routes.printerMaterial, { uuid: getUuid(pageState.printer?.uri) })}
        />
      </p>
    ),
    [PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH]: () => (
      <p className="success-action-text">
        <strong>{pageState.batch.quantity} {pageState.batch.units}&nbsp;</strong>
        of <strong>{pageState.batch.materials[0].name}&nbsp;</strong>
        successfully sieved from the Batch
        <ActionLink
          text={getShortUuid(pageState.batch.uri)}
          link={getBatchLink()}
        />
      </p>
    ),
    [PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL]: () => (
      <p className="success-action-text">
        <strong>{pageState.batch.quantity} {pageState.batch.units}&nbsp;</strong>
        of <strong>{pageState.batch.materials[0].name}&nbsp;</strong>
        has been reclaimed from the build chamber of Machine
        <ActionLink
          text={pageState.printer?.name}
          link={getRouteURI(routes.printerMaterial, { uuid: getUuid(pageState.printer?.uri) })}
        />
      </p>
    ),
  };

  const createMaterialBatchConfig = (title, batchProp, subLocationProp) => ({
    title,
    batch: pageState[batchProp],
    subLocation: pageState[subLocationProp],
  });

  const createMaterialBatchSuccessCardDetails = (config) => (
    <MaterialBatchSuccessCardDetails key={config.title || config?.batch?.uri} {...config} />
  );

  const PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG = {
    UNLOAD_MACHINE: [
      ...pageState.fetchedContainers.map(container => ({
        permanentContainer: container,
        customQuantity: container.quantity,
        batch: pageState.scannedContainerBatches.find(batch => batch.uri === container.current_batch),
        customLocation: pageState.fetchedPermanentContainersLocations.find(location => location.uri === container.location)?.name,
        subLocation: pageState.fetchedContainersSubLocations.find(subLocation => subLocation.uri === container.sub_location)?.name,
      })),
      createMaterialBatchConfig('Batch', 'batch', 'subLocation'),
      {
        printer: pageState.printer,
        batch: pageState.batchLoadedToPrinter,
        subLocation: pageState.subLocation,
      },
    ],
    SIEVE_BATCH: [
      ...pageState.fetchedContainers.map(container => ({
        permanentContainer: container,
        customQuantity: container.quantity,
        batch: pageState.scannedContainerBatches.find(batch => batch.uri === container.current_batch),
        customLocation: pageState.fetchedPermanentContainersLocations.find(location => location.uri === container.location)?.name,
        subLocation: pageState.fetchedContainersSubLocations.find(subLocation => subLocation.uri === container.sub_location)?.name,
      })),
      createMaterialBatchConfig('Batch', 'batch', 'subLocation'),
    ],
    DEFAULT: createMaterialBatchConfig('Batch', 'batch', 'subLocation'),
  };

  const renderCards = (config) => {
    return config.map(createMaterialBatchSuccessCardDetails);
  };

  const ACTION_SUCCESS_DATA = {
    [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: () => createMaterialBatchSuccessCardDetails(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.DEFAULT),
    [PERMANENT_CONTAINER_ACTIONS.TOP_OFF]: () => createMaterialBatchSuccessCardDetails(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.DEFAULT),
    [PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL]: () => renderCards(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.UNLOAD_MACHINE),
    [PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL]: () => renderCards(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.UNLOAD_MACHINE),
    [PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH]: () => renderCards(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.SIEVE_BATCH),
  };

  if (pageState.error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={pageState.id} />
        </main>
      </>
    );
  }

  const getPermanentContainerUrl = (uuid = permanentContainerUUID) => {
    return `/permanent-container/${uuid}`;
  };

  const getPrinterUrl = () => {
    return `/printer/${getUuid(printerUri)}/material`;
  };

  if (isLoading) {
    return <Loader />;
  }

  const renderBottomButtons = () => (
    <div className="success-buttons-bottom">
      {
        shouldShowViewPermanentContainerButton && (
          <Link
            to={isUnloadAction ? getPrinterUrl() : getPermanentContainerUrl()}
          >
            <div className="btn btn-outline-primary btn-action mt-4">
              <FontAwesomeIcon icon={faFastBackward} size="3x" className="d-block m-auto" />
              {isUnloadAction ? 'View Printer' : 'View Container'}
            </div>
          </Link>
        )
      }
      <ScanButton />
    </div>
  );

  return (
    <>
      <Header title={`${PERMANENT_CONTAINER_ACTIONS_VERBOSE[action]} Success`} user={user} />
      <main role="main" className="text-center">
        <div className="success-main">
          <div className="success-animation">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
          <p className="success-text">{actionTitle} Action Complete</p>

          <div className="success-main-content">
            {ACTION_SUCCESS_MESSAGES?.[action] && ACTION_SUCCESS_MESSAGES[action]()}

            <hr className="success-hr-line" />

            {ACTION_SUCCESS_DATA?.[action] && ACTION_SUCCESS_DATA[action]()}
            {renderBottomButtons()}
          </div>
        </div>
      </main>
    </>
  );

};

PermanentContainerSuccessPage.propTypes = {
  user: userPropType,
};

PermanentContainerSuccessPage.defaultProps = {
  user: null,
};

export default PermanentContainerSuccessPage;
