import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { Suspense } from 'react'
import { Modal } from 'react-bootstrap'
import Loader from 'src/components/loader'
import ModelViewer from 'src/components/ModelViewer'

const ModelViewerModal = ({ show, model, close }) => {
  return (
    <Modal size="lg" show={show} onHide={close}>
      <Modal.Header className='d-flex w-100 justify-content-between'>
        {model?.name}
        <FontAwesomeIcon role="button" className='action-panel-close-btn-icon cursor-pointer' icon={faXmark} onClick={close} />
      </Modal.Header>
      <Modal.Body>
        <Suspense fallback={<Loader />}>
          <ModelViewer model={model} />
        </Suspense>
      </Modal.Body>
    </Modal>
  )
}

export default ModelViewerModal

ModelViewerModal.propTypes = {
    model: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
}