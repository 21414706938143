import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import { api } from 'src/utils/api';
import config from 'src/utils/config';
import { CONTACTLESS_LOGIN_ENABLED, LOCALSTORAGE_KEYS } from 'src/utils/constants';
import routes from 'src/utils/routes';
import Sentry from 'src/utils/sentry';
import useLocalstorage from 'src/utils/useLocalStorage';
import userPropType from 'src/utils/user-prop-type';

const AboutPage = ({ user }) => {
  const [debugMode, setDebugMode] = useLocalstorage(LOCALSTORAGE_KEYS.DEBUG_MODE, false);
  const [loading, setLoading] = useState(false);

  /* Kiosk mode is
   * visible-> when the cookie(`contactlessLoginEnabled`) is set to True.
   * not visible-> when the cookie(`contactlessLoginEnabled`) is not present(deleted)
   */
  const [cookies, setCookie, removeCookie] = useCookies([CONTACTLESS_LOGIN_ENABLED]);

  /* Show Kiosk Mode checkbox, ONLY when
   * bureau-settings for contactless_logon_enabled value is set to True in rapidfab.
   * debugMode in localStorage is set to True
   */
  const [showKioskMode, setShowKioskMode] = useState(false);

  const [isTestEventSent, setTestEventSent] = useState(false);

  const onDebugModeChange = (checkboxEvent) => {
    setDebugMode(checkboxEvent.target.checked);
  };
  const onKioskModeChange = (checkboxEvent) => {
    if (checkboxEvent.target.checked) {
      setCookie(CONTACTLESS_LOGIN_ENABLED, true, { path: '/' });
    } else {
      removeCookie(CONTACTLESS_LOGIN_ENABLED);
    }
  };

  const sendTestEvent = () => {
    Sentry.captureMessage('Test Error', 'error');
    setTestEventSent(true);
  };

  useEffect(() => {
    let getBureauSettings = () => {
      setLoading(true);
      api.get(`bureau-settings`).json()
        .then((result) => {
          // eslint-disable-next-line camelcase
          setShowKioskMode(result?.resources[0]?.contactless_logon_enabled);
          setLoading(false);
        })
        .catch(() => {
          // Alert needed? (if the user is not logged in- 403 forbidden error)
          setLoading(false);
        });

    };
    getBureauSettings();

  }, []);


  return (
    <>
      <Header title="About" back={routes.home} user={user} />
      <main role="main">
        {!loading && (
          <dl>
            <dt>Environment:</dt>
            <dd>{config.environment}</dd>
            <dt>Version:</dt>
            <dd className="text-truncate" title={config.version}>
              {config.version}
            </dd>
            <dt>Commit:</dt>
            <dd className="text-truncate" title={config.commit}>
              {config.commit}
            </dd>
            <dt>API:</dt>
            <dd>{config.apiHost}</dd>
            <dt>Rapidfab:</dt>
            <dd>{config.rapidfabHost}</dd>
            <dt>Sentry Enabled:</dt>
            <dd>
              {config.sentryDsn ? 'Yes' : 'No'}
              {(config.sentryDsn && debugMode) && (
                <button
                  type="button"
                  className="btn btn-primary btn-sm ml-3"
                  disabled={isTestEventSent}
                  onClick={sendTestEvent}
                >
                  Send Test Event
                </button>
              )}
            </dd>
            <dt>Settings:</dt>
            <dd>Debug Mode: <input type="checkbox" defaultChecked={debugMode} onClick={onDebugModeChange} /></dd>
            {/* Show Kiosk Mode only if Debug Mode is ON and cookies.contactless_login_enabled is true */}
            {debugMode && showKioskMode && (
              <dd>Kiosk Mode: <input
                type="checkbox" defaultChecked={cookies.contactlessLoginEnabled}
                onClick={onKioskModeChange} />
              </dd>
            )}
          </dl>
        )}
        {loading && <Loader />}
      </main>
    </>
  );
};

AboutPage.propTypes = {
  user: userPropType,
};

AboutPage.allowLoggedOut = true;

export default AboutPage;
