import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS,
  MATERIAL_CONTAINER_STATUSES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
} from 'src/utils/constants';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../../utils/routes';
import ActionPage from './_action-wrapper';

const SplitBatch = ({ user }) => {
  const { uuid: batchUUID } = useParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageState, setPageState] = useState({
    id: batchUUID,
    batch: null,
    containers: [],
    byContainers: false,
  });
  
  const [selectedContainers, setSelectedContainers] = useState([]);
  const [availableContainers, setAvailableContainers] = useState(pageState.containers);
  const [nonEmptyContainersByUri, setNonEmptyContainersByUri] = useState({});
  
  const navigate = useNavigate();

  const reduceQuantityByContainers = (containerList) => containerList.reduce((total, uuid)=>{
    return total + nonEmptyContainersByUri[uuid]?.quantity
  },0)
  

  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`).json();
      const { EMPTY, ...containerStatusesExceptEmpty } = MATERIAL_CONTAINER_STATUSES;
      const { resources: nonEmptyContainers } = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
        searchParams: {
          'filter[current_batch]': batch.uri,
          'filter[status]': Object.values(containerStatusesExceptEmpty).join(','),
          'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
        },
      }).json();
      nonEmptyContainers.forEach((container)=>{
        setNonEmptyContainersByUri((previousState)=>({ ...previousState, [container.uri]:container }))
      })
      setPageState((prevState) => ({
        ...prevState,
        id: batchUUID,
        batch,
        containers: nonEmptyContainers.map((nonEmptyContainer) => nonEmptyContainer.uri),
        byContainers: batch.material_in_containers && nonEmptyContainers.length > 1,
      }));
      setAvailableContainers(nonEmptyContainers.map((nonEmptyContainer) => nonEmptyContainer.uri));
    } catch (error) {
      if (error.name === 'HTTPError') {
        setPageState((prevState) => ({
          ...prevState,
          id: batchUUID,
          error: {
            name: error.name,
            status: error.response && error.response.status,
          },
        }));
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, [batchUUID]);

  const setSelectedAmountHandler = (changeEvent) => {
    const value = changeEvent.target.value;

    if (value >= 0 && value <= pageState.batch.quantity) {
      setSelectedAmount(Number(changeEvent.target.value));
    }
  };

  const onActionSplitBatch = async (batchUri) => {
    let actionResult;
    try {
      /* eslint-disable camelcase */
      const payload = {
        metadata: {},
        action_type: MATERIAL_BATCH_ACTIONS.SPLIT_BATCH,
        source_batch: batchUri,
      };

      if (pageState.byContainers) {
        payload.metadata.containers_to_split = selectedContainers.map((containerUri) => {
          return { uri: containerUri };
        });
      } else {
        payload.metadata.amount_to_split = selectedAmount;
      }
      /* eslint-enable camelcase */

      setSubmitting(true);
      actionResult = await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
        json: payload,
      }).json();
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    const { metadata: { split_off_batch: splitOffBatch } } = actionResult;

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(splitOffBatch) },
      {
        action: MATERIAL_BATCH_ACTIONS.SPLIT_BATCH,
        initialBatchQuantity: pageState.batch.quantity - selectedAmount,
      }));

    return new Promise(() => {
    });
  };

  const containerClicked = (containerUuid) => {
    const updatedAvailableContainers = [...availableContainers];
    const updatedSelectedContainers = [...selectedContainers];

    const leftContainerIndex = updatedAvailableContainers.indexOf(containerUuid);
    const selectedContainerIndex = updatedSelectedContainers.indexOf(containerUuid);

    if (leftContainerIndex !== -1) {
      updatedAvailableContainers.splice(leftContainerIndex, 1);
    } else {
      updatedAvailableContainers.push(containerUuid);
    }

    if (selectedContainerIndex !== -1) {
      updatedSelectedContainers.splice(selectedContainerIndex, 1);
    } else {
      updatedSelectedContainers.push(containerUuid);
    }

    setAvailableContainers(updatedAvailableContainers);
    setSelectedContainers(updatedSelectedContainers);
  };

  if (isLoading) {
    return <Loader />;
  }

  const submitDisabled = isSubmitting ||
    (pageState.byContainers &&
      (!selectedContainers.length || !availableContainers.length)
    ) ||
    (!pageState.byContainers &&
      (!selectedAmount || selectedAmount === pageState.batch.quantity)
    );

  const selectedPercentage = Math.round(selectedAmount / pageState.batch.quantity * 100);

  const quantityBarMaximumHeight = 150;
  // Height of EMPTY space of bar. If batch is fully loaded, it's 0 value
  const sourceBatchAmountBarHeight = (selectedAmount / pageState.batch.quantity) * quantityBarMaximumHeight;
  const newBatchAmountBarHeight = quantityBarMaximumHeight - sourceBatchAmountBarHeight;

  return (
    <ActionPage
      id={pageState.id}
      user={user}
      httpError={pageState.error}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.SPLIT_BATCH}
    >
      <h2 className="header-margin">Split Batch&nbsp;
        <span className="badge badge-secondary">
          {getShortUuid(pageState.batch.uri)}
        </span>
      </h2>

      {pageState.byContainers && (
        <div className="row batch-container-split-list-row">
          <div className="col-xs-6">
            <div className="h5">Batch <span className="badge badge-secondary">{getShortUuid(pageState.batch.uri)}</span>
            </div>
            <div className="h6 header-margin">{availableContainers.length} Container(s)</div>
            <p>{`Total amount of material left ${reduceQuantityByContainers(availableContainers)} (${pageState.batch.units})`}
            </p>
            <ul className="batch-container-split-list">
              {availableContainers.map((uuid) => {
                return (
                  <li key={uuid} className="batch-container-split-list-item">
                    <button
                      type="button"
                      disabled={availableContainers.length === 1}
                      className="btn btn-outline-secondary btn-action batch-container-split-list-btn"
                      onClick={() => containerClicked(uuid)}
                    >
                      {getShortUuid(uuid)} {`(${nonEmptyContainersByUri[uuid]?.quantity} ${pageState.batch.units})`} 
                    </button>
                  </li>
              )})}
            </ul>
          </div>
          <div className="col-xs-6">
            <div className="h5">New Batch</div>
            <div className="h6 header-margin">{selectedContainers.length} Container(s)</div>
            <p>
              {`Amount of material in new batch ${reduceQuantityByContainers(selectedContainers)} (${pageState.batch.units})`}
            </p>
            <ul className="batch-container-split-list">
              {selectedContainers.map((uuid) => (
                <li key={uuid} className="batch-container-split-list-item">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-action batch-container-split-list-btn"
                    onClick={() => containerClicked(uuid)}
                  >
                    {getShortUuid(uuid)} {`(${nonEmptyContainersByUri[uuid]?.quantity} ${pageState.batch.units})`}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {!pageState.byContainers && (
        <div className="alert">
          <div>
            <label className="new-quantity-label">New batch quantity:</label>
            <input
              name="selectedAmount"
              min="0"
              max={pageState.batch.quantity}
              type="number"
              value={selectedAmount > 0 ? selectedAmount : ''}
              onChange={setSelectedAmountHandler}
            />
            <span> {pageState.batch.units} ({selectedPercentage}%)</span>
          </div>

          <div className="row batch-container-split-list-row">
            <div className="col-xs-6">
              <div className="h5 header-margin">Batch&nbsp;
                <span className="badge badge-secondary">
                  {getShortUuid(pageState.batch.uri)}
                </span>
              </div>
              <div className="batch-material-amount">
                <div style={{ paddingTop: `${sourceBatchAmountBarHeight}px`, background: 'white' }} />
              </div>
              <div
                className="h6 header-margin"
              >{pageState.batch.quantity - selectedAmount} {pageState.batch.units}
              </div>
            </div>
            <div className="col-xs-6">
              <div className="h5 header-margin">New Batch</div>
              <div className="batch-material-amount">
                <div style={{ paddingTop: `${newBatchAmountBarHeight}px`, background: 'white' }} />
              </div>
              <div className="h6 header-margin">{selectedAmount} {pageState.batch.units}</div>
            </div>
          </div>

          <div className="form-group">
            <input
              name="selectedAmountRange"
              min="0"
              max={pageState.batch.quantity}
              type="range"
              value={selectedAmount}
              onChange={setSelectedAmountHandler}
            />
          </div>
        </div>
      )}

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={submitDisabled}
        onClick={() => onActionSplitBatch(pageState.batch.uri)}
      >
        Confirm
      </button>
      <Link to={`/material-container?batch=${pageState.id}`}>
        <button type="button" className="btn btn-default btn-action">
          Cancel
        </button>
      </Link>
    </ActionPage>
  );
};

SplitBatch.propTypes = {
  user: userPropType,
};

SplitBatch.defaultProps = {
  user: null,
};

export default SplitBatch;
