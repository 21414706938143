import React from 'react';

const ActionError = () => (
  <div className='error-checkmark-wrapper'>
    <svg className='error-checkmark' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 88.4 88.4'>
      <circle className='error-checkmark_circle' cx='44.2' cy='44.2' r='42.5' fill='none' />
      <path
        className='error-checkmark_check'
        fill='none'
        d='M23.97 23.97l40.46 40.46 m0,-40.46 l-40.46,40.46'
      />
    </svg>
  </div>
);

export default ActionError;
