import PropTypes from 'prop-types';
import React from 'react';

import Icon from '/public/static/icon-192.png';

const InstallPrompt = ({ onConfirm, onDismiss }) => {
  return (
    <div className='install-prompt'>
      <p className='h5'>
        <img src={Icon} width='40' height='40' alt='' className='mr-2' />
        {/*
         */}
        Authentise QR
      </p>
      <button type='button' className='btn btn-primary float-right' onClick={onConfirm}>
        Add to Home Screen
      </button>
      <button type='button' className='close' aria-label='Close' onClick={onDismiss}>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
  );
};

InstallPrompt.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default InstallPrompt;
