import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'src/App';
import FeaturesProvider from 'src/context/FeaturesProvider';
import UserProvider from 'src/context/UserProvider';

render(
  <UserProvider>
    <FeaturesProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </FeaturesProvider>
  </UserProvider>,
  document.querySelector('#app')
);
