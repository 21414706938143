import { faChevronDown,faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { groupBy } from 'src/utils/array';
import { isUUID } from 'src/utils/isUUID';
import { isWorkInstructionValidByThreshold, WORK_INSTRUCTION_THRESHOLD_ACTIONS } from 'src/utils/thresholds';

import { GROUP_STATES } from '../index';
import PrintWithWorkInstructions from './_print-with-work-instructions';
import PrintsGroupWithWorkInstructions from './_prints-group-with-work-instructions';

/**
 * This component is used to combine list of prints into groups with equal work instructions
 * to allow user to fill in work instruction in bulk (1 input per work instruction for multiple prints)
 *
 * @param prints - List of prints
 * @param modelsByUri - List of Models keyed by URI (passed further into printWithWorkInstructions component)
 * @param lineItemsByUri - List of LineItems keyed by URI (passed further into printWithWorkInstructions component)
 * @param checklistsByPrintUri - List of checklists (work instructions list) grouped by Print URI
 * @param reportsByPrintUri - List of Reports grouped by Print URI
 * @param uploadingStateByPrintUri - List of current uploading state keyed by Print URI
 * (passed further into printWithWorkInstructions component)
 * @param handleStepInput - Callback to handle report value input
 * @param handleStepChange - Callback to handle report carousel step change
 * @param groupsState Print Groups expanded/collapsed state.
 * (Stored in parent component to be able to use it in RunMenu - Expand All/Collapse All buttons)
 * @param setGroupsState - Print Groups state setter
 * @param piecesByPrintUri - List of Pieces grouped by Print URI
 * @param sendNCReviewForPiece - Callback to send NC Review for Piece
 * @param runTransformation - Callback to run transformation
 * @param savedReportsByPrintUri - List of saved Reports grouped by Print URI
 * @param setIsAbleToCompleteRun - Callback to set isAbleToCompleteRun flag
 * @param reportFilesPrintURI - Print URI for which report file is being uploaded
 * @param isFileUploading - Flag to show if file is being uploaded
 * @param isPowderWorkflow - Flag to show if workflow is Powder
 * (Flag is true only if all groups are expanded)
 */
const GroupedPrints = ({
                         prints,
                         modelsByUri,
                         lineItemsByUri,
                         checklistsByPrintUri,
                         reportsByPrintUri,
                         uploadingStateByPrintUri,
                         handleStepInput,
                         handleStepChange,
                         groupsState,
                         setGroupsState,
                         piecesByPrintUri,
                         sendNCReviewForPiece,
                         runTransformation,
                         savedReportsByPrintUri,
                         setIsAbleToCompleteRun,
                         reportFilesPrintURI,
                         isFileUploading,
                         isPowderWorkflow,
                         labelsByPiece,
                       }) => {

  const handleUniqueReportFileNames = (reportValues) => {
    if (!reportValues) {
      return false;
    }

    /* Data contains the array of report values. Here we will map through
      all of them, get their report_file.filename values.
      [...new Set] - will contain only unique values. Which means
      if we have the same filenames, we will return true, otherwise we
      will have multiple values which means the instruction group does not
      contain the same instruction values (files) */

    const uniqueFileNames = [...new Set(reportValues.map((report) =>
      report.report_file.filename))];

    return uniqueFileNames.length === 1;
  };

  /*
     On each new file add (not uploaded yet, only added to the input field),
     we will need to substitute the file name with the existing report file name.
     It is required to check if the instruction group has the same files for all instructions,
     taking into account the new file uploaded (as the file has its name)

     In other words, the process is the following:

     1. User adds a new file (to the input field) for the instruction group.
     2. We already have the files list with the names of the files for the instruction group.
     3. We will find the index of the instruction by the print URI.
     4. We will substitute the file name with the existing file name.
     5. We will check if all files have the same name.

     This way the instruction group will be collapsible on each "File Input Field Change".
  */

  const handleRefreshReportFileByFileName = (reportValues, print, file) => {
    if (!reportValues || !print || !file) {
      return false;
    }

    /* We don't do Shallow Copy of reportValues as we will need to modify
       the reference object (which is lastUploadedFiles[workInstructionUUID]).
       The modification is required in order to track the uploaded files each time,
       to make sure on each method run, we will be able to compare the reportValues
       with all previous files uploaded instead of each time check the original
       lastUploadedFiles[workInstructionUUID] object. */


    // Find index of the report value by print URI.
    const printReportFileIndex = reportValues.findIndex((report) => report.print === print.uri);

    // If there is no report value or it does not have report_file, return false.
    if (printReportFileIndex === -1 || !reportValues[printReportFileIndex]?.report_file) {
      return false;
    }

    // Modify the reference object (lastUploadedFiles[workInstructionUUID]) with the new file name.
    reportValues[printReportFileIndex].report_file.filename = file.name;

    // Check if all report values have the same file name based on the new changes above.
    return handleUniqueReportFileNames(reportValues);
  };

  /* As we do not substitute the Report Values by the endpoint /work-instruction-report, but
     on each new file upload, we add new file and as a response, get the array of files:
     print.uri: [{file1}, {file2}, ...], we need to operate with the only latest uploaded
     files. The method below returns <work_instruction.uuid>: [{latestFile1}, {latestFile2}, ...] */

  const lastUploadedFiles = Object.values(JSON.parse(JSON.stringify(reportFilesPrintURI)))
    .reduce((accumulator, reportValues) => {
      const lastUploadedFile = reportValues[reportValues.length - 1];
      if (!accumulator[lastUploadedFile.work_instruction]) {
        accumulator[lastUploadedFile.work_instruction] = [];
      }
      accumulator[lastUploadedFile.work_instruction].push(lastUploadedFile);
      return accumulator;
    }, {});

  const isGroupCollapsible = (printsFromGroup) => {
    const printURIs = printsFromGroup.map((print) => print.uri);

    // Array of all reports of all used prints
    const groupReports = printURIs.map((printURI) => reportsByPrintUri[printURI]).flat();

    // Get the report values of all reports (array of arrays)
    const reportValues = groupReports.map((item) => Object.keys(item || {}));
    // Get the length of the first array to compare with the rest
    const firstlength = reportValues[0].length;

    // Check if the Work Instructions are the same for all prints in the group
    const hasSameLength = reportValues.every((array) => array.length === firstlength);

    const groupReportValuesByWorkInstructionUUID = groupReports.reduce(
      (result, reports) => {
        Object.keys(reports || {}).forEach((workInstructionUUID) => {
          if (!result[workInstructionUUID]) {
            result[workInstructionUUID] = new Set();
          }
          /* We need to make sure all the uploaded file names are the same,
             this way we will allow collapsing the Instruction Group */
          const uploadedFilesAreSame = handleUniqueReportFileNames(lastUploadedFiles[workInstructionUUID]);

          if (isUUID(reports[workInstructionUUID]) && hasSameLength && uploadedFilesAreSame) {
            result[workInstructionUUID].add(JSON.stringify({ uuid: true }));
          } else {
            result[workInstructionUUID].add(JSON.stringify(reports[workInstructionUUID]));
          }

        });

        return result;
      },
      {},
    );

    // returns true even if 0 reports are exist
    return Object.values(groupReportValuesByWorkInstructionUUID).every((uniqueReports) => {
      // All reports must be equal
      return uniqueReports.size === 1;
    });
  };

  const groupPrints = () => {
    // Generating unique keys for each print based on checklist URIs
    const printsWithChecklistsKey = prints.map((print) => {
      const workInstructions = checklistsByPrintUri[print.uri] && checklistsByPrintUri[print.uri].work_instructions || [];
      const workInstructionUUIDs = workInstructions.map((workInstruction) => workInstruction.uuid);
      const workInstructionsUUIDsKey = workInstructionUUIDs.join('-');
      return {
        ...print,
        // Group key is set to concatenated list of work instruction UUIDs or process step uri, if none.
        groupKey: workInstructionsUUIDsKey || print.process_step,
      };
    });

    // Grouping of prints is done based on unique checklists key
    // since prints may have equal process step uri, but custom checklists
    const printsByChecklistsKey = groupBy(printsWithChecklistsKey, 'groupKey');

    return Object.keys(printsByChecklistsKey).map((groupKey) => {
      const printsFromGroup = printsByChecklistsKey[groupKey];
      const collapsible = isGroupCollapsible(printsFromGroup);
      return {
        prints: printsFromGroup,
        collapsible,
        // Collapsing by default all collapsible items
        collapsed: collapsible,
        groupKey,
      };
    });
  };
  const [printGroups, setPrintGroups] = useState(() => groupPrints());
  const [sentToNCRItems, setSentToNCRItems] = useState(runTransformation?.length ?
    runTransformation.map((runItem) => runItem.prints).flat() : []);

  useEffect(() => {
    if (groupsState === GROUP_STATES.COLLAPSED) {
      printGroups.forEach((printsGroup, index) => {
        if (!printsGroup.collapsed) {
          toggleCollapsed(index);
        }
      });
    }
    if (groupsState === GROUP_STATES.EXPANDED) {
      printGroups.forEach((printsGroup, index) => {
        if (printsGroup.collapsed) {
          toggleCollapsed(index);
        }
      });
    }
  }, [groupsState]);


  const toggleCollapsed = (groupIndex) => {
    if (!printGroups[groupIndex].collapsible && !printGroups[groupIndex].collapsed) {
      // Do not proceed when trying to collapse non-collapsible group
      return;
    }

    const updatedPrintGroups = printGroups.map((printsGroup) => ({ ...printsGroup }));
    updatedPrintGroups[groupIndex].collapsed = !updatedPrintGroups[groupIndex].collapsed;
    setPrintGroups(updatedPrintGroups);

    // Truncating groups state to be able to use it again in useEffect
    setGroupsState(GROUP_STATES.NONE);
  };

  const hasNCRNotSentOutsideOfThreshold = (workInstructions) => {
    if (!workInstructions) {
      // No need to check if no Work Instructions provided.
      return false;
    }

    const hasIncorrectSubmittedValues = Object.fromEntries(Object.entries(savedReportsByPrintUri)
      .filter(([key, value]) => {
        const currentPrints = Object.entries(value).filter(([key, value]) => {
          const currentInstruction = workInstructions.find((instruction) => instruction.uuid === key);
          // eslint-disable-next-line camelcase
          if (currentInstruction?.threshold_action === WORK_INSTRUCTION_THRESHOLD_ACTIONS.NCR_WARNING
            // eslint-disable-next-line camelcase
            && !isWorkInstructionValidByThreshold(value, currentInstruction?.threshold, currentInstruction?.threshold_type)) {
            return key;
          }
        });

        if (currentPrints.length) {
          return key;
        }

        return null;

      }));

    if (!Object.values(hasIncorrectSubmittedValues).length) {
      return false;
    }

    const hasIncorrectNotSubmittedNCRValues =
      Object.keys(hasIncorrectSubmittedValues)
        .filter((item) => !sentToNCRItems.includes(item));

    return !!hasIncorrectNotSubmittedNCRValues.length;
  };

  const renderPrint = (print) => {
    const lineItem = lineItemsByUri[print.line_item];
    const model = (!isPowderWorkflow && lineItem) ? modelsByUri[lineItem.additive.model] : null;
    const checklist = checklistsByPrintUri[print.uri];
    const reportValues = reportsByPrintUri[print.uri];
    const savedReportValues = savedReportsByPrintUri[print.uri];
    const uploadingState = uploadingStateByPrintUri[print.uri];
    const onStepInput = (reportUUID, value) => {
      handleStepInput([print.uri], reportUUID, value);

      // Proceed to checking new value and update `collapsible` flag if needed

      const printGroupIndex = printGroups.findIndex((printsGroup) =>
        !!printsGroup.prints.find((item) => item.uri === print.uri),
      );

      const printURIsFromGroup = printGroups[printGroupIndex].prints
        .map((item) => item.uri)
        // Exclude current print URI, since the value is a new one
        .filter((printUri) => printUri !== print.uri);

      const printReportValues = printURIsFromGroup
        .map((printURI) => {
          const currentReportUUID = Object.keys(reportsByPrintUri[printURI] || {})
            .find((uuid) => uuid === reportUUID);
          return reportsByPrintUri[printURI] && reportsByPrintUri[printURI][currentReportUUID];
        });

      // Check if the current uploaded (or set) value is a File.
      const isFile = value instanceof File;
      // Add new value to the list
      printReportValues.push(value);

      const uniqueReports = printReportValues.reduce(
        // Using JSON.stringify to handle adding arrays to Set for `range` instruction type
        (result, reportValue) => result.add(JSON.stringify(reportValue)),
        new Set());

      const allFilesAreEqual = isFile ? handleRefreshReportFileByFileName(lastUploadedFiles[reportUUID], print, value) : null;
      // All reports must be equal
      const isNowCollapsible = isFile ? allFilesAreEqual : uniqueReports.size === 1;

      if (isNowCollapsible !== printGroups[printGroupIndex].collapsible) {
        // in case `collapsible` flag differs - save it
        const updatedPrintGroups = printGroups.map((printsGroup) => ({ ...printsGroup }));
        updatedPrintGroups[printGroupIndex].collapsible = isNowCollapsible;
        setPrintGroups(updatedPrintGroups);
      }
    };
    const onStepChange = (previousStepIndex) => {
      handleStepChange([print.uri], previousStepIndex);
    };
    const hasIncorrectValues = hasNCRNotSentOutsideOfThreshold(checklist?.work_instructions);

    return (
      <PrintWithWorkInstructions
        key={print.uri}
        model={model}
        print={print}
        piece={piecesByPrintUri[print.uri]}
        lineItem={lineItem}
        checklist={checklist}
        reportValuesByUUID={reportValues}
        uploadingState={uploadingState}
        sendNCReviewForPiece={sendNCReviewForPiece}
        runTransformation={runTransformation}
        setSentToNCRItems={setSentToNCRItems}
        sentToNCRItems={sentToNCRItems}
        savedReportsByPrintUri={savedReportsByPrintUri}
        hasIncorrectValues={hasIncorrectValues}
        savedReportValues={savedReportValues}
        setIsAbleToCompleteRun={setIsAbleToCompleteRun}
        reportFilesPrintURI={reportFilesPrintURI}
        isFileUploading={isFileUploading}
        label={labelsByPiece[print.piece]}
        onStepInput={onStepInput}
        onStepChange={onStepChange}
      />
    );
  };

  return printGroups.map((printsGroup, groupIndex) => {
    if (printsGroup.prints.length === 1) {
      // if there's only one print in the group, display the heading without
      // the dropdown arrow so the "group" looks consistent with the others
      return (
        <div key={printsGroup.groupKey}>
          <h4 className={`text-${isPowderWorkflow ? 'center' : 'left'}`}>
            <span className="ml-1">Instruction Group {groupIndex + 1}</span>
          </h4>
          {renderPrint(printsGroup.prints[0])}
        </div>
      );
    }
    return (
      <div key={printsGroup.groupKey}>
        <h4 className="text-left">
          <button
            className="btn"
            type="button"
            disabled={!printsGroup.collapsible}
            onClick={() => toggleCollapsed(groupIndex)}
          >
            <FontAwesomeIcon icon={printsGroup.collapsed ? faChevronRight : faChevronDown} />
          </button>
          <span className="ml-1">Instruction Group {groupIndex + 1} ({printsGroup.prints?.length} pieces)</span>
        </h4>
        {printsGroup.collapsed && (
          <PrintsGroupWithWorkInstructions
            lineItemsByUri={lineItemsByUri}
            checklistsByPrintUri={checklistsByPrintUri}
            reportsByPrintUri={reportsByPrintUri}
            modelsByUri={modelsByUri}
            uploadingStateByPrintUri={uploadingStateByPrintUri}
            handleStepInput={handleStepInput}
            handleStepChange={handleStepChange}
            printsGroup={printsGroup}
            sendNCReviewForPiece={sendNCReviewForPiece}
            runTransformation={runTransformation}
            prints={prints}
            setSentToNCRItems={setSentToNCRItems}
            sentToNCRItems={sentToNCRItems}
            savedReportsByPrintUri={savedReportsByPrintUri}
            hasNCRNotSentOutsideOfThreshold={hasNCRNotSentOutsideOfThreshold}
            setIsAbleToCompleteRun={setIsAbleToCompleteRun}
            isFileUploading={isFileUploading}
            isPowderWorkflow={isPowderWorkflow}
          />
        )}
        {!printsGroup.collapsed && printsGroup.prints.map(renderPrint)}
      </div>
    );
  });
};

GroupedPrints.propTypes = {
  prints: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    line_item: PropTypes.string.isRequired,
  })).isRequired,
  modelsByUri: PropTypes.shape({}).isRequired,
  lineItemsByUri: PropTypes.shape({}).isRequired,
  checklistsByPrintUri: PropTypes.shape({}).isRequired,
  reportsByPrintUri: PropTypes.shape({}).isRequired,
  uploadingStateByPrintUri: PropTypes.shape({}).isRequired,
  handleStepInput: PropTypes.func.isRequired,
  handleStepChange: PropTypes.func.isRequired,
  groupsState: PropTypes.string.isRequired,
  setGroupsState: PropTypes.func.isRequired,
  piecesByPrintUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  savedReportsByPrintUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  setIsAbleToCompleteRun: PropTypes.func.isRequired,
  reportFilesPrintURI: PropTypes.shape({}).isRequired,
  isFileUploading: PropTypes.bool.isRequired,
  isPowderWorkflow: PropTypes.bool.isRequired,
  labelsByPiece: PropTypes.shape({}).isRequired,
};

GroupedPrints.defaultProps = {};

export default GroupedPrints;
