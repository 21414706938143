import config from 'src/utils/config';

export const fetcher = async (path, options = {}) => {
  const uri = `${config.apiHost}/${path}/`;
  const response = await fetch(uri, {
    credentials: 'include',
    method: 'GET',
    ...options,
  });
  const payload = await response.json();
  if (!response.ok) {
    throw payload;
  }
  return payload;
};
