import { faArrowUpShortWide, faObjectGroup } from '@fortawesome/free-solid-svg-icons';
import {
  faBoxOpen,
  faClipboard,
  faExclamationCircle,
  faPercent,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import TruncatedTitle from 'src/components/TruncatedTitle';
import { getShortUuid } from 'src/utils/url';

const MaterialLotCardDetails = ({ lot, materials, state, debugMode, customMainStyle }) => {
  if (!lot) return null;

  if (state.error) {
    return (
      <p className='error-loading-card-resource'>
        <FontAwesomeIcon icon={faExclamationCircle} className='d-block spacer-right danger-color' />
        An error occurred while loading the Lots Data.
      </p>
    );
  }

  // eslint-disable-next-line camelcase
  const lotMaterial = materials.find(({ material_lot_uri }) => material_lot_uri === lot.uri);

  return (
    <div className='material-lot-card-details' style={customMainStyle}>
      <div className='material-lot-card-details-header'>
        <FontAwesomeIcon icon={faBoxOpen} className='spacer-right' />
        <TruncatedTitle
          inline
          title={lot.name}
          titleClassName='mb0'
          maxTextLength={25}
          maxTextLengthMobile={20}
        />
        {debugMode && <span className='spacer-left'>({getShortUuid(lot.uri)})</span>}
      </div>
      <div className='material-lot-card-details-body'>
        <div className='text-left'>
          <p>
            <FontAwesomeIcon className='spacer-right' icon={faClipboard} />
            {lot.delivery_id || 'N/A'}
          </p>
          <p className='mb0'>
            <FontAwesomeIcon className='spacer-right' icon={faPercent} />
            {lotMaterial?.percentage || 'N/A'}
          </p>
        </div>
        <div className='material-lot-card-details-body-right-details'>
          <p>
            {lot.quantity} {lot.units}
            <FontAwesomeIcon className='spacer-left' icon={faArrowUpShortWide} />
          </p>
          <p className='mb0'>
            <TruncatedTitle
              inline
              title={lot.material_name}
              titleClassName='mb0'
              maxTextLength={25}
              maxTextLengthMobile={20}
            />
            <FontAwesomeIcon className='spacer-left' icon={faObjectGroup} />
          </p>
        </div>
      </div>
    </div>
  );
};

MaterialLotCardDetails.defaultProps = {
  customMainStyle: {},
};

MaterialLotCardDetails.propTypes = {
  /* eslint-disable camelcase */
  lot: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
    units: PropTypes.string,
    status: PropTypes.string,
    quantity: PropTypes.number,
    delivery_id: PropTypes.string,
    usage_cycles: PropTypes.number,
    material_name: PropTypes.string,
  }),
  state: PropTypes.shape({
    error: PropTypes.bool,
  }).isRequired,
  debugMode: PropTypes.bool.isRequired,
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      percentage: PropTypes.number,
    })
  ).isRequired,
  customMainStyle: PropTypes.shape({}),
};

export default MaterialLotCardDetails;
