import { fetcher } from 'src/api/fetcher';

import { api, apiWithContext } from './api';

export const checkSSO = async email => {
  return api
    .get(`user_sso/${email}/`, {
      searchParams: {
        // eslint-disable-next-line camelcase
        return_to: `${window.location.origin}/scan`,
      },
    })
    .json();
};

export const createSession = async (username, password, adUsername = null) => {
  let payload = {
    username: `${username}`.trim(),
    password,
  };
  if (adUsername) {
    payload = {
      ...payload,
      // eslint-disable-next-line camelcase
      ad_username: `${adUsername}`.trim(),
      // eslint-disable-next-line camelcase
      logon_type: 'ldap',
    };
  }
  const createSessionResponse = await api
    .post('sessions/', {
      json: payload,
    })
    .json();

  return createSessionResponse;
};

export const destroySession = async (ctx = {}) => {
  const destroySessionResponse = apiWithContext(ctx).delete('sessions/').json();
  return destroySessionResponse;
};

export const getSession = async () => {
  const getSessionResponse = await fetcher('sessions');
  return getSessionResponse;
};

export const getFeatures = async () => {
  const getFeaturesResponse = await fetcher('feature');
  return getFeaturesResponse?.resources || [];
};
