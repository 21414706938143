import _camelCase from 'lodash/camelCase';
import _mapKeys from 'lodash/mapKeys';

export const parseCookie = str => {
  if (!str) {
    return {};
  }
  const cookies = str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});
  return _mapKeys(cookies, (value, key) => _camelCase(key));
};
