import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Run from 'src/pages/run/[uuid]';
import RunActionPage from 'src/pages/run/[uuid]/action';
import ScanToolingStock from 'src/pages/run/[uuid]/action/scan-tooling-stock';
import PrivateRoute from 'src/routes/PrivateRoute';

import NotFoundPage from '../pages/not-found';

const RunRoutes = () => (
  <Routes>
    <Route index path='/:uuid' element={<PrivateRoute Component={Run} />} />
    <Route index path='/:uuid/action' element={<PrivateRoute Component={RunActionPage} />} />
    <Route
      path='/:uuid/action/scan-tooling-stock'
      element={<PrivateRoute Component={ScanToolingStock} />}
    />
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default RunRoutes;
