import PropTypes from 'prop-types';
import React from 'react';

import { RUN_STATUS, RUN_STATUS_ORDER } from '../utils/constants';

const statusMap = new Map([
  [RUN_STATUS.inProgress, 'badge-info'],
  [RUN_STATUS.complete, 'badge-success'],
  [RUN_STATUS.error, 'badge-danger'],
]);

const PrintStatusBadge = ({ status }) => {
  const statusClass = statusMap.get(status) || 'badge-secondary';

  return <span className={`badge ${statusClass}`}>{status}</span>;
};

PrintStatusBadge.propTypes = {
  status: PropTypes.oneOf(RUN_STATUS_ORDER).isRequired,
};

export default PrintStatusBadge;
