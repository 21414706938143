import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Loader = ({ inline, text, className, showText, isSmall, ...props }) => {
  const commonClassNames = classNames(className, 'd-flex', 'align-items-center');

  const loadingText = `Loading${text && ` ${text}`}…`;

  if (inline) {
    return (
      <div className={commonClassNames} {...props}>
        <div className='spinner-border spinner-border-sm mr-2' role='status' aria-hidden='true' />
        {showText && <span data-testid='loader-text'>{loadingText}</span>}
      </div>
    );
  }

  const blockClassNames = classNames(commonClassNames, 'justify-content-center', 'flex-column');

  if (isSmall) {
    return (
      <div className={blockClassNames}>
        {showText && <strong data-testid='loader-text'>{loadingText}</strong>}
        <div>
          <div
            className='spinner-border spinner-border-sm ml-auto'
            role='status'
            aria-hidden='true'
          />
        </div>
      </div>
    );
  }

  return (
    <div className={blockClassNames}>
      {showText && <strong data-testid='loader-text'>{loadingText}</strong>}
      <div className='mt-3'>
        <div className='spinner-border ml-auto' role='status' aria-hidden='true' />
      </div>
    </div>
  );
};

Loader.propTypes = {
  inline: PropTypes.bool,
  showText: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  isSmall: PropTypes.bool,
};

Loader.defaultProps = {
  inline: false,
  text: '',
  className: '',
  showText: true,
  isSmall: false,
};

export default Loader;
