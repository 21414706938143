const prohibitedActionForType = (accessInfo, type) => {
  return (
    accessInfo &&
    accessInfo.actions.find(action => action.type === type && action.allowed === false)
  );
};

// Important: Action will be allowed even if `type` doesn't exist in `accessInfo` object
// so please make sure that you have that type always in `accessInfo` object
export const isAccessAllowed = (accessInfo, type) => {
  return !prohibitedActionForType(accessInfo, type);
};

export const accessProhibitedByGroupNames = (accessInfo, type, publicGroupsByUri) => {
  const action = prohibitedActionForType(accessInfo, type);
  return action
    ? action.disallow_by_edit_groups.map(
        groupUri => publicGroupsByUri[groupUri] && publicGroupsByUri[groupUri].name
      )
    : [];
};
