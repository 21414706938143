import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from 'src/context/UserContext';

import Loader from '../components/loader';
import { destroySession } from '../utils/auth';
import routes from '../utils/routes';


const LogoutPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { setUser } = useContext(UserContext);

  const handleLogOut = async () => {
    try {
      // TODO REFACTORING: Change when we have new API
      await destroySession({});
      setUser(null);
      navigate(routes.home);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleLogOut();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return <div />;
};


export default LogoutPage;
