import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Modal from 'src/components/modal';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS,
  MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES,
} from 'src/utils/constants';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../../utils/routes';
import BatchPowderQualityWarningModal from '../../../material-container/sections/_powder_quality_warning_modal';
import SieveModal from '../sections/_sieve_modal';
import ActionPage from './_action-wrapper';

const MachineLoad = ({ user }) => {
  const { addToast } = useToasts();
  const [searchParams] = useSearchParams();
  const { uuid: batchUUID } = useParams();
  const {
    printer: printerUri,
    actionFromBatch,
  } = Object.fromEntries(searchParams.entries()) ?? {};
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [showRelocateModal, setShowRelocateModal] = useState(false);

  const [batch, setBatch] = useState(null);
  const [printer, setPrinter] = useState(null);
  const [isPrinterAlreadyLoaded, setIsPrinterAlreadyLoaded] = useState(false);
  const [locations, setLocations] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [printerType, setPrinterType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSieveModal, setShowSieveModal] = useState(false);
  const [showBatchWarningModal, setShowBatchWarningModal] = useState(false);

  const navigate = useNavigate();

  const batchHasPowderQualityWarningStatus = MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(batch?.powder_quality);

  const getInitialData = async () => {
    setIsLoading(true);
    try {
      const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`).json();

      const printer = printerUri && await api.get(`${API_RESOURCES.PRINTER}/${getUuid(printerUri)}/`).json();
      const printerType = printer?.printer_type && await api.get(`${API_RESOURCES.PRINTER_TYPE}/${getUuid(printer.printer_type)}/`).json();

      const { resources: batchesInPrinter } = await api.get(
        `${API_RESOURCES.MATERIAL_BATCH}/`,
        {
          searchParams: {
            'filter[at_machine]': printerUri,
          },
        },
      ).json();
      const isPrinterAlreadyLoaded = (batchesInPrinter.length > 0);

      const subLocationsUris = printer && batch ?
        [...new Set([printer.sub_location, batch.sub_location])] :
        [];

      const { resources: subLocations } = await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
        searchParams: {
          'filter[uri]': subLocationsUris.join(','),
        },
      }).json();

      const { resources: locations } = await api.get(`${API_RESOURCES.LOCATION}/`, {
        searchParams: {
          'filter[uri]': printer.location,
        },
      }).json();

      setPrinter(printer);
      setBatch(batch);
      setIsPrinterAlreadyLoaded(isPrinterAlreadyLoaded);
      setLocations(locations);
      setSubLocations(subLocations);
      setPrinterType(printerType);
      const powderWarning = MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(batch.powder_quality);
      setShowBatchWarningModal(powderWarning);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => void getInitialData(), [batchUUID]);

  const checkSupportedMaterialsMismatch = (batch, printer) => {
    if (!batch?.materials || !printer?.materials) {
      return [];
    }

    const batchMaterials = batch.materials.map((material) => ({
      uri: material.uri,
      name: material.name,
    }));

    let currentUnsupportedMaterials = [];


    for (const batchMaterial of batchMaterials) {
      if (!printer.materials.some((printerMaterial) => printerMaterial === batchMaterial.uri)) {
        currentUnsupportedMaterials.push(batchMaterial.name);
      }
    }

    // If we have at least one unsupported material, return the name of the materials.
    if (currentUnsupportedMaterials.length) {
      return currentUnsupportedMaterials;
    }

    // Return [] if there are no unsupported materials.
    return [];
  };

  const unsupportedMaterials = checkSupportedMaterialsMismatch(batch, printerType);

  const hasDifferentLocations = batch?.location !== printer?.location && batch?.sub_location !== printer?.sub_location;

  useEffect(() => {
    if (isPrinterAlreadyLoaded) {
      // Printer is already loaded. Redirecting to Top Off confirmation page

      navigate(getRouteURI(routes.printerAlreadyLoaded,
        { uuid: getUuid(printer.uri) },
        {
          batch: batch.uri,
          actionFromBatch,
        }));
    }
  }, [isPrinterAlreadyLoaded]);

  const redirectToPrinterPage = () => {
    navigate(getRouteURI(routes.printerMaterial, { uuid: getUuid(printer.uri) }));
  };

  const onActionRelocate = async (batchUri, locationUri, subLocationUri) => {
    try {
      /* eslint-disable camelcase */
      const payload = {
        metadata: {
          destination_location: locationUri,
          destination_sub_location: subLocationUri,
        },
        action_type: MATERIAL_BATCH_ACTIONS.RELOCATE,
        source_batch: batchUri,
      };

      setSubmitting(true);
      await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
        json: payload,
      }).json();
    } catch (error_) {
      console.error(error_);
      setSubmitError(true);
      setSubmitting(false);
    }

  };

  const handleSubmitMachineLoad = () => {
    if (hasDifferentLocations) {
      setShowRelocateModal(true);
      return;
    }

    return onActionMachineLoad(batch.uri, printer.uri);
  };


  const onActionMachineLoad = async (batchUri, printerUri) => {

    try {
      const payload = {
        metadata: {
          printer: printerUri,
        },
        /* eslint-disable camelcase */
        action_type: MATERIAL_BATCH_ACTIONS.MACHINE_LOAD,
        source_batch: batchUri,
        /* eslint-enable camelcase */
      };

      setSubmitting(true);
      await api.post('material-batch-action/', {
        json: payload,
      });
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(batchUri) },
      {
        action: MATERIAL_BATCH_ACTIONS.MACHINE_LOAD,
      }));

    return new Promise(() => {
    });
  };

  const onModalConfirm = async () => {
    await onActionRelocate(batch.uri, printer.location, printer.sub_location);
    return onActionMachineLoad(batch.uri, printer.uri);
  };

  const onModalDismiss = () => setShowRelocateModal(false);

  const renderLocationMismatchText = () => {

    if (!batch || !printer || !locations || !subLocations) return null;

    const batchSubLocation = subLocations.find((subLocation) => subLocation.uri === batch.sub_location);
    const printerSubLocation = subLocations.find((subLocation) => subLocation.uri === printer.sub_location);
    const printerLocation = locations.find((location) => location.uri === printer.location);


    return (
      <>
        You are trying to Machine Load but there is a Locations mismatch. The Printer ({getShortUuid(printer.uri)}) is
        in {printerLocation?.name} ({printerSubLocation?.name}) and the Batch ({getShortUuid(batch.uri)}) is
        in {batch.location_name} ({batchSubLocation?.name}).

        Before Machine Load, the Batch ({getShortUuid(batch.uri)}) will be Relocated to the matching Locations. Are you
        sure you would like to relocate and continue?
      </>
    );
  };

  const renderWarningIcon = (id, content) => {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={(
          <Tooltip id={id}>
            {content()}
          </Tooltip>)}
      >
        <FontAwesomeIcon icon={faExclamationTriangle} className="d-block ml-2 warning-color" />
      </OverlayTrigger>
    );
  };

  const handleSieve = async () => {
    addToast(`Batch ${getShortUuid(batch?.uuid)} was successfully Sieved`, { appearance: 'success' });
    // Invalidate page data to refresh sieve information
    await getInitialData();
    setShowSieveModal(false);
    setShowBatchWarningModal(false);
  };

  return (
    <ActionPage
      id={batchUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.MACHINE_LOAD}
      isLoading={isLoading}
    >
      <div className="d-flex align-items-center justify-content-center alert alert-info" role="alert">
        <p className="mb0">
          <b>{batch?.quantity} {batch?.units}</b> of <b>{batch?.materials[0]?.name} (Batch {getShortUuid(batch?.uri)}) </b> will
          be
          loaded to machine <b>{printer?.name}</b>
        </p>

        {unsupportedMaterials.length ? (
          renderWarningIcon('batch-powder-quality-warning', () => {
            return (
              <>
                Printer &#39;{printer?.name}&#39; does not support&nbsp;
                {unsupportedMaterials.length > 1 ? 'materials' : 'material'}&nbsp;
                {unsupportedMaterials.map((material) => material).join(', ')}
              </>
            );
          })
        ) : null}
        {
          batchHasPowderQualityWarningStatus && !unsupportedMaterials.length ? (
            renderWarningIcon('workstation-is-overloaded', () => {
              return (
                <>
                  Consider sieving this batch or use a Batch that is New or already Sieved.
                </>
              );
            })) : null
        }
      </div>
      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isSubmitting || unsupportedMaterials.length}
        onClick={handleSubmitMachineLoad}
      >
        Confirm
      </button>

      <Modal
        isOpen={showRelocateModal}
        title="Locations Mismatch"
        dismissText="Cancel"
        confirmText="Relocate"
        onConfirm={onModalConfirm}
        onDismiss={onModalDismiss}
      >
        {renderLocationMismatchText()}
      </Modal>
      {showBatchWarningModal && (
        <BatchPowderQualityWarningModal
          action={MATERIAL_BATCH_ACTIONS.MACHINE_LOAD}
          additionalAction={() => setShowSieveModal(true)}
          redirectAction={redirectToPrinterPage}
          onSubmit={() => setShowBatchWarningModal(false)}
          onClose={() => setShowBatchWarningModal(false)} />
      )}
      {showSieveModal && (
        <SieveModal
          initialBatch={batch}
          hideModal={() => setShowSieveModal(false)}
          redirectToNextAction={handleSieve}
        />
      )}
    </ActionPage>
  );
};

MachineLoad.propTypes = {
  user: userPropType,
};

MachineLoad.defaultProps = {
  user: null,
};

export default MachineLoad;
