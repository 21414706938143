import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import FeaturesContext from 'src/context/FeaturesContext';
import { api } from 'src/utils/api';
import { API_RESOURCES, PIECE_STATUS_BADGE_COLORS } from 'src/utils/constants';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';
import { getUuid } from 'src/utils/url';

import MaterialChips from './_matherial';

const PieceHeader = ({ piece, runUUID }) => {
  const [material, setMaterial] = useState(null);
  const [materialBatch, setMaterialBatch] = useState(null);
  const [materialLot, setMaterialLot] = useState(null);
  const [isMaterialLoading, setIsMaterialLoading] = useState(false);
  const { features } = useContext(FeaturesContext);
  const isMaterialManagementFeatureEnabled = isFeatureEnabled(features, FEATURES.MATERIAL_MANAGEMENT);

  const { addToast } = useToasts();

  const getMaterialInfo = async () => {
    try {
      setIsMaterialLoading(true);
      const run = await api.get(`${API_RESOURCES.RUN}/${runUUID}`).json();

      const printerUUID = getUuid(run.printer);

      if (!printerUUID) {
        setIsMaterialLoading(false);

        const prints = await api.get(
          `${API_RESOURCES.PRINT}/`,
          {
            searchParams: {
              'filter[piece]': piece.uri,
            },
          }
        ).json();

        const runURIs = prints.resources.map((print) => print.run).filter(Boolean);

        const runActuals = await api.get(
          `${API_RESOURCES.RUN_ACTUALS}/`,
          {
            searchParams: {
              'filter[run]': runURIs,
            },
          }
        ).json();

        const materialBatchUUID = getUuid(runActuals.resources[0]?.material_batch);

        if (materialBatchUUID) {
          const materialBatch = await api.get(
            `${API_RESOURCES.MATERIAL_BATCH}/${materialBatchUUID}/`
          ).json();

          setMaterialBatch(materialBatch);

          const materialUUID = getUuid(materialBatch?.materials[0].uri);

          if (materialUUID) {
            const material = await await api.get(`${API_RESOURCES.MATERIAL}/${materialUUID}/`).json();
            setMaterial(material);
          }

          setIsMaterialLoading(false);

          // eslint-disable-next-line camelcase
          const materialLotUUID = getUuid(materialBatch?.material_lots[0]);

          if (materialLotUUID) {
            const materialLot = await api.get(`${API_RESOURCES.MATERIAL_LOT}/${materialLotUUID}/`).json();
            setMaterialLot(materialLot);
          }

        }

        return;
      }

      if (isMaterialManagementFeatureEnabled) {

        const printer = await api.get(`${API_RESOURCES.PRINTER}/${printerUUID}/`).json();

        const { resources: materialBatchesAtMachine } = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/`, {
          searchParams: {
            'filter[at_machine]': printer.uri,
          },
        }).json();

        const materialBatch = materialBatchesAtMachine[0];

        setMaterialBatch(materialBatch);

        const materialUUID = getUuid(materialBatch?.materials[0].uri);

        if (materialUUID) {
          const material = await await api.get(`${API_RESOURCES.MATERIAL}/${materialUUID}/`).json();
          setMaterial(material);
        }

        setIsMaterialLoading(false);

        // eslint-disable-next-line camelcase
        const materialLotUUID = getUuid(materialBatch?.material_lots[0]);

        if (materialLotUUID) {
          const materialLot = await api.get(`${API_RESOURCES.MATERIAL_LOT}/${materialLotUUID}/`).json();
          setMaterialLot(materialLot);
        }

      }

    } catch (error) {
      addToast('There was an error. Please try again.', { appearance: 'error' });
      console.error(error);
    } finally {
      setIsMaterialLoading(false);
    }
  };

  useEffect(() => {
    getMaterialInfo();
  }, []);

  return (
    <div className="piece-status run-workstation-body">
      <h4 className="header-margin mt-2">
        Status:{' '}
        {/* eslint-disable-next-line react/jsx-child-element-spacing */}
        <span
          className="badge badge-secondary"
          style={{ backgroundColor: PIECE_STATUS_BADGE_COLORS[piece.status], textTransform: 'capitalize' }}
        >
          {piece.status}
        </span>
        {/* TODO restore when the guide on how to progress that status will be ready */}
        {/* <FontAwesomeIcon className="right" icon={faInfoCircle} /> */}
      </h4>

      <MaterialChips
        material={material}
        materialBatch={materialBatch}
        materialLot={materialLot}
        isLoading={isMaterialLoading}
      />
    </div>
  );
};

PieceHeader.propTypes = {
  runUUID: PropTypes.string.isRequired,
  piece: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    material: PropTypes.string.isRequired,
  }).isRequired,
};

export default PieceHeader;
