import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import { reportError } from '../utils/errors';
import IntlDate from './intl-date.jsx';
import PrintStatusBadge from './print-status-badge.jsx';

const ActivityItem = ({ event, run, prints, user }) => {
  const { previous_value: previousValue, current_value: currentValue } = event;

  // If the run has multiple prints, find the first one that matches
  const printUri = run.prints.find(printUri => printUri in prints);

  if (!printUri) {
    const error = new Error('Run does not have expected print for line item.');
    error.level = 'info';

    reportError(error, {
      runPrints: run.prints,
      lineItemPrints: Object.keys(prints),
      lineItem: prints[0].line_item,
      copy: prints[0].copy,
    });

    return null;
  }

  const processStep = _get(prints, [printUri, 'process_step', 'process_type', 'name']);

  return (
    <li className='list-group-item d-flex align-items-center'>
      <div className='flex-grow-1 text-left'>
        <h3 className='h5 mb-0'>{processStep}</h3>
        <p className='mb-1'>
          <PrintStatusBadge status={previousValue} /> <span aria-label='to'>→</span>{' '}
          <PrintStatusBadge status={currentValue} />
        </p>
        <p className='small mb-0'>
          <IntlDate withTime date={event.created} />
          {user && <> by {user.name}</>}
        </p>
      </div>
      <div>{/* <button className="btn btn-light" type="button">Undo</button> */}</div>
    </li>
  );
};

ActivityItem.propTypes = {
  event: PropTypes.shape({
    created: PropTypes.string.isRequired,
    // API results are camelcase ¯\_(ツ)_/¯
    /* eslint-disable camelcase */
    current_value: PropTypes.string.isRequired,
    previous_value: PropTypes.string.isRequired,
    /* eslint-enable camelcase */
  }).isRequired,
  run: PropTypes.shape({
    prints: PropTypes.array.isRequired,
  }).isRequired,
  prints: PropTypes.shape({}).isRequired,
  user: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
};

ActivityItem.defaultProps = {
  user: false,
};

export default ActivityItem;
