import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MachineLoad from 'src/pages/material-batch/[uuid]/action/machine-load';
import MachineUnloadChamber from 'src/pages/material-batch/[uuid]/action/machine-unload-chamber';
import MachineUnloadHopper from 'src/pages/material-batch/[uuid]/action/machine-unload-hopper';
import RelocateBatch from 'src/pages/material-batch/[uuid]/action/relocate';
import StartTest from 'src/pages/material-batch/[uuid]/action/start-test';
import UnloadReclaimedMaterial from 'src/pages/material-batch/[uuid]/action/unload-reclaimed-material';
import BatchSuccessPage from 'src/pages/material-batch/[uuid]/success';
import PrivateRoute from 'src/routes/PrivateRoute';

import BatchActionPage from '../pages/material-batch/[uuid]/action';
import BlendBatches from '../pages/material-batch/[uuid]/action/blend-batches';
import ScrapBatch from '../pages/material-batch/[uuid]/action/scrap-batch';
import Sieve from '../pages/material-batch/[uuid]/action/sieve';
import SplitBatch from '../pages/material-batch/[uuid]/action/split-batch';
import NotFoundPage from '../pages/not-found';

const MaterialBatchRoutes = () => (
  <Routes>
    <Route index path='/:uuid/action' element={<PrivateRoute Component={BatchActionPage} />} />
    <Route path='/:uuid/action/split-batch' element={<PrivateRoute Component={SplitBatch} />} />
    <Route path='/:uuid/action/blend-batches' element={<PrivateRoute Component={BlendBatches} />} />
    <Route path='/:uuid/action/scrap-batch' element={<PrivateRoute Component={ScrapBatch} />} />
    <Route path='/:uuid/action/sieve' element={<PrivateRoute Component={Sieve} />} />
    <Route path='/:uuid/action/relocate' element={<PrivateRoute Component={RelocateBatch} />} />
    <Route path='/:uuid/action/start-test' element={<PrivateRoute Component={StartTest} />} />
    <Route path='/:uuid/action/machine-load' element={<PrivateRoute Component={MachineLoad} />} />
    <Route
      path='/:uuid/action/machine-unload-hopper'
      element={<PrivateRoute Component={MachineUnloadHopper} />}
    />
    <Route
      path='/:uuid/action/machine-unload-chamber'
      element={<PrivateRoute Component={MachineUnloadChamber} />}
    />
    <Route
      path='/:uuid/action/unload-reclaimed-material'
      element={<PrivateRoute Component={UnloadReclaimedMaterial} />}
    />
    <Route path='/:uuid/success' element={<PrivateRoute Component={BatchSuccessPage} />} />
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default MaterialBatchRoutes;
