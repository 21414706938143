import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import { PERMANENT_CONTAINER_ACTIONS } from 'src/utils/constants';
import routes from 'src/utils/routes';
import { getRouteURI } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

const PermanentContainerActionPage = ({ user }) => {
  const [actionNotFound, setActionNotFound] = useState(false);
  const [searchParams] = useSearchParams();
  const { uuid: id } = useParams();
  const {
    type,
    batch: batchUri,
    sieveQuantity,
    initialSieveAction,
    printer: printerUri,
  } = Object.fromEntries(searchParams.entries()) ?? {};
  const navigate = useNavigate();

  useEffect(() => {
    let pageName = null;
    const queryParams = {};

    switch (type) {
      case PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL:
        pageName = 'load-material';
        queryParams.batch = batchUri;
        break;
      case PERMANENT_CONTAINER_ACTIONS.TOP_OFF:
        pageName = 'top-off';
        queryParams.batch = batchUri;
        break;
      case PERMANENT_CONTAINER_ACTIONS.TRANSFER_MATERIAL:
        pageName = 'transfer-material';
        queryParams.batch = batchUri;
        break;
      case PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL:
        pageName = 'action-unload-to-container';
        queryParams.printer = printerUri;
        queryParams.batch = batchUri;
        queryParams.initialAction = PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL;
        break;
      case PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH:
        pageName = 'action-unload-to-container';
        queryParams.batch = batchUri;
        queryParams.sieveQuantity = sieveQuantity;
        if (initialSieveAction) {
          queryParams.initialSieveAction = initialSieveAction;
        }
        queryParams.initialAction = PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH;
        break;
      case PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL:
        pageName = 'action-unload-to-container';
        queryParams.printer = printerUri;
        queryParams.batch = batchUri;
        queryParams.initialAction = PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL;
        break;
      case PERMANENT_CONTAINER_ACTIONS.RELOCATE:
        pageName = 'relocate';
        break;
      default:
        setActionNotFound(true);
        break;
    }

    if (pageName) {
      navigate(getRouteURI(
          `${routes.permanentContainerAction}/${pageName}`, { uuid: id }, queryParams),
        { replace: true });
    }

  }, [type]);

  if (actionNotFound) {
    return (
      <>
        <Header title="Permanent Container Action Not Found" user={user} />
        <main role="main" className="text-center">
          <NotFound id={id} />
        </main>
      </>
    );
  }

  return (
    <>
      <Header title="Permanent Container Action" user={user} />
      <main role="main" className="text-center">
        <Loader />
      </main>
    </>
  );
};

PermanentContainerActionPage.propTypes = {
  user: userPropType,
};

PermanentContainerActionPage.defaultProps = {
  user: null,
};

export default PermanentContainerActionPage;
