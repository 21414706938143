export default {
  home: '/',
  about: '/about',
  activity: '/activity',
  login: '/log-in',
  logout: '/log-out',
  logonScan: '/logon-scan',

  materialBatch: '/material-batch',
  materialBatchAction: '/material-batch/:uuid/action',
  materialBatchBlendBatches: '/material-batch/:uuid/action/blend-batches',
  materialBatchMachineLoad: '/material-batch/:uuid/action/machine-load',
  materialBatchMachineUnloadChamber: '/material-batch/:uuid/action/machine-unload-chamber',
  materialBatchMachineUnloadHopper: '/material-batch/:uuid/action/machine-unload-hopper',
  materialBatchRelocate: '/material-batch/:uuid/action/relocate',
  materialBatchScrapBatch: '/material-batch/:uuid/action/scrap-batch',
  materialBatchSieve: '/material-batch/:uuid/action/sieve',
  materialBatchSplitBatch: '/material-batch/:uuid/action/split-batch',
  materialBatchStartTest: '/material-batch/:uuid/action/start-test',
  materialBatchUnloadReclaimedMaterial: '/material-batch/:uuid/action/unload-reclaimed-material',
  materialBatchSuccess: '/material-batch/:uuid/success',

  materialContainerCreateInitialBatch: '/material-container/:uuid/create-initial-batch',
  materialContainerDetails: '/material-container/:uuid',
  materialContainer: '/material-container',

  piece: '/piece/:uuid',
  pieceOverview: '/piece-overview/:uuid',

  printer: '/printer',
  printerAlreadyLoaded: '/printer/:uuid/already-loaded',
  printerMaterial: '/printer/:uuid/material',

  runRoute: '/run',
  run: '/run/:uuid',

  toolRoute: '/tool',

  permanentContainer: '/permanent-container',
  permanentContainerDetails: '/permanent-container/:uuid',
  permanentContainerAction: '/permanent-container/:uuid/action',
  runAction: '/run/:uuid/action',
  toolAction: '/tool/:uuid/action',
  permanentContainerAlreadyLoaded: '/permanent-container/:uuid/already-loaded',
  permanentContainerSuccess: '/permanent-container/:uuid/success',

  shipment: '/shipment/:uuid',

  travelerPrint: '/traveler/print/:print',
  travelerRun: '/traveler/run/:run',

  scan: '/scan',
  summary: '/summary',
};
