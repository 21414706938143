import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { getUuid } from '../utils/url';
import ActionContext from './action-context';

// This context may be used for the rest of the actions in the future.
// One of the options of the usage is to save the scanned data and even when the page is reloaded ->
// Still allow the user to proceed with the requested action (which is not supported right now).
const initialState = Object.freeze({
  containersScanned: {},
});

const ActionProvider = ({ children }) => {
  const [permanentContainersActionState, setPermanentContainersActionState] =
    useState(initialState);

  const handleAddScannedPermanentContainerForUnloadMaterial = useCallback(container => {
    setPermanentContainersActionState(prevState => {
      const alreadyScanned = prevState.containersScanned[getUuid(container.uri)];

      if (alreadyScanned) {
        return prevState;
      }

      const newContainersScanned = {
        ...prevState.containersScanned,
        [getUuid(container.uri)]: container,
      };

      return { ...prevState, containersScanned: newContainersScanned };
    });
  }, []);

  const updateContainerQuantitiesForUnloadMaterial = useCallback(updatedContainers => {
    setPermanentContainersActionState(prevState => {
      return {
        ...prevState,
        containersScanned: updatedContainers,
      };
    });
  }, []);

  const removeScannedContainerForUnloadMaterial = useCallback(containerUri => {
    setPermanentContainersActionState(prevState => {
      const updatedContainersScanned = { ...prevState.containersScanned };
      const removedContainerQuantity = updatedContainersScanned[getUuid(containerUri)].quantity;
      delete updatedContainersScanned[getUuid(containerUri)];

      // Distribute the quantity of the removed container to the maxQuantity of the remaining containers
      const remainingContainerKeys = Object.keys(updatedContainersScanned);
      remainingContainerKeys.forEach(key => {
        updatedContainersScanned[key].maxQuantity += removedContainerQuantity;
      });

      return {
        ...prevState,
        containersScanned: updatedContainersScanned,
      };
    });
  }, []);

  const resetActionContainerState = useCallback(() => {
    setPermanentContainersActionState(initialState);
  }, []);

  return (
    <ActionContext.Provider
      value={{
        containerActionData: {
          permanentContainersActionState,
          addScannedContainer: handleAddScannedPermanentContainerForUnloadMaterial,
          updateContainerQuantities: updateContainerQuantitiesForUnloadMaterial,
          removeScannedContainer: removeScannedContainerForUnloadMaterial,
          resetActionContainerState,
        },
      }}
    >
      {children}
    </ActionContext.Provider>
  );
};

ActionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ActionProvider;
