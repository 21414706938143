import PropTypes from 'prop-types';
import React, { useState } from 'react';

import UserContext from './UserContext';

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default UserProvider;
