import { faQrcode } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import routes from 'src/utils/routes';

const ScanButton = ({ withWrapper }) => {
  const Button = (
    <Link to={routes.scan}>
      <button type='button' className='btn btn-outline-primary btn-action'>
        <FontAwesomeIcon icon={faQrcode} size='3x' className='d-block m-auto' />
        New Scan
      </button>
    </Link>
  );

  if (withWrapper) {
    return <div className='optionButtons ml-4 mt-4'>{Button}</div>;
  }

  return Button;
};

ScanButton.propTypes = {
  withWrapper: PropTypes.bool,
};

ScanButton.defaultProps = {
  withWrapper: true,
};

export default ScanButton;
