import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Sentry from 'src/utils/sentry';
import { getRouteURI, getUuid } from 'src/utils/url';

import routes from '../../../../utils/routes';

const NextRunForPrint = ({ piece, nextRun, nextWorkstation }) => {
  const maxLength = 16;
  const truncateString = (str) => (str.length > maxLength)
    ? (
      <>
        {str.substr(0, maxLength - 1)}
        &hellip;
      </>
    )
    : str;


  const NextRunLink = () => {
    // Make sure user will see the link (even if he has no access to Workstation for example)
    const nextRunLinkText = nextWorkstation ? nextWorkstation.name : nextRun.name;
    return (
      <Link to={getRouteURI(routes.run, { uuid: getUuid(nextRun.uri) })}>
        <a>{truncateString(nextRunLinkText)}</a>
      </Link>
    );
  };

  return (
    <li>
      <span className="float-left">{truncateString(piece.name)}</span>
      <span className="float-right">
        {
          nextRun
            ? <NextRunLink />
            : 'None'
        }
      </span>
    </li>
  );
};

NextRunForPrint.propTypes = {
  piece: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  nextRun: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }),
  nextWorkstation: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

NextRunForPrint.defaultProps = {
  nextRun: null,
  nextWorkstation: null,
};

const NextRunsForPrints = ({ prints, nextRunsByPrintUri, workstationsByUri, piecesByPrintUri }) => (
  <ol className="clearfix">
    {prints.map(
      (print) => {
        const nextRun = nextRunsByPrintUri[print.uri];
        const workstationUri = nextRun && (nextRun.printer || nextRun.post_processor);
        const nextWorkstation = workstationUri && workstationsByUri[workstationUri];
        const piece = piecesByPrintUri[print.uri];

        if (!piece) {
          Sentry.captureMessage(`Failed to find piece for Print ${print.uri} on Run ${print.run} for Next Run link.`);
          // Piece is expected to be always set for a Print. Prevent exceptions if not found.
          return null;
        }

        return (
          <NextRunForPrint
            key={print.uri}
            piece={piece}
            nextRun={nextRun}
            nextWorkstation={nextWorkstation}
          />
        );
      },
    )}
  </ol>
);

NextRunsForPrints.propTypes = {
  prints: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
  })).isRequired,
  nextRunsByPrintUri: PropTypes.objectOf(PropTypes.shape({
    printer: PropTypes.string,
    // eslint-disable-next-line camelcase
    post_processor: PropTypes.string,
  })).isRequired,
  workstationsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  piecesByPrintUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};


export default NextRunsForPrints;
