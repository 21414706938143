import PropTypes from 'prop-types';
import React from 'react';
import { BARCODE_SCANNER_STATES } from 'src/utils/constants';

const BarcodeStatusAlert = ({ scannerState, successfulScan, focusInput }) => {
  const getAlertContent = () => {
    switch (scannerState) {
      case BARCODE_SCANNER_STATES.idle:
        return <div className='alert alert-warning'>The scanner was asleep. Please try again.</div>;
      case BARCODE_SCANNER_STATES.inactive:
        return (
          <div className='alert alert-secondary barcode-alert-inactive'>
            <p className='mb-0 mr15'>The scanner is not ready.</p>
            <button type='button' className='btn btn-secondary btn-sm' onClick={focusInput}>
              Click here to scan again
            </button>
          </div>
        );
      case BARCODE_SCANNER_STATES.ready:
        return <div className='alert alert-success'>Ready to scan</div>;
      default:
        return null;
    }
  };

  return successfulScan ? null : getAlertContent();
};

BarcodeStatusAlert.propTypes = {
  scannerState: PropTypes.string.isRequired,
  successfulScan: PropTypes.bool.isRequired,
  focusInput: PropTypes.func.isRequired,
};

export default BarcodeStatusAlert;
