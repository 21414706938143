import { faCheck, faInfoCircle, faPause, faPlay, faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import Loader from 'src/components/loader';
import ScanButton from 'src/components/scan-button';
import SubmitButton from 'src/components/submit-button';
import FeaturesContext from 'src/context/FeaturesContext';
import { accessProhibitedByGroupNames, isAccessAllowed } from 'src/utils/accessInfo';
import { api } from 'src/utils/api';
import {
  ACCESS_INFO_ACTION_TYPES,
  API_RESOURCES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  RUN_OPERATIONS,
  RUN_STATUS,
  RUN_STATUS_ORDER,
  TIME_ENTRY_CATEGORIES,
  TIME_ENTRY_RELATED_TABLE_NAME,
} from 'src/utils/constants';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';
import { isWorkInstructionValidByThreshold, WORK_INSTRUCTION_THRESHOLD_ACTIONS } from 'src/utils/thresholds';
import { getEnabledTimeEntryCategories } from 'src/utils/timeEntry';
import { getUuid } from 'src/utils/url';
import useLocalstorage from 'src/utils/useLocalStorage';
import { checkAllRequiredWorkInstructionsSubmitted } from 'src/utils/work-instructions';

import CompleteModal from './_complete-modal';
import PauseModal from './_pause-modal';
import WarningModal from './_warning-modal';


const IN_PROGRESS_INDEX = RUN_STATUS_ORDER.indexOf(RUN_STATUS.inProgress);
const COMPLETE_INDEX = RUN_STATUS_ORDER.indexOf(RUN_STATUS.complete);

const RunOperations = ({
                         submitInstructionReport,
                         onRunUpdate,
                         run,
                         accessInfo,
                         publicGroupsByUri,
                         reportsByPrintUri,
                         checklistsByPrintUri,
                         latestAttendTimeTrackingEntry,
                         materialData,
                         workstation,
                         isAbleToCompleteRun,
                         alreadySubmittedWorkInstructions,
                       }) => {
  const { addToast } = useToasts();

  const [showPauseModal, setShowPauseModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [isRunStarting, setIsRunStarting] = useState(false);

  /* - If we have 10-10 in progress, show workstation is busy and show info tooltip with the text. No opportunity to start the run.
     - If we have some in progress but also have slots for the other runs to start (2 in progress of 5 for example) ->
       show the text similar to workstation is busy and : X out of Y runs in-progress at this station.
       But provide with the opportunity to start the run anyway. */

  const isTheCurrentRunIsAlreadyActive = workstation && workstation.in_progress?.includes(run?.uri);
  const isWorkstationOutOfCapacity = (workstation && !isTheCurrentRunIsAlreadyActive) &&
    (workstation.in_progress?.length === workstation.in_progress_max);
  const isWorkstationBusy = !isTheCurrentRunIsAlreadyActive && (workstation && workstation.in_progress?.length > 0) && !isWorkstationOutOfCapacity;

  const currentStatusIndex = RUN_STATUS_ORDER.indexOf(run.status);
  const isStartDisabled = currentStatusIndex >= IN_PROGRESS_INDEX;

  const [isReportCompleted, setIsReportCompleted] = useState(false);

  // Get all work instructions
  const workInstructions = Object.values(checklistsByPrintUri)[0]?.work_instructions || [];
  // Identify the required fields only
  const requiredWorkInstructions = workInstructions.filter((instruction) => instruction.required);

  // Check if all the required work instructions are submitted
  // By checking all the required fields
  // And already submitted fields
  const allRequiredInstructionsSubmitted = checkAllRequiredWorkInstructionsSubmitted(
    requiredWorkInstructions,
    alreadySubmittedWorkInstructions,
  );

  const { features } = useContext(FeaturesContext);

  const isRunTimeTrackingFeatureEnabled = isFeatureEnabled(features, FEATURES.RUN_TIME_TRACKING);
  const isUserTimeTrackingFeatureEnabled = isFeatureEnabled(features, FEATURES.USER_RUN_TIME_TRACKING);
  const isMaterialManagementFeatureEnabled = isFeatureEnabled(features, FEATURES.MATERIAL_MANAGEMENT);
  // In case this option is not stored in localStorage -> we will show modal by default
  const [isCompleteConfirmationHidden] = useLocalstorage('DO_NOT_SHOW_COMPLETE_MODAL');
  const isAnyTimeTrackingEnabled = isRunTimeTrackingFeatureEnabled || isUserTimeTrackingFeatureEnabled;

  const isReportCompletedForPrintUri = (printUri) => {
    if (!checklistsByPrintUri[printUri]) {
      return true;
    }
    const workInstructions = checklistsByPrintUri[printUri].work_instructions;
    const requiredWorkInstructions = workInstructions.filter((instruction) => instruction.required);

    if (!reportsByPrintUri[printUri] && requiredWorkInstructions.length > 0) {
      // Some instructions are required, but they're not submitted yet
      return false;
    }

    const errorThresholdsWorkInstructions = workInstructions.filter(
      (instruction) =>
        instruction.threshold
        && instruction.threshold_action === WORK_INSTRUCTION_THRESHOLD_ACTIONS.ERROR,
    );

    if (errorThresholdsWorkInstructions.length > 0) {
      // Find any report for `error` thresholds that is filled in correctly
      const isAnyErrorThresholdFilledIncorrectly = errorThresholdsWorkInstructions.some(
        (instruction) =>
          // if report exists
          reportsByPrintUri[printUri]
          // and any value is provided
          && reportsByPrintUri[printUri][instruction.uuid]
          // and provided value is outside of the thresholds params
          && !isWorkInstructionValidByThreshold(
            reportsByPrintUri[printUri][instruction.uuid],
            instruction.threshold,
            instruction.threshold_type,
          ),
      );

      if (isAnyErrorThresholdFilledIncorrectly) {
        return false;
      }
    }

    // For all required work instruction, the report must exist
    return requiredWorkInstructions.every(
      (instruction) => reportsByPrintUri[printUri] && reportsByPrintUri[printUri][instruction.uuid]
    );
  };

  const getReportsCompletionByPrintUri = () => {
    const result = {};
    Object.keys(checklistsByPrintUri).forEach((printUri) => {
      result[printUri] = isReportCompletedForPrintUri(printUri);
    });
    return result;
  };

  const [isReportCompletedByPrintUri, setIsReportCompletedByPrintUri] = useState(() => getReportsCompletionByPrintUri());

  useEffect(() => {
    setIsReportCompletedByPrintUri(getReportsCompletionByPrintUri());
  }, [reportsByPrintUri]);

  useEffect(() => {
    if (currentStatusIndex >= COMPLETE_INDEX) {
      // Report is considered `completed` when Run is completed
      setIsReportCompleted(true);
      return;
    }
    let reportCompleted = true;
    Object.keys(isReportCompletedByPrintUri).forEach((printUri) => {
      if (!isReportCompletedByPrintUri[printUri]) {
        reportCompleted = false;
      }
    });
    setIsReportCompleted(reportCompleted);
  }, [isReportCompletedByPrintUri, currentStatusIndex]);

  const checkMaterialBatchForRun = () => {
    if (!(materialData.materialBatch && Object.keys(materialData.materialBatch).length)) {
      setShowWarningModal(true);
    } else {
      setShowWarningModal(false);
      return onStart();
    }
  };

  const handleRunUpdate = async (runStatus) => {
    try {
      await api.put(run.uri, {
        prefixUrl: false,
        json: {
          status: runStatus,
        },
        onErrorToastCallback: addToast,
      });
    } catch (error) {
      return;
    }

    const updatedRun = await api.get(run.uri, { prefixUrl: false }).json();
    if (!isAnyTimeTrackingEnabled) {
      // No need to load time entries if none of time entry features is enabled
      onRunUpdate(updatedRun);
    }

    const updatedRunTimeEntries = await loadTimeEntries();
    onRunUpdate(updatedRun, updatedRunTimeEntries);
  };

  const onStart = async () => {
    setIsRunStarting(true);
    await handleRunUpdate(RUN_STATUS.inProgress);
    return setIsRunStarting(false);
  };

  if (run.status === RUN_STATUS.complete) {
    // When run is completed - no need to show any actions. Showing `Scan` link
    return (
      <ScanButton />
    );
  }

  const loadTimeEntries = async () => {
    // Prepare categories of the time entries to load
    const timeEntryCategories = getEnabledTimeEntryCategories(features);

    const { resources: updatedRunTimeEntries } = await api.get(
      `${API_RESOURCES.TIME_ENTRY}/`,
      {
        searchParams: {
          'filter[related_table_name]': TIME_ENTRY_RELATED_TABLE_NAME.RUN,
          'filter[related_uuid]': getUuid(run.uri),
          'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
          'filter[category]': timeEntryCategories.join(','),
          'sort': 'created',
        },
      },
    ).json();
    return updatedRunTimeEntries;
  };

  const onPause = async () => {
    if (isUserTimeTrackingFeatureEnabled) {
      setShowPauseModal(true);
      return;
    }
    return handleRunPause();
  };

  const handleRunPause = async (notes, isRunStillRunning = false) => {
    const pauseRun = isRunTimeTrackingFeatureEnabled && !isRunStillRunning;
    if (isUserTimeTrackingFeatureEnabled) {
      try {
        await api.put(latestAttendTimeTrackingEntry.uri, {
          prefixUrl: false,
          json: {
            notes,
            // eslint-disable-next-line camelcase
            end_time: (new Date()).toISOString(),
          },
          onErrorToastCallback: addToast,
        });
      } catch (error) {
        return;
      }
    }

    if (pauseRun) {
      await handleRunUpdate(RUN_STATUS.paused);
    } else {
      // If run status is untouched - load new time entries only
      const updatedRunTimeEntries = await loadTimeEntries();
      onRunUpdate(run, updatedRunTimeEntries);
    }
  };

  const handleCompleteRun = async () => {
    try {
      return handleRunUpdate(RUN_STATUS.complete);
    } catch (error) {
      return;
    }
  };

  const handleChecklistsSave = () => {
    const promises = [];
    run.prints.forEach((printUri) => {
      if (checklistsByPrintUri[printUri]) {
        checklistsByPrintUri[printUri].work_instructions.forEach((instruction) => {
          promises.push(submitInstructionReport([printUri], instruction));
        });
      }
    });

    return Promise.all(promises);
  };

  const showAccessError = () => {
    const prohibitedByGroupNames = accessProhibitedByGroupNames(accessInfo, ACCESS_INFO_ACTION_TYPES.EDIT, publicGroupsByUri);
    addToast(
      `Cannot complete this run. Completing a run is limited to the Qualified Group "${prohibitedByGroupNames.join(', ')}" and Bureau Managers. Contact your Bureau Manager if you have any questions.`,
      { appearance: 'error' },
    );
  };

  const onResume = async () => {
    if (isUserTimeTrackingFeatureEnabled) {
      try {
        await api.post(`${API_RESOURCES.TIME_ENTRY}/`, {
          json: {
            category: TIME_ENTRY_CATEGORIES.ATTENDING,
            'related_table_name': TIME_ENTRY_RELATED_TABLE_NAME.RUN,
            'related_uuid': getUuid(run.uri),
          },
          onErrorToastCallback: addToast,
        });
      } catch (error) {
        return;
      }
    }

    if (isRunTimeTrackingFeatureEnabled && run.status !== RUN_STATUS.inProgress) {
      return handleRunUpdate(RUN_STATUS.inProgress);
    } else {
      // If run status is untouched - load new time entries only
      const updatedRunTimeEntries = await loadTimeEntries();
      onRunUpdate(run, updatedRunTimeEntries);
    }
  };

  const handleWarningSubmit = async () => {
    try {
      return onStart();
    } catch (error) {
      return;
    }
  };

  const onComplete = async () => {
    if (!isCompleteConfirmationHidden) {
      setShowCompleteModal(true);
      return;
    }
    await handleCompleteRun();
  };

  const onSubmitWI = async () => {
    await handleChecklistsSave();
  };

  const handleStartWorkingOnRun = () => {
    if (isMaterialManagementFeatureEnabled && isRunOfTypePrint) {
      return checkMaterialBatchForRun();
    }

    return onStart();
  };

  const showStart = !isStartDisabled;
  // Showing Complete button always, when Start is not shown
  const showComplete = !showStart;
  const showResume = Boolean(isAnyTimeTrackingEnabled
    && (
      // Resume is allowed when run is paused
      run.status === RUN_STATUS.paused
      // Or when there is no active user time tracking entry (user time is on pause)
      || (isUserTimeTrackingFeatureEnabled && latestAttendTimeTrackingEntry && latestAttendTimeTrackingEntry.end_time)
    )
  );
  // Pause is allowed only if run is in progress (both for Run and/or User time tracking) and when user time tracking is running
  const showPause = isAnyTimeTrackingEnabled && run.status === RUN_STATUS.inProgress && !showResume;

  const isRunEditAccessAllowed = isAccessAllowed(accessInfo, ACCESS_INFO_ACTION_TYPES.EDIT);
  // Submit WI button is needed only when Run Editing is not allowed
  // (means user is not able to click Complete button and needs an extra option)
  const showSubmitWI = !isRunEditAccessAllowed;
  // Report is completed message needed
  // when report is completed
  // and `complete` action is available but user has no access (isRunEditAccessAllowed)
  const showReportCompletedMessage = !isRunEditAccessAllowed && showComplete && isReportCompleted;
  const isRunOfTypePrint = (run?.operation === RUN_OPERATIONS.PRINTING);


  if (!isStartDisabled && isWorkstationOutOfCapacity) {
    return (
      <div className="btn btn-danger">
        <div className="d-flex align-items-center justify-content-center">
          <div>Cannot start Run</div>
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip id="workstation-is-overloaded">
                Cannot start this run because too many other Runs
                are In Progress at this Workstation. Please complete
                the other Runs or Change the Workstation this Run is
                assigned to so that you may proceed with this Run.
              </Tooltip>)}
          >
            <FontAwesomeIcon icon={faInfoCircle} className="ml-1" />
          </OverlayTrigger>
        </div>
        <div>Workstation is too busy</div>
      </div>
    );
  }

  if (!isStartDisabled && isWorkstationBusy) {
    return (
      isRunStarting ?
        <Loader inline text="Run" className="mb-4 justify-content-center" /> :
        <SubmitButton
          className="btn btn-warning"
          disabled={!isRunEditAccessAllowed}
          onClick={handleStartWorkingOnRun}
        >
          <div>{workstation.in_progress.length || 0} of {workstation.in_progress_max || 0} Runs in-progress at this
            station.
          </div>
          <div>Start this Run?</div>
        </SubmitButton>
    );
  }

  return (
    <>
      {showSubmitWI && (
        <div className="text-center mt-4">
          <SubmitButton
            className="btn-info"
            onClick={onSubmitWI}
          >
            Submit Work Instructions
          </SubmitButton>
        </div>
      )}
      {showReportCompletedMessage && (
        <div className="text-center mt-4">
          All required steps are complete. Run is ready to be completed. Contact your bureau manager if you have any
          questions.
        </div>
      )}
      <div className="text-center mt-4">
        {!isRunEditAccessAllowed && (
          <button
            type="button"
            className="btn btn-danger"
            onClick={showAccessError}
          >
            <FontAwesomeIcon icon={faQuestion} />
          </button>
        )}
        {showStart && (
          isRunStarting ?
            <Loader inline text="Run" className="mb-4 justify-content-center" /> :
            <SubmitButton
              className="btn-success"
              disabled={!isRunEditAccessAllowed}
              onClick={handleStartWorkingOnRun}
            >
              Start working on Run
            </SubmitButton>
        )}
        {showPause && (
          <>
            &nbsp;
            <SubmitButton
              className="btn-info"
              disabled={!isRunEditAccessAllowed}
              onClick={onPause}
            >
              <FontAwesomeIcon icon={faPause} />
            </SubmitButton>
          </>
        )}

        {showResume && (
          <>
            &nbsp;
            <SubmitButton
              className="btn-info"
              disabled={!isRunEditAccessAllowed}
              onClick={onResume}
            >
              <FontAwesomeIcon icon={faPlay} />
            </SubmitButton>
          </>
        )}
        {showComplete && (
          !isAbleToCompleteRun && (isReportCompleted && isRunEditAccessAllowed) ? (
            <>
              &nbsp;
              <div className="btn btn-warning">
                <div className="d-flex align-items-center justify-content-center">
                  <div>Cannot complete Run</div>
                  <OverlayTrigger
                    placement="top"
                    overlay={(
                      <Tooltip id="ncr-cannot-complete-run">
                        Please confirm if the piece(s) should be sent to NC
                        in order to to complete this run.
                      </Tooltip>)}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-1" />
                  </OverlayTrigger>
                </div>
              </div>
            </>

          ) : (

            <>
              &nbsp;
              <SubmitButton
                className="btn-info"
                disabled={!isReportCompleted || !isRunEditAccessAllowed || !allRequiredInstructionsSubmitted}
                onClick={onComplete}
              >
                <FontAwesomeIcon icon={faCheck} />
              </SubmitButton>
            </>
          )
        )}
      </div>
      {showPauseModal && (
        <PauseModal onSubmit={handleRunPause} onClose={() => setShowPauseModal(false)} />
      )}
      {showCompleteModal && (
        <CompleteModal
          onSubmit={handleCompleteRun}
          onClose={() => setShowCompleteModal(false)}
        />
      )}
      {showWarningModal && (
        <WarningModal onSubmit={handleWarningSubmit} onClose={() => setShowWarningModal(false)} />
      )}
    </>
  );
};

RunOperations.propTypes = {
  submitInstructionReport: PropTypes.func.isRequired,
  onRunUpdate: PropTypes.func.isRequired,
  run: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    prints: PropTypes.arrayOf(PropTypes.string).isRequired,
    operation: PropTypes.string,
  }).isRequired,
  accessInfo: PropTypes.shape({}),
  publicGroupsByUri: PropTypes.objectOf(PropTypes.shape({})),
  reportsByPrintUri: PropTypes.shape({}).isRequired,
  checklistsByPrintUri: PropTypes.shape({}).isRequired,
  latestAttendTimeTrackingEntry: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    end_time: PropTypes.string,
  }),
  materialData: PropTypes.shape({
    materialBatch: PropTypes.object,
  }).isRequired,
  workstation: PropTypes.shape({
    // eslint-disable-next-line camelcase
    in_progress: PropTypes.arrayOf(PropTypes.string).isRequired,
    // eslint-disable-next-line camelcase
    in_progress_max: PropTypes.number.isRequired,
  }),
  isAbleToCompleteRun: PropTypes.bool.isRequired,
  alreadySubmittedWorkInstructions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

RunOperations.defaultProps = {
  workstation: null,
  latestAttendTimeTrackingEntry: null,
  accessInfo: null,
  publicGroupsByUri: null,
};

export default RunOperations;
