import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Alert from 'src/components/alert.jsx';
import Loader from 'src/components/loader';
import Modal from 'src/components/modal.jsx';
import { api } from 'src/utils/api';

const NotesModal = ({ run, onClose }) => {
  const [notes, setNotes] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [saveError, setSaveError] = useState(null);

  const runUri = run.uri;

  if (!runUri) {
    return null;
  }

  const hideModal = () => {
    setNotes('');
    setIsLoading(false);
    setSaveError(null);
    onClose();
  };

  const loadRunData = () => {
    setIsLoading(true);
    api.get(runUri, {
      prefixUrl: false,
    }).json().then((runData) => {
      setNotes(runData.notes || '');
      setIsLoading(false);
    });
  };

  const handleNotesChange = ({ target }) => {
    setNotes(target.value);
  };

  const onModalDismiss = () => {
    hideModal();
  };

  const onModalConfirm = async () => {
    setIsLoading(true);

    try {
      await api.put(runUri, {
        prefixUrl: false,
        json: {
          notes,
        },
      });

      hideModal();
    } catch (error) {
      setSaveError(error);
    }

    setIsLoading(false);
  };

  return (
    <Modal
      isOpen
      title="Run Notes"
      dismissText="Cancel"
      confirmText="Save Notes"
      onAfterOpen={loadRunData}
      onConfirm={onModalConfirm}
      onDismiss={onModalDismiss}
    >
      {saveError && (
        <Alert variant="danger" className="small">
          There was an error saving the notes. Please try again.
        </Alert>
      )}
      {isLoading && <Loader inline />}
      <textarea disabled={isLoading} className="form-control" value={notes} onChange={handleNotesChange} />
    </Modal>
  );
};

NotesModal.propTypes = {
  run: PropTypes.shape({
    uri: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotesModal;
