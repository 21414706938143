import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import Modal from 'src/components/modal.jsx';
import useLocalstorage from 'src/utils/useLocalStorage';

const CompleteModal = ({ onSubmit, onClose }) => {
  const [saveError, setSaveError] = useState(null);
  const [completeConfirmationEnabled, setCompleteConfirmationEnabled] = useState(true);
  const [, setCompleteModal] = useLocalstorage('DO_NOT_SHOW_COMPLETE_MODAL', false);
  const { addToast } = useToasts();

  const saveDoNotShowConfirmationComplete = () => setCompleteModal(true);
  const handleCheckboxConfirmation = () => setCompleteConfirmationEnabled((prev) => !prev);

  useEffect(() => {
    if (saveError) {
      addToast('There was an error completing run. Please try again.', { appearance: 'error' });
    }
  }, [saveError]);

  const hideModal = () => {
    setSaveError(null);
    onClose();
  };

  const onModalDismiss = () => {
    hideModal();
  };

  const onModalConfirm = async () => {
    try {
      if (!completeConfirmationEnabled) {
        // if "Do not show again" option is clicked -> save localStorage config to prevent show this modal
        saveDoNotShowConfirmationComplete();
      }

      await onSubmit();
      hideModal();
    } catch (error) {
      setSaveError(error);
    }
  };

  return (
    saveError ? null : (
      <Modal
        isOpen
        title="Complete Run"
        dismissText="Cancel"
        confirmText="Complete"
        onConfirm={onModalConfirm}
        onDismiss={onModalDismiss}
      >
        <p>You are attempting to Complete this Run. Would you like to proceed?</p>
        <div className="form-group form-check mb-15">
          <input
            type="checkbox"
            className="form-check-input"
            id="completeCheckbox"
            onChange={handleCheckboxConfirmation}
          />
          <label className="form-check-label" htmlFor="completeCheckbox">
            Do not ask again
          </label>
        </div>
      </Modal>
    )
  );
};

CompleteModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompleteModal;
