// NOTE: This require will be replaced with `@sentry/node` on the server
// thanks to the webpack config in next.config.js
// eslint-disable-next-line no-restricted-imports
import { Debug as DebugIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

export const init = (config = {}) => {
  const { dsn, environment, release, sampleRate } = config;

  if (!dsn) {
    return;
  }

  const sentryOptions = {
    dsn,
    environment,
    release,
  };

  if ('Breadcrumbs' in Sentry.Integrations) {
    sentryOptions.integrations = [
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ];
  }

  if (sampleRate) {
    sentryOptions.sampleRate = config.sampleRate;
  }

  // Don’t actually send the errors to Sentry.
  // Instead, dump the errors to the console with the debug integration.
  if (config.nodeEnv !== 'production') {
    sentryOptions.beforeSend = () => null;

    sentryOptions.integrations = [
      ...(sentryOptions.integrations || []),
      new DebugIntegration({
        debugger: false,
      }),
    ];
  }

  Sentry.init(sentryOptions);
  console.info('Sentry initialized with options', sentryOptions);
};

export default Sentry;
