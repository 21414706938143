import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Header from 'src/components/header.jsx';
import Loader from 'src/components/loader';
import Traveler from 'src/components/traveler.jsx';
import FeaturesContext from 'src/context/FeaturesContext';
import { api } from 'src/utils/api';
import { API_RESOURCES, BUREAU_BARCODE_FORMAT } from 'src/utils/constants';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';
import { getLineItemWorkflowTypeObjectKey } from 'src/utils/lineItem';

const PRINTING_PROCESS_STEP_POSITION = 1;

const PrintTravelerPage = () => {
  const { print } = useParams();
  const printUuid = Array.isArray(print) ? print[0] : print;

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [printState, setPrintState] = useState({
    error: null,
    print: {},
    order: null,
    lineItem: null,
    model: {},
    workflow: {},
    orderOwner: null,
    printingProcessStepRun: null,
    piece: {},
  });

  const { features } = useContext(FeaturesContext);
  const isPowderWorkflowFeatureEnabled = isFeatureEnabled(features, FEATURES.POWDER_WORKFLOW);

  const fetchInitialData = async () => {
    try {
      setIsLoading(true);
      const printData = await api.get(`${API_RESOURCES.PRINT}/${printUuid}/`).json();

      // Order and Line Item may already be deleted,
      // so orderData and lineItemData may be empty
      const [orderData, lineItemData, { resources: printingStepPrints }, piece] = await Promise.all([
        api.get(printData.order, {
          prefixUrl: false,
        }).json(),
        api.get(printData.line_item, {
          prefixUrl: false,
        }).json(),
        api.get(`${API_RESOURCES.PRINT}/`, {
          searchParams: {
            'filter[line_item]': printData.line_item,
            'filter[copy]': printData.copy,
            'filter[process_step_position]': PRINTING_PROCESS_STEP_POSITION,
          },
        }).json(),
        api.get(printData.piece, {
          prefixUrl: false,
        }).json(),
      ]);

      const printingStepPrint = printingStepPrints[0];
      const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItemData);

      // Model and Workflow may be empty in cases when line item is already deleted
      const [modelData, workflowData, printingProcessStepRun, { resources: bureauSettings }] = await Promise.all([
        lineItemData[workflowTypeKey]?.model
          ? api.get(lineItemData[workflowTypeKey].model, {
            prefixUrl: false,
          }).json()
          : Promise.resolve(null)
        ,
        lineItemData
          ? api.get(lineItemData.workflow, {
            prefixUrl: false,
          }).json()
          : Promise.resolve(null)
        ,
        printingStepPrint.run
          ? api.get(printingStepPrint.run, {
            prefixUrl: false,
          }).json()
          : Promise.resolve(null),
        api.get(`${API_RESOURCES.BUREAU_SETTINGS}/`).json(),
      ]);

      const bureauBarcodeFormatSettings = bureauSettings?.[0].barcode_default_format;

      let orderOwner = null;
      // OrderData may be empty here in case order is already deleted
      if (orderData && orderData.order_owner) {
        try {
          orderOwner = await api.get(orderData.order_owner, {
            prefixUrl: false,
          }).json();
        } catch (orderOwnerError) {
          // Order owner might not exist anymore (when user is deleted).
          // Ignoring order owner request errors since it is an optional property
        }
      }

      setPrintState({
        print: printData,
        order: orderData,
        lineItem: lineItemData,
        model: modelData,
        workflow: workflowData,
        orderOwner,
        piece,
        printingProcessStepRun,
        bureauBarcodeFormat: bureauBarcodeFormatSettings || BUREAU_BARCODE_FORMAT.QR,
      });

      setIsLoading(false);

    } catch (error) {
      if (error?.response) {
        const { errors } = await error.response.json();

        setError(errors[0]);
      } else {
        setError(error);
      }
      return setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, [printUuid]);

  if (error) {
    const errorMessage = error.code === 'RecordNotFound' ? 'Print was not found.' : error.message;

    return (
      <>
        <Header title="Print Traveler" back="" />
        <div className="alert alert-danger m-4" role="alert">
          <h1 className="h5">Print error</h1>
          <p className="mb-0">{errorMessage}</p>
        </div>
      </>
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Print Traveler</title>
      </Helmet>
      <Traveler
        {...printState}
        isPowderWorkflow={isPowderWorkflowFeatureEnabled}
        scanningMode={printState.bureauBarcodeFormat}
      />
    </>
  );
};

export default PrintTravelerPage;
