import PropTypes from 'prop-types';
import React from 'react';
import Alert from 'src/components/alert';
import Feature from 'src/components/feature';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import Qr from 'src/components/qr';
import ResourceLookupField from 'src/components/resource-lookup-field';
import { API_RESOURCES } from 'src/utils/constants';
import { FEATURES } from 'src/utils/features';
import userPropType from 'src/utils/user-prop-type';

const QrScan = ({
  user,
  allowedEntityType,
  successfulScan,
  handleScan,
  handleError,
  urlError,
  showInstructions,
  instructionText,
  requestedEntity,
  renderUseWithoutPermanentContainer,
  isUnloadAction,
  isActionMachineLoad,
  isLoadPrinterAction,
}) => {
  const loadMachineResources = [API_RESOURCES.MATERIAL_CONTAINER, API_RESOURCES.PRINTER];
  const noRequestEntity = !requestedEntity;
  const batchRequestEntity = requestedEntity === API_RESOURCES.MATERIAL_BATCH;
  const containerRequestEntity = requestedEntity === API_RESOURCES.MATERIAL_CONTAINER;
  const printerRequestEntity = requestedEntity === API_RESOURCES.PRINTER;
  const isRunAction = requestedEntity === API_RESOURCES.RUN;
  const shouldShowResourceLookupField =
    noRequestEntity || batchRequestEntity || containerRequestEntity || printerRequestEntity;

  const getResourceLookupData = () => {
    if (isActionMachineLoad && !isLoadPrinterAction) {
      return loadMachineResources;
    }

    if (isUnloadAction) {
      return API_RESOURCES.MATERIAL_CONTAINER;
    }

    return API_RESOURCES.MATERIAL_BATCH;
  };

  const resourceLookupName = getResourceLookupData();
  return (
    <>
      <Header scan title='Scan QR Code' user={user} />

      <main role='main'>
        <div className='qr-reader text-white bg-dark rounded-sm overflow-hidden mb-3'>
          <Qr
            requestedEntity={allowedEntityType}
            isUnloadAction={isUnloadAction}
            hidden={successfulScan}
            isActionMachineLoad={isActionMachineLoad}
            onScan={handleScan}
            onError={handleError}
          >
            <Loader inline text='data' className='mt-3' />
          </Qr>
        </div>
        {urlError && (
          <Alert name='qr-error' variant='danger' className='text-center'>
            {urlError.message}
          </Alert>
        )}
        {!urlError && showInstructions && (
          <Alert name='qr-instructions' variant='info' className='text-center'>
            <span>{instructionText}</span>
          </Alert>
        )}
        {renderUseWithoutPermanentContainer()}
        {shouldShowResourceLookupField && !isRunAction && (
          // It's possible to enter batch uuid if material management is enabled
          // and (
          //   exact resource type is not requested (user visited main page)
          //   or
          //   if expected to scan material batch entity
          // )
          <Feature featureName={FEATURES.MATERIAL_MANAGEMENT}>
            <div className='separator'>OR</div>
            <ResourceLookupField
              resourceName={resourceLookupName}
              successfulScan={successfulScan}
              onScan={handleScan}
            />
          </Feature>
        )}
      </main>
    </>
  );
};

QrScan.propTypes = {
  user: userPropType.isRequired,
  allowedEntityType: PropTypes.string,
  requestedEntity: PropTypes.string,
  successfulScan: PropTypes.bool.isRequired,
  handleScan: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  urlError: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  showInstructions: PropTypes.bool.isRequired,
  instructionText: PropTypes.string.isRequired,
  renderUseWithoutPermanentContainer: PropTypes.func.isRequired,
  isUnloadAction: PropTypes.bool.isRequired,
  isActionMachineLoad: PropTypes.bool.isRequired,
  isLoadPrinterAction: PropTypes.bool.isRequired,
};

QrScan.defaultProps = {
  allowedEntityType: null,
  requestedEntity: null,
};

export default QrScan;
