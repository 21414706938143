import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import { api } from 'src/utils/api';
import {
  API_RESOURCES, PERMANENT_CONTAINER_ACTIONS,
} from 'src/utils/constants';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../utils/routes';
import MaterialBatchCardDetails from './sections/material-batch-card-details';

const PermanentContainerPage = ({ user }) => {
  const { uuid: permanentContainerUuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [permanentContainer, setPermanentContainer] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [batch, setBatch] = useState(null);
  const isArchived = permanentContainer?.archived;

  const fetchInitialData = async (permanentContainerUuid) => {
    try {
      const permanentContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${permanentContainerUuid}/`).json();
      setPermanentContainer(permanentContainer);

      if (permanentContainer) {
        const location = await api.get(`${API_RESOURCES.LOCATION}/${getUuid(permanentContainer.location)}/`).json();
        const subLocation = await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(permanentContainer.sub_location)}/`).json();
        setLocationData({ location, subLocation });

        if (permanentContainer.current_batch) {
          const loadedBatch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(permanentContainer.current_batch)}/`).json();
          setBatch(loadedBatch);
        }
      }
      setIsLoading(false);
      setError(null);
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData(permanentContainerUuid);
  }, [permanentContainerUuid]);

  if (isLoading) {
    return (
      <>
        <Header title="Loading" back="/scan" user={user} />
        <main role="main" className="text-center">
          <Loader />
        </main>
      </>
    );
  }

  if (error || !permanentContainer) {
    return (
      <>
        <Header title="Permanent Container" user={user} />
        <main role="main" className="text-center">
          <NotFound id={permanentContainerUuid} />
        </main>
      </>
    );
  }


  return (
    <>
      <Header title="Permanent Container" user={user} />
      <main role="main" className="text-center">
        <div>
          <h2 className="header-margin">
            {permanentContainer.name}
          </h2>
          <h3 className="header-margin">
            Location:
            <div className="mt10">
              <span className="badge badge-secondary">{locationData.location.name}</span> /&nbsp;
              <span className="badge badge-secondary">{locationData.subLocation.name}</span>
            </div>
          </h3>
          {
            batch && (
              <h3 className="header-margin">Quantity:&nbsp;
                <span>{permanentContainer.quantity} {batch.units}</span>
              </h3>
            )
          }

          {
            !permanentContainer.current_batch && (
              <h4 className="header-margin-large">
                Current Status:&nbsp;
                <span className="badge badge-warning">
                  {isArchived ? 'Archived' : 'Empty'}
                </span>
              </h4>
            )
          }

          <MaterialBatchCardDetails batch={batch} />

          {
            permanentContainer.current_batch && (
              <Link className="link-btn" to={getRouteURI(routes.materialContainer, {}, { batch: batch.uuid })}>
                <button type="button" className="btn btn-lg btn-secondary btn-block mb15">View Batch Actions</button>
              </Link>
            )
          }

          {!permanentContainer.current_batch ?
            <Link
              to={getRouteURI(routes.scan,
                {},
                {
                  permanentContainer: permanentContainer.uri,
                  entity: API_RESOURCES.MATERIAL_CONTAINER,
                  action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
                })}
              className="link-btn"
            >
              <button type="button" disabled={isArchived} className="btn btn-lg btn-primary btn-block">Load Material
              </button>
            </Link>
            :
            <Link
              to={getRouteURI(routes.scan,
                {},
                {
                  permanentContainer: permanentContainer.uri,
                  entity: API_RESOURCES.MATERIAL_CONTAINER,
                  action: PERMANENT_CONTAINER_ACTIONS.TOP_OFF,
                })}
              className="link-btn"
            >
              <button type="button" disabled={isArchived} className="btn btn-lg btn-primary btn-block">Load Material
                (Top Off)
              </button>
            </Link>}

          {/* Hidden until the BE is not ready */}
          {/*<Link href={{ pathname: `/scan`, query: { permanentContainer: permanentContainer.uri, entity: API_RESOURCES.MATERIAL_BATCH, action: MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF } }}>*/}
          {/*  <button type="button" className="btn btn-lg btn-primary btn-block" disabled={!permanentContainer.current_batch}>Transfer Material</button>*/}
          {/*</Link>*/}

          <Link
            to={getRouteURI(routes.permanentContainerAction,
              { uuid: permanentContainerUuid },
              {
                type: PERMANENT_CONTAINER_ACTIONS.RELOCATE,
              })}
            className="link-btn"
          >
            <button
              type="button"
              disabled={permanentContainer.current_batch || isArchived}
              className="btn btn-lg btn-primary btn-block"
            >
              <div>
                <span>Relocate</span>
                {
                  permanentContainer.current_batch && (
                    <OverlayTrigger
                      placement="top"
                      overlay={(
                        <Tooltip>
                          Please relocate the full batch using the batch page, or split off a single container and
                          relocate
                          it independently.
                        </Tooltip>
                      )}
                    >
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        color="#FF9903"
                        className="spacer-left spacer-right"
                      />
                    </OverlayTrigger>
                  )
                }
              </div>
            </button>
          </Link>
        </div>
      </main>
    </>
  );
};

PermanentContainerPage.propTypes = {
  error: PropTypes.shape({}),
  user: userPropType,
};

PermanentContainerPage.defaultProps = {
  error: null,
  user: null,
};

export default PermanentContainerPage;
