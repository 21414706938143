import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import Loader from 'src/components/loader';
import { api } from 'src/utils/api';
import { API_RESOURCES } from 'src/utils/constants';
import { getUuid } from 'src/utils/url';


const WorkflowStep = ({ status, stepID, index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(null);
  const [workstationName, setWorkstationName] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const step = await api.get(`${API_RESOURCES.PROCESS_STEP}/${stepID}`).json();
        if (step.workstation) {
          const workstepType = step.workstation.split('/')[3];
          const workStepEndpoints = {
            [API_RESOURCES.PRINTER]: API_RESOURCES.PRINTER,
            [API_RESOURCES.SHIPPING]: API_RESOURCES.SHIPPING,
            default: API_RESOURCES.POST_PROCESSOR,
          };
          const workStationEndpoint = workStepEndpoints[workstepType] || workStepEndpoints.default;

          const workstation = await api.get(
            `${workStationEndpoint}/${getUuid(step.workstation)}/`
          ).json();
          setWorkstationName(workstation.name);
        } else {
          setWorkstationName(step.name);
        }
        setStep(step);
      } catch (error) {
        console.error(error);
        addToast('There was an error. Please try again.', { appearance: 'error' });
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  if (!step && isLoading) {
    return <Loader inline />;
  }

  if (!step) return null;

  return (
    <tr>
      {/* eslint-disable-next-line camelcase */}
      <td>{step.step_position || index + 1}</td>
      <td className="word-break">{workstationName || 'N/A'}</td>
      <td><span className="status-cell">{status || 'N/A'}</span></td>
    </tr>
  );
};

WorkflowStep.propTypes = {
  status: PropTypes.string.isRequired,
  stepID: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default WorkflowStep;
