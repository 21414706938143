import { faQrcode } from '@fortawesome/pro-regular-svg-icons';
import { faBarcode } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { BUREAU_BARCODE_FORMAT, PERMANENT_CONTAINER_ACTIONS } from 'src/utils/constants';

const QrBarcodeToggle = ({ scanMode, toggleScanMode, action }) => {
  return (
    <div
      className='scan-mode-toggle-container'
      style={
        action === PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL ||
        action === PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL
          ? { bottom: 0 }
          : { bottom: '20px' }
      }
    >
      <FontAwesomeIcon size='2x' icon={faQrcode} />
      <div
        className={`scan-mode-toggle-switch ${scanMode === BUREAU_BARCODE_FORMAT.BARCODE ? 'barcode-mode' : ''}`}
      >
        <div className='scan-mode-toggle-knob' onClick={toggleScanMode} />
      </div>
      <FontAwesomeIcon size='2x' icon={faBarcode} />
    </div>
  );
};

QrBarcodeToggle.propTypes = {
  scanMode: PropTypes.string.isRequired,
  toggleScanMode: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
};

export default QrBarcodeToggle;
