import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from 'src/components/header.jsx';
import NotFound from 'src/components/not-found';
import Traveler from 'src/components/traveler.jsx';
import FeaturesContext from 'src/context/FeaturesContext';
import { api, getAllByFilter } from 'src/utils/api';
import { groupBy, keyArrayBy } from 'src/utils/array';
import config from 'src/utils/config';
import { API_RESOURCES, BUREAU_BARCODE_FORMAT } from 'src/utils/constants';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';
import { getUuid } from 'src/utils/url';

const PRINTING_PROCESS_STEP_POSITION = 1;
const PRINTING_BATCH_SIZE = 30;

const RunTravelerPage = ({ user }) => {
  const [batchLoading, setBatchLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState({
    prints: [],
    orders: {},
    lineItems: {},
    models: {},
    workflows: {},
    orderOwners: {},
    printingProcessStepPrintsByLineItem: {},
    printingProcessStepRuns: {},
    pieces: {},
  });

  const {
    run,
    prints,
    orders,
    lineItems,
    models,
    workflows,
    orderOwners,
    printingProcessStepPrintsByLineItem,
    printingProcessStepRuns,
    pieces,
  } = data;

  const { features } = useContext(FeaturesContext);
  const isPowderWorkflowFeatureEnabled = isFeatureEnabled(features, FEATURES.POWDER_WORKFLOW);

  const fetchData = async () => {

    if (page !== 0) {
      setBatchLoading(true);
    }
    try {
      const runUuid = getUuid(window.location.href);
      const [run, printResponce] = await Promise.all([
        api.get(`${API_RESOURCES.RUN}/${runUuid}/`).json(),
        api.get(`${API_RESOURCES.PRINT}/`, {
          searchParams: {
            'filter[run]': `${config.apiHost}/run/${runUuid}`,
            'page[offset]': page * PRINTING_BATCH_SIZE,
            'page[limit]': PRINTING_BATCH_SIZE, // Adjust batch size as needed
            'filter[work_needed]': false,
          },
        }).json(),
      ]);

      const prints = printResponce?.resources || printResponce?.value?.resource;
      setTotalPages(Math.ceil(printResponce?.meta?.count / PRINTING_BATCH_SIZE));

      if (run.error || prints.length === 0) {
        setData({
          ...data,
          run,
        });
      }

      // eslint-disable-next-line camelcase
      const lineItemUris = new Set(prints.map(({ line_item }) => line_item));
      const lineItemUrisWithCopy = groupBy(prints, 'line_item', 'copy');
      const orderUris = new Set(prints.map(({ order }) => order));

      // Some line items and orders may already be deleted
      const [lineItems, orders, printingProcessStepPrintsResult, piecesResult, { resources: bureauSettings }] = await Promise.all([
        getAllByFilter([...lineItemUris], `${API_RESOURCES.LINE_ITEM}/`, {
          searchParams: {
            'filter[type]': 'product,specimen',
          },
        }),
        getAllByFilter([...orderUris], `${API_RESOURCES.ORDER}/`),
        await Promise.all(
          Object.entries(lineItemUrisWithCopy).map(
            (lineItemUriWithCopy) =>
              api.get(
                `${API_RESOURCES.PRINT}/`,
                {
                  searchParams: {
                    'filter[line_item]': lineItemUriWithCopy[0],
                    'filter[copy]': lineItemUriWithCopy[1].join(','),
                    'filter[process_step_position]': PRINTING_PROCESS_STEP_POSITION,
                  },
                }
              ).json()
          )
        ),
        await api.get(`${API_RESOURCES.PIECE}/`, {
          searchParams: {
            'filter[order]': [...orderUris].join(','),
          },
        }).json(),
        await api.get(`${API_RESOURCES.BUREAU_SETTINGS}/`).json(),
      ]);

      const printingProcessStepPrintsResultResources = printingProcessStepPrintsResult.map(({ resources }) => resources);
      const printingProcessStepPrints = printingProcessStepPrintsResultResources.flat();
      const modelUris = !isPowderWorkflowFeatureEnabled ? new Set(lineItems.map(({ additive }) => additive.model)) : [];
      const workflowUris = new Set(lineItems.map(({ workflow }) => workflow));
      const orderOwnerUuids = new Set(orders.map(({ order_owner: orderOwner }) => getUuid(orderOwner)).filter(Boolean));
      const printingProcessStepRunUris = new Set(printingProcessStepPrints.map(({ run }) => run).filter(Boolean));
      const bureauBarcodeFormatSettings = bureauSettings?.[0].barcode_default_format;

      const [models, workflows, orderOwners, printingProcessStepRuns] = await Promise.all([
        getAllByFilter([...modelUris], `${API_RESOURCES.MODEL}/`),
        getAllByFilter([...workflowUris], `${API_RESOURCES.WORKFLOW}/`, {
          searchParams: {
            'filter[include_custom_workflows]': 'true',
          },
        }),
        getAllByFilter([...orderOwnerUuids], `${API_RESOURCES.USERS}/`, {}, 'uuid'),
        getAllByFilter([...printingProcessStepRunUris], `${API_RESOURCES.RUN}/`),
      ]);

      const ordersByUri = keyArrayBy(orders, 'uri');
      const lineItemsByUri = keyArrayBy(lineItems, 'uri');
      const modelsByUri = keyArrayBy(models, 'uri');
      const workflowsByUri = keyArrayBy(workflows, 'uri');
      const orderOwnersByUri = keyArrayBy(orderOwners, 'uri');
      const printingProcessStepPrintsByLineItemUri = keyArrayBy(printingProcessStepPrints, 'line_item');
      const printingProcessStepRunsByUri = keyArrayBy(printingProcessStepRuns, 'uri');
      const piecesByUri = keyArrayBy(piecesResult['resources'], 'uri');

      if (page === 0) {
        setData({
          run,
          prints,
          orders: ordersByUri,
          lineItems: lineItemsByUri,
          models: modelsByUri,
          workflows: workflowsByUri,
          orderOwners: orderOwnersByUri,
          printingProcessStepPrintsByLineItem: printingProcessStepPrintsByLineItemUri,
          printingProcessStepRuns: printingProcessStepRunsByUri,
          pieces: piecesByUri,
          bureauBarcodeFormat: bureauBarcodeFormatSettings || BUREAU_BARCODE_FORMAT.QR,
        });
      } else {
        setData({
          ...data,
          run: { ...data.run, ...run },
          prints: [...data.prints, ...prints],
          orders: {
            ...data.orders,
            ...ordersByUri,
          },
          lineItems: {
            ...data.lineItems,
            ...lineItemsByUri,
          },
          models: {
            ...data.models,
            ...modelsByUri,
          },
          workflows: {
            ...data.workflows,
            ...workflowsByUri,
          },
          orderOwners: {
            ...data.orderOwners,
            ...orderOwnersByUri,
          },
          printingProcessStepPrintsByLineItem: {
            ...data.printingProcessStepPrintsByLineItem,
            ...printingProcessStepPrintsByLineItemUri,
          },
          printingProcessStepRuns: {
            ...data.printingProcessStepRuns,
            ...printingProcessStepRunsByUri,
          },
          pieces: {
            ...data.pieces,
            ...piecesByUri,
          },
        });
      }

      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setLoading(false);
      setBatchLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const handleScroll = async () => {
      const threshold = 5; // Pixels
      if (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight - threshold) {
        return;
      }
      if (batchLoading || loading) {
        return;
      }
      if (page >= totalPages) {
        return;
      }
      await fetchData();
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [batchLoading, page, totalPages, loading]);


  if (loading) {
    return (
      <>
        <Header title="Run Traveler" back="" />
        <div className="container d-flex align-items-center justify-content-center vh-100">
          <div className="row">
            <div className="col text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (run?.error) {
    return (
      <>
        <Header title="Run Traveler" back="" />
        <div className="alert alert-danger m-4" role="alert">
          <h1 className="h5">Run error</h1>
          <p className="mb-0">{run.error}</p>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={run?.id} />
        </main>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Run Traveler – {run?.name} – Authentise</title>
      </Helmet>
      {prints.map((print) => {
        const orderOwnerURI = orders[print.order] && orders[print.order].order_owner;
        const printingRunUri = printingProcessStepPrintsByLineItem[print.line_item].run;
        const modelUri = (lineItems[print.line_item] && !isPowderWorkflowFeatureEnabled) && lineItems[print.line_item].additive.model;
        const workflowUri = lineItems[print.line_item] && lineItems[print.line_item].workflow;
        const pieceUri = pieces[print.piece] && pieces[print.piece].uri;

        return (
          <Traveler
            key={print.uri}
            print={print}
            order={orders[print.order]}
            lineItem={lineItems[print.line_item]}
            model={modelUri && models[modelUri]}
            workflow={workflowUri && workflows[workflowUri]}
            orderOwner={orderOwnerURI && orderOwners[orderOwnerURI]}
            printingProcessStepRun={printingRunUri && printingProcessStepRuns[printingRunUri]}
            piece={pieceUri && pieces[pieceUri]}
            isPowderWorkflow={isPowderWorkflowFeatureEnabled}
            scanningMode={data.bureauBarcodeFormat}
          />
        );
      })}
      {batchLoading && (
        <div className="container d-flex align-items-center justify-content-center mb10 mt10">
          <div className="row">
            <div className="col text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

RunTravelerPage.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

export default RunTravelerPage;
