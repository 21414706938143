import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

import SelectableUser from './selectable-user';

const UserPicker = ({ handleHide, users, mentionedUsers, updateMentionedUsers }) => {
  return (
    <div className='user-picker__wrapper'>
      <div className='d-flex flex-direction-row justify-content-between w-100 mb-5'>
        <div className='user-picker__header'>
          <div className='user-picker__goback'>
            <span onClick={handleHide}>←</span>
          </div>

          <span className='picker__headings'>Tag</span>
        </div>
        <Button variant='success' onClick={handleHide}>
          Done {mentionedUsers.length > 0 && <em>({mentionedUsers.length} selected)</em>}
        </Button>
      </div>
      <div className='user-picker__list'>
        {users.map(user => (
          <SelectableUser
            key={user.uri}
            user={user}
            isMentioned={mentionedUsers.includes(user.username)}
            updateMentionedUsers={updateMentionedUsers}
          />
        ))}
      </div>
    </div>
  );
};

UserPicker.propTypes = {
  handleHide: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string.isRequired,
    })
  ).isRequired,
  mentionedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateMentionedUsers: PropTypes.func.isRequired,
};

export default UserPicker;
