import PropTypes from 'prop-types';
import React from 'react';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import {
  TOOLING_STOCK_ACTION_TYPES, TOOLING_STOCK_ACTION_VERBOSE,
} from 'src/utils/constants';
import userPropType from 'src/utils/user-prop-type';

const ToolActionPageWrapper = ({ id, user, action, httpError, customErrorText, isLoading, children }) => {
  if (httpError || customErrorText) {
    return (
      <>
        <Header title="Error" user={user} />
        {
          httpError ? (
            <main role="main" className="text-center">
              <NotFound id={id} />
            </main>
          ) : (
            <div className="alert alert-danger m-4" role="alert">
              <h1 className="h5 header-margin">Error</h1>
              {
                customErrorText ?
                  (<p className="mb-0">{customErrorText}</p>) :
                  (<p className="mb-0">Something Went Wrong.</p>)
              }
            </div>
          )

        }
      </>
    );
  }

  const verboseAction = TOOLING_STOCK_ACTION_VERBOSE[action];

  return (
    <>
      <Header title={verboseAction} user={user} />
      <main role="main" className="text-center">
        <div>
          {isLoading ? <Loader inline text="Tool" className="text-muted" /> : children}
        </div>
      </main>
    </>
  );
};

ToolActionPageWrapper.propTypes = {
  id: PropTypes.string,
  action: PropTypes.oneOf(Object.values(TOOLING_STOCK_ACTION_TYPES)).isRequired,
  user: userPropType,
  httpError: PropTypes.shape({}),
  customErrorText: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

ToolActionPageWrapper.defaultProps = {
  id: '',
  user: null,
  children: null,
  httpError: null,
  customErrorText: '',
  isLoading: false,
};

export default ToolActionPageWrapper;
