import React, { useContext } from 'react';
import UserContext from 'src/context/UserContext';

import Alert from '../components/alert';
import Header from '../components/header';

const NotFoundPage = () => {
  const { user } = useContext(UserContext);
  return (
    <>
      <Header title="Page Not Found" user={user} />
      <main role="main" className="text-center">
        <Alert variant="danger" name="not-found-page">
          <h1>404</h1>
          <p>
            You seem to have reached a link that does not go anywhere. Maybe you want{' '}
            <a href="/">to go back to the beginning?</a>
          </p>
        </Alert>
      </main>
    </>
  );
};

export default NotFoundPage;
