import { useAsync as originalUseAsync } from 'react-async';

import config from './config';
import { reportError } from './errors';

export const useAsync = (...args) => {
  const result = originalUseAsync(...args);

  if (result.error && config.sentryDsn) {
    reportError(result.error);
  }

  return result;
};
