import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AlreadyLoadedPage from 'src/pages/printer/[uuid]/already-loaded';
import PrinterMaterialPage from 'src/pages/printer/[uuid]/material';
import PrivateRoute from 'src/routes/PrivateRoute';

import NotFoundPage from '../pages/not-found';

const PrinterRoutes = () => (
  <Routes>
    <Route path='/:uuid/material' element={<PrivateRoute Component={PrinterMaterialPage} />} />
    <Route path='/:uuid/already-loaded' element={<PrivateRoute Component={AlreadyLoadedPage} />} />
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default PrinterRoutes;
