import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from 'src/context/UserContext';

import routes from '../utils/routes';

const PrivateRoute = ({ Component, ...rest }) => {
  const { user } = useContext(UserContext);
  if (!user) {
    // Redirect to log in if there's no user
    return <Navigate replace to={routes.login} />;
  }

  return <Component user={user} {...rest} />;
};

PrivateRoute.propTypes = {
  Component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
