import { faQrcode } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import FeaturesContext from 'src/context/FeaturesContext';
import UserContext from 'src/context/UserContext';
import routes from 'src/utils/routes';

import Logo from '/public/static/logo.png';
import Logo2X from '/public/static/logo@2x.png';

import { checkSSO, createSession, getFeatures } from '../utils/auth';
import { CONTACTLESS_LOGIN_ENABLED, IDENTITY_PROVIDER_TYPE, JSON_ERRORS, LOCALSTORAGE_KEYS } from '../utils/constants';
import { isValidEmail, isValidPassword } from '../utils/validation';


const validateForm = (values, shouldValidatePassword) => {
  const { email, password } = values;
  const errors = {};

  try {
    isValidEmail(email);
  } catch (error) {
    errors.email = error.message;
  }

  if (shouldValidatePassword) {
    try {
      isValidPassword(password);
    } catch (error) {
      errors.password = error.message;
    }
  }

  return errors;
};


const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showAdUsername, setShowAdUsername] = useState(false);
  const [ssoUri, setSsoUri] = useState(null);
  const navigate = useNavigate();
  const [, , removeCookie] = useCookies([CONTACTLESS_LOGIN_ENABLED]);
  const { setUser } = useContext(UserContext);
  const { setFeatures } = useContext(FeaturesContext);

  const onSsoLoginClick = () => {
    window.location = ssoUri;
  };

  const onQrLoginClick = () => {
    navigate(routes.logonScan);
  };

  const submitButtonText = showPassword
    // For users with `SSO` option add `with Password` for clarity
    ? `Log In${ssoUri ? ' with Password' : ''}`
    : 'Next';

  useEffect(() => {
    localStorage.removeItem(LOCALSTORAGE_KEYS.AUTOLOGOUT_AFTER);
    removeCookie(CONTACTLESS_LOGIN_ENABLED);
  }, []);

  const handleLogin = async (email, password, adUsername, setStatus, setFieldValue, setSubmitting) => {
    try {
      const userData = await createSession(email, password, adUsername);
      setUser({
        ...userData,
        initialLogin: true,
      });

      const features = await getFeatures();
      setFeatures(features);
      return navigate(routes.scan, { replace: true });
    } catch (error) {
      console.error(error);

      if (error.response) {
        const json = await error.response.json();
        const jsonError = json.errors[0];
        setStatus({ msg: jsonError.title });

        if (jsonError.code === JSON_ERRORS.INVALID_CREDENTIALS) {
          setFieldValue('password', '');
        }

        setSubmitting(false);
      } else {
        setStatus({ msg: 'An error occurred. Please try again.' });
        setSubmitting(false);
      }
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 vw-100">
      <Formik
        initialValues={{ email: '', password: '', adUsername: '' }}
        validate={(values) => validateForm(values, showPassword)}
        onSubmit={(values, { setStatus, setSubmitting, setFieldValue }) => {
          const { email, password, adUsername } = values;

          if (!password) {
            checkSSO(email)
              .then((sso) => {
                // only SAML at the moment
                if (sso.identity_provider_type === IDENTITY_PROVIDER_TYPE.SAML) {
                  if (sso.allow_password_login) {
                    // If password login is allowed - user will choose login option. Saving SSO Uri for later
                    setSsoUri(sso.sso_uri);
                    // Enable Password login option for user to choose
                    setShowPassword(true);
                    setSubmitting(false);
                  } else {
                    // If password login is not allowed - redirect to SSO Uri right away
                    window.location = sso.sso_uri;
                  }
                } else if (sso.identity_provider_type === IDENTITY_PROVIDER_TYPE.LDAPS) {
                  setShowPassword(true);
                  setShowAdUsername(true);
                  setSubmitting(false);

                }
              })
              // API returns a 404 if SSO isn’t available
              .catch(() => {
                setShowPassword(true);
                setSubmitting(false);
              });

            return;
          }

          return handleLogin(email, password, adUsername, setStatus, setFieldValue, setSubmitting);
        }}
      >
        {({ handleSubmit, isSubmitting, errors, touched, status }) => (
          <Form
            noValidate
            action="/login"
            method="POST"
            className="form-login needs-validation p-3 mb-5"
            onSubmit={handleSubmit}
          >
            <div className="mb-4 text-center">
              <img src={Logo} srcSet={`${Logo}, ${Logo2X} 2x`} alt="Authentise" width="150" height="150" />
            </div>

            {status && status.msg && (
              <div className="alert alert-danger" role="alert">
                {status.msg}
              </div>
            )}

            <div className="form-group">
              <label htmlFor="email">Email Address:</label>
              <Field
                required
                aria-invalid={errors.email && touched.email ? 'true' : 'false'}
                aria-describedby="email-error"
                type="email"
                className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                id="email"
                name="email"
                autoFocus={!showPassword}
              />
              <ErrorMessage id="email-error" name="email" className="invalid-feedback" component="div" />
            </div>

            {showAdUsername && (
              <div className="form-group">
                <label htmlFor="adUsername">AD Username:</label>
                <Field
                  required
                  aria-invalid={errors.adUsername && touched.adUsername ? 'true' : 'false'}
                  aria-describedby="email-error"
                  className={`form-control ${errors.adUsername && touched.adUsername ? 'is-invalid' : ''}`}
                  type="text"
                  id="adUsername"
                  name="adUsername"
                />
                <ErrorMessage id="adUsername-error" name="adUsername" className="invalid-feedback" component="div" />
              </div>
            )}

            {showPassword && (
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <Field
                  required
                  aria-invalid={errors.password && touched.password ? 'true' : 'false'}
                  aria-describedby="password-error"
                  type="password"
                  className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''}`}
                  id="password"
                  name="password"
                  autoFocus={showPassword}
                />
                <ErrorMessage id="password-error" name="password" className="invalid-feedback" component="div" />
              </div>
            )}

            <button type="submit" className="btn btn-lg btn-primary btn-block" disabled={isSubmitting}>
              {submitButtonText}
            </button>

            {ssoUri && (
              <button type="button" className="btn btn-lg btn-success btn-block" onClick={onSsoLoginClick}>
                Login via SSO
              </button>
            )}
            <>
              <div className="d-flex align-items-center">
                <hr className="flex-grow-1" />
                <span className="p-2"> OR </span>
                <hr className="flex-grow-1" />
              </div>
              <button type="button" className="btn btn-lg btn-dark-lavender btn-block" onClick={onQrLoginClick}>
                <div className="d-flex justify-content-around align-items-center">
                  <FontAwesomeIcon icon={faQrcode} size="2x" className="mr-2" />
                  Login with QR code
                </div>
              </button>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginPage;
