import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Modal from 'src/components/modal';
import { api } from 'src/utils/api';
import { API_RESOURCES, MATERIAL_BATCH_ACTIONS } from 'src/utils/constants';
import { getShortUuid } from 'src/utils/url';

const SieveModal = ({ initialBatch, hideModal, redirectToNextAction, batchUUIDToFetch }) => {
  const [batch, setBatch] = useState(initialBatch);
  const { uuid: batchUUID } = useParams();
  const [error, setError] = useState(undefined);
  const [submitError, setSubmitError] = useState(null);
  const [remainingQuantity, setRemainingQuantity] = useState(undefined);
  const { addToast } = useToasts();

  const fetchInitialData = async () => {
    if (!initialBatch) {
      const batchUUIDToLoad = batchUUIDToFetch || batchUUID;
      try {
        const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUIDToLoad}/`).json();
        setBatch(batch);
        setRemainingQuantity(batch.quantity);
      } catch (error) {
        setError(error.name);
      }
    } else {
      setBatch(initialBatch);
      setRemainingQuantity(initialBatch.quantity);
    }
  };

  useEffect(() => void fetchInitialData(), [initialBatch]);

  useEffect(() => {
    if (submitError) {
      addToast(
        `Error: ${submitError}`,
        { appearance: 'error' },
      );
    }
  }, [submitError]);

  const setRemainingQuantityHandler = (changeEvent) => {
    setRemainingQuantity(Number(changeEvent.target.value));
  };

  const onActionSieve = async (batchUri, remainingQuantity) => {
    try {
      const payload = {
        /* eslint-disable camelcase */
        metadata: {
          remaining_quantity: remainingQuantity,
        },
        action_type: MATERIAL_BATCH_ACTIONS.SIEVE,
        source_batch: batchUri,
        /* eslint-enable camelcase */
      };

      await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
        json: payload,
      });
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      hideModal();
      return;
    }

    redirectToNextAction();

    return new Promise(() => {
    });
  };

  const remainingPercentage = batch?.quantity ? Math.round(remainingQuantity / batch.quantity * 100) : undefined;


  return (
    <Modal
      isOpen
      title={(
        <b>Sieve batch {getShortUuid(batch?.uri)}</b>
      )}
      dismissText="Cancel"
      confirmText="Confirm"
      confirmClass="btn-primary"
      onConfirm={() => onActionSieve(batch.uri, remainingQuantity)}
      onDismiss={hideModal}
    >
      <div className="sieve-modal-body">
        <div>
          <label>Remaining quantity:</label>
          &nbsp;<input
            name="remainingQuantity"
            min="0"
            max={batch?.quantity}
            type="number"
            value={remainingQuantity}
            onChange={setRemainingQuantityHandler}
        />
          &nbsp;<span>{batch?.units} ({remainingPercentage}%) </span>
        </div>
        <div>
          <span>0 ({batch?.units})</span>
          &nbsp;<input
            name="remainingQuantityRange"
            min="0"
            max={batch?.quantity}
            type="range"
            value={remainingQuantity}
            onChange={setRemainingQuantityHandler}
        />
          &nbsp;<span>{batch?.quantity} ({batch?.units})</span>
        </div>
      </div>
      {
        error && (
          <div className="alert alert-danger mt15" role="alert">
            {error}
          </div>
        )
      }
    </Modal>
  );
};

export default SieveModal;

SieveModal.propTypes = {
  initialBatch: PropTypes.shape({
    units: PropTypes.string,
    uri: PropTypes.string,
    quantity: PropTypes.number,
  }),
  hideModal: PropTypes.func.isRequired,
  redirectToNextAction: PropTypes.func.isRequired,
  batchUUIDToFetch: PropTypes.string,
};

SieveModal.defaultProps = {
  batchUUIDToFetch: null,
};
