import {
  faAngleLeft,
  faAngleRight,
  faArrowRight,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes, { arrayOf } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useToasts } from 'react-toast-notifications';

import { WORK_INSTRUCTION_TYPES } from '../utils/constants';
import {
  isWorkInstructionValidByThreshold,
  WORK_INSTRUCTION_THRESHOLD_ACTIONS,
  WORK_INSTRUCTION_THRESHOLD_TYPES,
} from '../utils/thresholds';
import Loader from './loader';
import NCRNotification from './ncr-notification';
import SubmitButton from './submit-button';

const WorkInstructionsCarousel = ({
  workInstructions,
  visibleItems = 7,
  values,
  handleSubmit,
  speed = 200,
  uploading,
  children,
  singleGroup,
  sendNCReviewForPiece,
  print,
  runTransformation,
  allPrints,
  setSentToNCRItems,
  sentToNCRItems,
  savedReportsByPrintUri,
  hasIncorrectValues,
  savedValues,
  setIsAbleToCompleteRun,
  currentInstruction,
  activeIndex,
  setActiveIndex,
  checkDifferentValue,
  isFileUploading,
}) => {
  const hasNCRAction =
    currentInstruction?.threshold &&
    currentInstruction.threshold_action === WORK_INSTRUCTION_THRESHOLD_ACTIONS.NCR_WARNING;
  const [highlightedNCRSteps, setHighlightedNCRSteps] = useState([]);
  const { addToast } = useToasts();
  const currentValue = values ? values[currentInstruction?.uuid] : null;
  const currentSavedValue = savedValues ? savedValues[currentInstruction?.uuid] : null;
  const wasSentToNCR = singleGroup?.collapsed
    ? sentToNCRItems.length === allPrints?.length
    : sentToNCRItems.includes(print.uri);

  const hasDifferentValue = checkDifferentValue(currentValue, currentSavedValue);

  // eslint-disable-next-line camelcase
  const isRequired =
    currentInstruction?.required &&
    currentInstruction?.report_type !== WORK_INSTRUCTION_TYPES.NO_ENTRY;

  useEffect(() => {
    setIsAbleToCompleteRun(!hasIncorrectValues);
  }, [hasIncorrectValues, setIsAbleToCompleteRun]);

  const [submittedItems, setSubmittedItems] = useState(values);

  const slickRef = useRef(null);
  const isLastItem = activeIndex === workInstructions.length - 1;

  const disableNextArrow =
    uploading ||
    (isRequired && !currentValue) ||
    // Need to check for Thresholds only if any value is provided
    (currentValue &&
      // And threshold data is available
      currentInstruction?.threshold &&
      // And `error` level is selected
      // eslint-disable-next-line camelcase
      currentInstruction?.threshold_action === WORK_INSTRUCTION_THRESHOLD_ACTIONS.ERROR &&
      // disable next button when value is not withing thresholds params
      // eslint-disable-next-line camelcase
      !isWorkInstructionValidByThreshold(
        currentValue,
        currentInstruction?.threshold,
        currentInstruction?.threshold_type
      ));

  const isAppropriateFormatForStorageReport = value => {
    if (typeof value !== 'string') {
      return false;
    }
    return value.match(/Stored\s+(.+)\s+at\s+(.+)/);
  };

  const handleNextSlide = async () => {
    if (activeIndex === workInstructions.length - 1) {
      return;
    }

    if (disableNextArrow) {
      return false;
    }

    if (submittedItems && submittedItems[currentInstruction?.uuid] !== currentValue) {
      if (
        currentInstruction.report_type === WORK_INSTRUCTION_TYPES.OUTPUT_AT_LOCATION &&
        !isAppropriateFormatForStorageReport(currentValue)
      ) {
        return addToast('Please enter values for both the [Amount] and [Location] fields.', {
          appearance: 'warning',
        });
      }

      await handleSubmit(activeIndex);
      setSubmittedItems(prev => ({ ...prev, [currentInstruction?.uuid]: currentValue }));
    }

    if (
      currentInstruction.threshold_action === WORK_INSTRUCTION_THRESHOLD_ACTIONS.NCR_WARNING &&
      currentInstruction?.threshold &&
      !isWorkInstructionValidByThreshold(
        currentValue,
        currentInstruction?.threshold,
        currentInstruction?.threshold_type
      ) &&
      !sentToNCRItems.includes(print?.uri)
    ) {
      return false;
    } else {
      slickRef.current.slickNext();
      return setTimeout(() => {
        setActiveIndex(activeIndex + 1);
      }, speed);
    }
  };

  const handlePreviousSlide = () => {
    if (activeIndex === 0) {
      return;
    }

    slickRef.current.slickPrev();
    return setTimeout(() => {
      setActiveIndex(activeIndex - 1);
    }, speed);
  };

  const handleSetActiveSlide = idx => {
    slickRef.current.slickGoTo(idx);
    setActiveIndex(idx);
  };

  const handleSubmitWorkInstruction = async () => {
    // eslint-disable-next-line camelcase
    const { required, report_type: reportType } = currentInstruction;

    const isNotValidByThreshold =
      currentInstruction?.threshold &&
      !isWorkInstructionValidByThreshold(
        currentValue,
        currentInstruction?.threshold,
        currentInstruction?.threshold_type
      );

    if (required && !currentValue) {
      return false;
    }

    if (
      reportType === WORK_INSTRUCTION_TYPES.OUTPUT_AT_LOCATION &&
      !isAppropriateFormatForStorageReport(currentValue)
    ) {
      return addToast('Please enter values for both the [Amount] and [Location] fields.', {
        appearance: 'warning',
      });
    }

    await handleSubmit(activeIndex);
    setSubmittedItems(prev => ({ ...prev, [currentInstruction?.uuid]: currentValue }));

    if (currentValue && hasNCRAction && !wasSentToNCR && isNotValidByThreshold) {
      if (!highlightedNCRSteps.includes(currentInstruction.uuid)) {
        setHighlightedNCRSteps(prev => [...prev, currentInstruction.uuid]);
        setIsAbleToCompleteRun(false);
        return;
      } else {
        setActiveIndex(activeIndex + 1);
        slickRef.current.slickNext();
        return addToast('Please confirm if the piece(s) should be sent to NC', {
          appearance: 'warning',
        });
      }
    }

    if (activeIndex !== workInstructions.length - 1) {
      setHighlightedNCRSteps(prev => prev.filter(item => item !== currentInstruction.uuid));
      setActiveIndex(activeIndex + 1);
      slickRef.current.slickNext();
    }
    return true;
  };

  const handleRenderNCRNotification = () => {
    if (singleGroup?.collapsed) {
      const hasIncorrectSubmittedValues = Object.fromEntries(
        Object.entries(savedReportsByPrintUri).filter(([key, value]) => {
          const currentPrints = Object.entries(value).filter(([key, value]) => {
            const currentInstruction = workInstructions.find(
              instruction => instruction.uuid === key
            );
            const filteredSubmittedItems = Object.fromEntries(
              Object.entries(submittedItems || {}).filter(([key, value]) => {
                return typeof value === 'number' || Boolean(value) ? key : null;
              })
            );
            // eslint-disable-next-line camelcase
            if (
              currentInstruction?.threshold_action ===
                WORK_INSTRUCTION_THRESHOLD_ACTIONS.NCR_WARNING &&
              // eslint-disable-next-line camelcase
              !isWorkInstructionValidByThreshold(
                value,
                currentInstruction?.threshold,
                currentInstruction?.threshold_type
              ) &&
              Object.keys(filteredSubmittedItems).includes(key)
            ) {
              return key;
            }
          });

          if (currentPrints.length) {
            return key;
          }

          return null;
        })
      );

      if (hasIncorrectSubmittedValues) {
        const printsToSendToNCR = Object.keys(hasIncorrectSubmittedValues || {});
        const isNotValidByThreshold =
          currentInstruction?.threshold &&
          !isWorkInstructionValidByThreshold(
            currentSavedValue,
            currentInstruction?.threshold,
            currentInstruction?.threshold_type
          );

        if (
          (submittedItems &&
            submittedItems[currentInstruction?.uuid] &&
            submittedItems[currentInstruction?.uuid] === currentSavedValue &&
            isNotValidByThreshold &&
            hasNCRAction &&
            currentSavedValue &&
            !wasSentToNCR) ||
          (wasSentToNCR && hasNCRAction)
        ) {
          return (
            <NCRNotification
              singleGroup={singleGroup}
              sendNCReviewForPiece={sendNCReviewForPiece}
              print={print}
              runTransformation={runTransformation}
              sentToNCRItems={sentToNCRItems}
              setSentToNCRItems={setSentToNCRItems}
              printsToSendToNCR={printsToSendToNCR}
              splitRuns={allPrints.length !== printsToSendToNCR.length}
              wasNCRHighlighted={highlightedNCRSteps.includes(currentInstruction.uuid)}
              setHighlightedNCRSteps={setHighlightedNCRSteps}
              currentInstruction={currentInstruction}
              wasSentToNCR={wasSentToNCR}
            />
          );
        }
      }
    } else {
      const isNotValidByThreshold =
        currentInstruction?.threshold &&
        !isWorkInstructionValidByThreshold(
          currentSavedValue,
          currentInstruction?.threshold,
          currentInstruction?.threshold_type
        );

      if (
        (submittedItems[currentInstruction?.uuid] &&
          isNotValidByThreshold &&
          hasNCRAction &&
          currentSavedValue &&
          !wasSentToNCR) ||
        (submittedItems.length === allPrints?.length && hasNCRAction && wasSentToNCR)
      ) {
        return (
          <NCRNotification
            singleGroup={singleGroup}
            sendNCReviewForPiece={sendNCReviewForPiece}
            print={print}
            runTransformation={runTransformation}
            sentToNCRItems={sentToNCRItems}
            setSentToNCRItems={setSentToNCRItems}
            splitRuns={false}
            wasNCRHighlighted={highlightedNCRSteps.includes(currentInstruction.uuid)}
            setHighlightedNCRSteps={setHighlightedNCRSteps}
            currentInstruction={currentInstruction}
            wasSentToNCR={wasSentToNCR}
          />
        );
      }
    }
  };

  const warningNcrButton = highlightedNCRSteps.includes(currentInstruction.uuid) && !wasSentToNCR;

  return (
    <>
      <Slider
        ref={slickRef}
        initialSlide={activeIndex}
        infinite={false}
        speed={speed}
        swipe={false}
        className='work-instructions-carousel-slick'
      >
        {children}
      </Slider>
      <div className='work-instructions-carousel-content'>
        <div className='work-instructions-carousel'>
          <div
            className={`work-instructions-carousel-prev-arrow ${activeIndex === 0 ? 'work-instructions-carousel-inactive-arrow' : ''}`}
            onClick={handlePreviousSlide}
          >
            <FontAwesomeIcon
              className='slick-arrow work-instructions-carousel-arrow'
              icon={faAngleLeft}
            />
          </div>

          <div
            className='d-flex work-instructions-carousel-container'
            style={{
              justifyContent: workInstructions.length <= 5 ? 'center' : 'space-between',
            }}
          >
            {workInstructions.map((item, index) => {
              const currentItem = workInstructions.find(item2 => item2.uuid === item.uuid);
              const wasNCRHighlighted = highlightedNCRSteps.includes(item.uuid);
              const classes = classNames(
                'work-instructions-carousel-item',
                index === activeIndex && 'work-instructions-carousel-item-active',
                submittedItems &&
                  submittedItems[item.uuid] &&
                  'work-instructions-carousel-item-submitted',
                currentItem?.threshold &&
                  submittedItems[item.uuid] &&
                  !isWorkInstructionValidByThreshold(
                    submittedItems[item.uuid],
                    currentItem.threshold,
                    currentItem.threshold_type
                  ) &&
                  'work-instructions-carousel-item-invalid',
                currentItem?.threshold &&
                  submittedItems[item.uuid] &&
                  !isWorkInstructionValidByThreshold(
                    submittedItems[item.uuid],
                    currentItem.threshold,
                    currentItem.threshold_type
                  ) &&
                  wasNCRHighlighted &&
                  !wasSentToNCR &&
                  'ncr-highlighted-step'
              );
              return (
                <span
                  key={item.uuid}
                  className={classes}
                  style={{
                    transform:
                      activeIndex >= 6
                        ? `translate3d(-${(activeIndex - 4) * (100 + (activeIndex + 4 * visibleItems))}%, 0px, 0px)`
                        : `translate3d(0px, 0px, 0px)`,
                    position: 'relative',
                    left: activeIndex >= 6 ? `-${((activeIndex - 4) * 23) / 100}px` : '0px',
                  }}
                  onClick={() => handleSetActiveSlide(index)}
                >
                  {index + 1}
                </span>
              );
            })}
          </div>

          <div
            className={`work-instructions-carousel-next-arrow ${isLastItem || disableNextArrow ? 'work-instructions-carousel-inactive-arrow' : ''}`}
            onClick={handleNextSlide}
          >
            <FontAwesomeIcon
              className={`slick-arrow work-instructions-carousel-arrow ${highlightedNCRSteps.includes(currentInstruction.uuid) && !wasSentToNCR ? 'ncr-highlighted-arrow' : ''}`}
              icon={faAngleRight}
            />
          </div>
        </div>

        <SubmitButton
          className={`${warningNcrButton ? 'btn-warning' : 'btn-success'} work-instructions-carousel-submit-btn ${disableNextArrow || (!hasDifferentValue && !warningNcrButton) ? 'work-instructions-carousel-submit-btn-inactive' : ''}`}
          disabled={isFileUploading}
          onClick={handleSubmitWorkInstruction}
        >
          {isFileUploading ? (
            <Loader isSmall showText={false} />
          ) : (
            <FontAwesomeIcon
              icon={
                submittedItems && submittedItems[currentInstruction?.uuid] ? faCheck : faArrowRight
              }
            />
          )}
        </SubmitButton>
      </div>
      <span className='work-instructions-carousel-submitted'>
        {Object.values(submittedItems || {}).filter(Boolean).length} of {workInstructions.length}{' '}
        instructions submitted
      </span>
      {/* eslint-disable-next-line camelcase */}
      {handleRenderNCRNotification()}
    </>
  );
};

WorkInstructionsCarousel.defaultProps = {
  visibleItems: 7,
  speed: 200,
  singleGroup: {},
  print: {},
  runTransformation: [],
  allPrints: [],
};

WorkInstructionsCarousel.propTypes = {
  workInstructions: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      required: PropTypes.bool,
      threshold: PropTypes.shape({}),
      // eslint-disable-next-line camelcase
      threshold_type: PropTypes.string,
      // eslint-disable-next-line camelcase
      threshold_action: PropTypes.oneOf(Object.values(WORK_INSTRUCTION_THRESHOLD_ACTIONS)),
      // eslint-disable-next-line camelcase
      report_type: PropTypes.string,
    })
  ).isRequired,
  visibleItems: PropTypes.number,
  values: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  speed: PropTypes.number,
  children: PropTypes.node.isRequired,
  uploading: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      instruction: PropTypes.string.isRequired,
      file: PropTypes.object.isRequired,
      progress: PropTypes.number.isRequired,
    }),
  ]),
  singleGroup: PropTypes.shape({
    collapsed: PropTypes.bool,
  }),
  sendNCReviewForPiece: PropTypes.func.isRequired,
  print: PropTypes.shape({
    uri: PropTypes.string,
  }),
  runTransformation: arrayOf(PropTypes.shape({})),
  allPrints: PropTypes.arrayOf(PropTypes.shape({})),
  setSentToNCRItems: PropTypes.func.isRequired,
  sentToNCRItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  savedValues: PropTypes.shape({}).isRequired,
  hasIncorrectValues: PropTypes.bool.isRequired,
  savedReportsByPrintUri: PropTypes.shape({}).isRequired,
  setIsAbleToCompleteRun: PropTypes.func.isRequired,
  currentInstruction: PropTypes.shape({
    uuid: PropTypes.string,
    threshold: PropTypes.shape({}),
    // eslint-disable-next-line camelcase
    threshold_action: PropTypes.oneOf(Object.values(WORK_INSTRUCTION_THRESHOLD_ACTIONS)),
    // eslint-disable-next-line camelcase
    threshold_type: PropTypes.oneOf(Object.values(WORK_INSTRUCTION_THRESHOLD_TYPES)),
    // eslint-disable-next-line camelcase
    report_type: PropTypes.string,
    required: PropTypes.bool,
  }).isRequired,
  activeIndex: PropTypes.number.isRequired,
  setActiveIndex: PropTypes.func.isRequired,
  checkDifferentValue: PropTypes.func.isRequired,
  isFileUploading: PropTypes.bool.isRequired,
};

export default WorkInstructionsCarousel;
