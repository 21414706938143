import { faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _flatten from 'lodash/flatten';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ActionContext from 'src/components/action-context';
import Loader from 'src/components/loader';
import { api } from 'src/utils/api';
import { API_RESOURCES, MATERIAL_BATCH_ACTIONS, PERMANENT_CONTAINER_ACTIONS } from 'src/utils/constants';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../../utils/routes';
import {
  formatUnloadQuantityInMachineNumber, preventNegativeOnKeyDownValue,
  renderRemainingValueClass,
} from '../../../material-batch/[uuid]/action/machine-unload-hopper';
import PermanentContainerActionPageWrapper from './_action-wrapper';
import { checkSupportedMaterialsMismatch } from './load-material';

const ActionUnloadToContainer = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { uuid: permanentContainerUUID } = useParams();
  const {
    printer: printerUri,
    batch: batchUri,
    sieveQuantity,
    initialAction,
    initialSieveAction: isInitialSieveAction,
  } = Object.fromEntries(searchParams.entries()) ?? {};

  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [batch, setBatch] = useState(null);
  const [subLocation, setSubLocation] = useState(undefined);
  const [hasNoCapacityForMultipleContainers, setHasNoCapacityForMultipleContainers] = useState(false);
  const [isFinishContainerActionDisabled, setIsFinishContainerActionDisabled] = useState(true);
  const [remainingQuantity, setRemainingQuantity] = useState(undefined);
  const [initialQuantity, setInitialQuantity] = useState(undefined);
  const [action, setAction] = useState(initialAction);

  const isUnloadReclaimedPowderAction = initialAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL;
  const isUnloadUnusedMaterialHopperAction = initialAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL;
  const isSieveBatchToContainerAction = initialAction === PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH;

  const {
    containerActionData,
  } = useContext(ActionContext);
  const { permanentContainersActionState } = containerActionData;

  const navigate = useNavigate();

  const unsupportedMaterials = useMemo(() => {
    return _flatten(Object.values(permanentContainersActionState.containersScanned).map(container => {
      return checkSupportedMaterialsMismatch(batch, container, true);
    }));
  }, [batch, permanentContainersActionState.containersScanned]);

  const getInitialData = async () => {
    setIsLoading(true);
    try {
      const permanentContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${permanentContainerUUID}/`).json();
      const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(batchUri)}/`).json();
      const subLocation = await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batch.sub_location)}/`).json();
      setBatch(batch);
      setSubLocation(subLocation);
      setInitialQuantity(batch?.quantity);
      const currentInitialQuantity = isInitialSieveAction ? Number(sieveQuantity) : batch?.quantity;
      setRemainingQuantity(isInitialSieveAction ? batch?.quantity : currentInitialQuantity);

      // Calculate the total quantity already allocated to the scanned containers
      let totalAllocatedQuantity = 0;
      Object.values(permanentContainersActionState.containersScanned).forEach((container) => {
        totalAllocatedQuantity += container.quantity;
      });

      // Calculate the maxQuantity for the new container
      let newContainerMaxQuantity = isInitialSieveAction ? batch?.quantity : (currentInitialQuantity - totalAllocatedQuantity);

      // Add the new container with the calculated maxQuantity
      containerActionData.addScannedContainer({
        ...permanentContainer,
        quantity: isInitialSieveAction ? currentInitialQuantity : 0,
        maxQuantity: newContainerMaxQuantity,
      });
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const setCurrentActionHandler = () => {
    if (isSieveBatchToContainerAction) {
      return setAction(PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH);
    }

    if (isUnloadUnusedMaterialHopperAction) {
      return setAction(PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL);
    }

    return setAction(PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL);
  };

  useEffect(() => {
    return void getInitialData();
  }, [permanentContainerUUID, batchUri]);

  useEffect(() => {
    setCurrentActionHandler();
  }, [initialAction]);

  const materialActionRequest = (payload) => {
    return api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
      json: payload,
    });
  };

  const handleUnloadUnusedMaterialAction = async (quantity, actionType) => {
    const metadata = {
      /* eslint-disable camelcase */
      unload_amount: quantity,
      ...(isUnloadReclaimedPowderAction && { unload_type: 'reclaimed' }),
    };

    const payload = {
      /* eslint-disable camelcase */
      source_batch: batchUri,
      action_type: actionType,
      metadata,
    };
    setSubmitting(true);
    const { metadata: { resulting_batch: unloadedBatch } } = await materialActionRequest(payload).json();
    return unloadedBatch;
  };

  const handleSieveBatchAction = async (quantity) => {
    const payload = {
      /* eslint-disable camelcase */
      metadata: {
        remaining_quantity: quantity,
      },
      action_type: MATERIAL_BATCH_ACTIONS.SIEVE,
      source_batch: batchUri,
    };

    setSubmitting(true);
    await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
      json: payload,
    });
  };

  const onActionFinish = async (batchUri, value, action) => {
    const quantityToUnload = Object.values(permanentContainersActionState.containersScanned).reduce((sum, container) => sum + container.quantity, 0);
    const unloadActionType = isUnloadUnusedMaterialHopperAction
      ? MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER
      : MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER;

    let resultingBatch = null;

    try {
      resultingBatch = isSieveBatchToContainerAction
        ? await handleSieveBatchAction(quantityToUnload)
        : await handleUnloadUnusedMaterialAction(quantityToUnload, unloadActionType);

      const containersWithUriAndQuantity = Object.values(permanentContainersActionState.containersScanned).map((container) => {
        return {
          uri: container.uri,
          quantity: container.quantity,
        };
      });

      const containerizePayload = {
        action_type: MATERIAL_BATCH_ACTIONS.CONTAINERIZE_BATCH,
        source_batch: isSieveBatchToContainerAction ? batchUri : resultingBatch,
        metadata: {
          containers: containersWithUriAndQuantity,
        },
      };

      await api.post('material-batch-action/', {
        json: containerizePayload,
      });

    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    navigate(getRouteURI(routes.permanentContainerSuccess,
      { uuid: permanentContainerUUID },
      {
        action,
        batch: isSieveBatchToContainerAction ? getUuid(batch.uri) : getUuid(resultingBatch),
        quantity: quantityToUnload,
        remainingQuantity: value,
        subLocation: subLocation.name,
        ...(isSieveBatchToContainerAction ? {} : { printer: printerUri }),
      }));

    return new Promise(() => {
    });
  };

  const onActionScanAdditionalContainer = async (batchUri, action) => {
    navigate(getRouteURI(routes.scan,
      {},
      {
        entity: API_RESOURCES.MATERIAL_CONTAINER,
        action,
        'material-batch': batchUri,
        ...(!isSieveBatchToContainerAction && { printer: printerUri }),
        ...(isSieveBatchToContainerAction && { sieveQuantity }),
        isSameScan: true,
      }));

    return new Promise(() => {
    });
  };

  useEffect(() => {
    const containers = Object.values(permanentContainersActionState.containersScanned);

    const totalQuantityUsed = containers.reduce((sum, container) => sum + container.quantity, 0);
    setRemainingQuantity(initialQuantity - totalQuantityUsed);

    // If there's only one container and its quantity is the full batch quantity,
    // or if all containers have a quantity set, the finish unload should be enabled.
    const isAnyContainerEmpty = containers.some((container) => container.quantity === 0);
    const isOnlyOneContainerFull = containers.length === 1 && totalQuantityUsed === initialQuantity;
    setIsFinishContainerActionDisabled(isAnyContainerEmpty && !isOnlyOneContainerFull);

    // Determine if we have capacity for another container
    const predictedRemainingQuantity = initialQuantity - totalQuantityUsed;
    const hasCapacityForAnotherContainer = predictedRemainingQuantity > 0;

    setHasNoCapacityForMultipleContainers(!hasCapacityForAnotherContainer);
  }, [JSON.stringify(permanentContainersActionState.containersScanned), initialQuantity]);


  const setRemainingQuantityHandler = (event, containerUri) => {
    const inputValue = event.target.value;
    const inputQuantity = inputValue === '' ? '' : parseFloat(inputValue);

    const containerUuid = getUuid(containerUri);
    const { containersScanned } = permanentContainersActionState;

    let totalScannedQuantity = 0;

    // Update the quantity for the current container
    if (containersScanned[containerUuid]) {
      containersScanned[containerUuid].quantity = inputQuantity;
    }

    // Calculate total scanned quantity
    Object.values(containersScanned).forEach((container) => {
      totalScannedQuantity += container.quantity;
    });

    // Update maxQuantity for each container
    Object.keys(containersScanned).forEach((uuid) => {
      const remainingQuantity = initialQuantity - totalScannedQuantity + containersScanned[uuid].quantity;
      containersScanned[uuid].maxQuantity = Math.min(remainingQuantity, initialQuantity);
    });

    // Update the remaining quantity in the machine
    setRemainingQuantity(parseFloat((initialQuantity - totalScannedQuantity).toFixed(2)));
    containerActionData.updateContainerQuantities(containersScanned);
  };

  const removeContainerHandler = (containerUri) => {
    containerActionData.removeScannedContainer(containerUri);
  };

  const resetActionContainerState = () => {
    // Reset the context state to its initial state
    containerActionData.resetActionContainerState();
  };

  const renderActionContainerUI = (containerRemainingPercentage, container, containersLength, isNotSupportedByMaterials) =>
    (
      <>
        <p className="mb0 d-flex align-items-center justify-content-center">
          <strong>{container?.name}</strong>
          {isNotSupportedByMaterials && (
            <FontAwesomeIcon icon={faExclamationTriangle} className="d-block ml-2 warning-color" />
          )}
        </p>
        <div className="alert">
          <div className={`d-flex align-items-center 
              ${containersLength > 1 ? 'justify-content-between' : 'justify-content-center'}`}
          >
            <div className="mb10 d-flex align-items-center">
              <input
                name="remainingQuantity"
                min={0}
                max={container.maxQuantity}
                type="number"
                className="unload-hopper-input"
                value={container.quantity}
                onKeyDown={preventNegativeOnKeyDownValue}
                onChange={(e) => setRemainingQuantityHandler(e, container.uri)}
              />
              <div className="unload-hopper-input-input-text"><p className="mb0 mr5">{batch?.units}</p>&nbsp;
                <p className={`mb0 ${remainingQuantity < 0 ? 'text-danger' : ''}`}>
                  ({containerRemainingPercentage}%)
                </p>
              </div>
            </div>
            {
              containersLength > 1 && (
                <button
                  type="button"
                  className="btn btn-default btn-action"
                  onClick={() => removeContainerHandler(container.uri)}
                >
                  <FontAwesomeIcon color="#F31414" size="2x" icon={faXmark} />
                </button>
              )
            }
          </div>
          {
            containersLength <= 1 && (
              <div>
                <span>0 ({batch?.units})</span>
                <input
                  name="remainingQuantityRange"
                  min="0"
                  className="unload-hopper-input-slider"
                  max={container.maxQuantity}
                  type="range"
                  value={container.quantity}
                  onChange={(e) => setRemainingQuantityHandler(e, container.uri)}
                />
                <span>{container.maxQuantity} ({batch?.units})</span>
              </div>
            )
          }
        </div>
        <hr className="flex-grow-1" />
      </>
    );

  return (
    <PermanentContainerActionPageWrapper
      id={permanentContainerUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={action}
      isLoading={isLoading}
    >
      <div className="d-flex align-items-center justify-content-center alert alert-info" role="alert">
        <b>Please
          specify {isSieveBatchToContainerAction ? 'amount to sieve' : 'unused amount to remove'}. {(!isUnloadReclaimedPowderAction && !isSieveBatchToContainerAction) &&
            <span>Unused material will not receive an additional Usage Cycle.</span>}
        </b>
      </div>

      <p className="font-bold">Total Quantity {isSieveBatchToContainerAction ? 'to Sieve' : 'in Machine'}:&nbsp;
        <span className={renderRemainingValueClass(remainingQuantity)}>
          {formatUnloadQuantityInMachineNumber(remainingQuantity)} ({batch?.units})
        </span>
        {
          remainingQuantity < 0 && (
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip id="sieve-value-exceeded">
                  Remaining sieve quantity exceeds the batch quantity
                </Tooltip>
              )}
            >
              <FontAwesomeIcon icon={faTriangleExclamation} color="#FFC008" className="spacer-left" />
            </OverlayTrigger>
          )
        }
      </p>
      <hr className="flex-grow-1" />

      {
        Object.values(permanentContainersActionState.containersScanned).map((container) => {
          const containerRemainingPercentage = Math.round(container.quantity / initialQuantity * 100);
          const containersLength = Object.values(permanentContainersActionState.containersScanned).length;
          const isNotSupportedByMaterials = unsupportedMaterials.find((unsupportedContainer) => unsupportedContainer.uri === container.uri);
          return (
            <div key={container.uri}>
              {renderActionContainerUI(containerRemainingPercentage, container, containersLength, isNotSupportedByMaterials)}
            </div>
          );
        })
      }
      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isSubmitting || isFinishContainerActionDisabled || (remainingQuantity === initialQuantity || remainingQuantity < 0) || unsupportedMaterials.length}
        onClick={() => onActionFinish(batch.uri, remainingQuantity, action)}
      >
        <p className="d-flex align-items-center justify-content-center mb0">
          Finish {isSieveBatchToContainerAction ? 'Sieve' : 'Unload'}
          {isSubmitting && <Loader inline className="spacer-left" showText={false} />}
          {unsupportedMaterials.length ? (
            <OverlayTrigger
              placement="right"
              overlay={(
                <Tooltip id="workstation-is-overloaded">
                  {
                    unsupportedMaterials.map((container) => (
                      <p key={container.name} className="mb5 mt5">
                        Permanent Container &#39;{container.name}&#39; does not support&nbsp;
                        {unsupportedMaterials.length > 1 ? 'materials' : 'material'}&nbsp;
                        {container.materialsNotSupported.map((material) => material).join(', ')}
                      </p>

                    ))
                  }
                </Tooltip>)}
            >
              <FontAwesomeIcon icon={faExclamationTriangle} className="d-block ml-2 warning-color" />
            </OverlayTrigger>
          ) : null}
        </p>
      </button>

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isSubmitting || hasNoCapacityForMultipleContainers}
        onClick={() => onActionScanAdditionalContainer(batch.uri, action)}
      >
        Load Another Container
      </button>

      <Link to={getRouteURI(routes.printerMaterial, { uuid: getUuid(printerUri) })}>
        <button
          type="button" disabled={isSubmitting} className="btn btn-default btn-action"
          onClick={resetActionContainerState}
        >
          Cancel
        </button>
      </Link>
    </PermanentContainerActionPageWrapper>
  );
};

ActionUnloadToContainer.propTypes = {
  user: userPropType,
};

ActionUnloadToContainer.defaultProps = {
  user: null,
};

export default ActionUnloadToContainer;
