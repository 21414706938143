export const pluralWord = (baseWordForm, array, character = 's') => {
  if (!array) return baseWordForm;

  const pluralRules = new Intl.PluralRules();

  if (pluralRules.select(array.length) !== 'one') {
    return `${baseWordForm}${character}`;
  }

  return baseWordForm;
};
