import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ActionContext from 'src/components/action-context';
import Header from 'src/components/header.jsx';
import Loader from 'src/components/loader';
import MaterialBatchSuccessCardDetails from 'src/components/material-batch-success-card-details';
import NotFound from 'src/components/not-found';
import { api } from 'src/utils/api';
import { API_RESOURCES, MATERIAL_BATCH_ACTIONS, PERMANENT_CONTAINER_ACTIONS } from 'src/utils/constants';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../utils/routes';

const PrinterMaterialPage = ({ user }) => {
  const { uuid: printerUuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [pageState, setPageState] = useState({
    id: printerUuid,
    printer: null,
    materialBatches: null,
    error: null,
    batchSubLocation: null,
  });

  const {
    containerActionData,
  } = useContext(ActionContext);

  const resetUnloadMaterialState = () => {
    // Reset the context state to its initial state
    containerActionData.resetActionContainerState();
  };

  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      const printer = await api.get(`${API_RESOURCES.PRINTER}/${printerUuid}/`).json();
      const { resources: materialBatchesAtMachine } = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/`, {
        searchParams: {
          'filter[at_machine]': printer.uri,
        },
      }).json();


      const batchSubLocation = materialBatchesAtMachine?.[0] ?
        await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(materialBatchesAtMachine[0].sub_location)}/`).json() :
        null;

      setPageState((prevState) => ({
        ...prevState,
        id: printerUuid,
        printer,
        materialBatches: materialBatchesAtMachine,
        batchSubLocation: batchSubLocation?.name,
      }));
    } catch (error) {
      if (error.name === 'HTTPError' && error.response.status === 404) {
        setPageState((prevState) => ({
          ...prevState,
          error: {
            name: error.name,
            status: error.response && error.response.status,
          },
        }));
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, [printerUuid]);


  if (pageState.error) {
    return (
      <>
        <Header title="Not Found" user={user} />
        <main role="main" className="text-center">
          <NotFound id={pageState.id} />
        </main>
      </>
    );
  }
  if (isLoading) {
    return <Loader />;
  }

  const materialBatch = pageState.materialBatches.length && pageState.materialBatches[0];
  const isBatchSummaryVisible = Boolean(materialBatch);

  return (
    <>
      <Header title="Printer Information" back="/" />
      <main role="main">
        <h2 className="header-margin">Printer name: {pageState.printer.name}</h2>
        {
          isBatchSummaryVisible &&
          <MaterialBatchSuccessCardDetails
            batch={materialBatch}
            subLocation={pageState.batchSubLocation} />
        }
        {!materialBatch ?
          <Link
            to={getRouteURI(routes.scan,
              {},
              {
                printer: pageState.printer.uri,
                entity: API_RESOURCES.MATERIAL_BATCH,
                action: MATERIAL_BATCH_ACTIONS.MACHINE_LOAD,
              })}
            className="link-btn"
          >
            <button type="button" className="btn btn-lg btn-primary btn-block">Load Material</button>
          </Link>
          :
          <Link
            to={getRouteURI(routes.scan,
              {},
              {
                printer: pageState.printer.uri,
                entity: API_RESOURCES.MATERIAL_BATCH,
                action: MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF,
              })}
            className="link-btn"
          >
            <button type="button" className="btn btn-lg btn-primary btn-block">Load Material (Top Off)
            </button>
          </Link>}

        <Link
          to={getRouteURI(routes.scan,
            {},
            {
              printer: pageState.printer.uri,
              'material-batch': materialBatch?.uri,
              entity: API_RESOURCES.MATERIAL_CONTAINER,
              action: PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL,
            })}
          className="link-btn"
        >
          <button
            type="button"
            disabled={!materialBatch}
            className="btn btn-lg btn-primary btn-block"
            onClick={resetUnloadMaterialState}
          >Unload Unused Material
          </button>
        </Link>


        <Link
          to={getRouteURI(routes.scan,
            {},
            {
              printer: pageState.printer.uri,
              'material-batch': materialBatch?.uri,
              entity: API_RESOURCES.MATERIAL_CONTAINER,
              action: PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL,
            })}
          className="link-btn"
        >
          <button
            type="button"
            disabled={!materialBatch}
            className="btn btn-lg btn-primary btn-block"
            onClick={resetUnloadMaterialState}
          >Complete Print
            Job: Unload Reclaimed Powder
          </button>
        </Link>

        <Link
          to={getRouteURI(routes.materialBatchAction,
            { uuid: getUuid(materialBatch.uri) },
            {
              type: MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER,
            })}
          className="link-btn"
        >
          <button
            type="button" disabled={!materialBatch}
            className="btn btn-lg btn-primary btn-block"
          >Complete Print
            Job: Record Build Weight
          </button>
        </Link>
      </main>
    </>
  );
};

PrinterMaterialPage.propTypes = {
  user: userPropType,
};

PrinterMaterialPage.defaultProps = {
  user: null,
};

export default PrinterMaterialPage;
