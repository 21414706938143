import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import Modal from 'src/components/modal';
import { api } from 'src/utils/api';
import { API_RESOURCES, PERMANENT_CONTAINER_ACTIONS } from 'src/utils/constants';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../../utils/routes';
import PermanentContainerActionPageWrapper from './_action-wrapper';


export function checkSupportedMaterialsMismatch(batch, permanentContainer, isMultiple = false) {
  if (!batch?.materials || !permanentContainer?.material_restrictions?.length) {
    return [];
  }

  const currentUnsupportedMaterials = batch.materials
    .filter((material) => !permanentContainer.material_restrictions.includes(material.uri))
    .map((material) => material.name);

  // If isMultiple is true, return the data in the specified structure
  if (isMultiple && currentUnsupportedMaterials.length) {
    return [{
      name: permanentContainer.name, // Adjust 'name' as necessary based on your object structure
      materialsNotSupported: currentUnsupportedMaterials,
      uri: permanentContainer.uri,
    }];
  }

  // If isMultiple is false, return just the names of the materials
  return currentUnsupportedMaterials;
}

const LoadMaterial = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { uuid: permanentContainerUUID } = useParams();
  const { batch: batchUri } = Object.fromEntries(searchParams.entries()) ?? {};
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [showRelocateModal, setShowRelocateModal] = useState(false);

  const [batch, setBatch] = useState(null);
  const [permanentContainer, setPermanentContainer] = useState(null);
  const [isPermanentContainerAlreadyLoaded, setIsPermanentContainerAlreadyLoaded] = useState(false);
  const [locations, setLocations] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const getInitialData = async (batchUri) => {
    setIsLoading(true);
    try {
      const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(batchUri)}/`).json();
      const permanentContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${permanentContainerUUID}/`).json();
      const isPermanentContainerAlreadyLoaded = permanentContainer.current_batch;

      const subLocationsUris = permanentContainer && batch ?
        [...new Set([permanentContainer.sub_location, batch.sub_location])] :
        [];

      const { resources: subLocations } = await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
        searchParams: {
          'filter[uri]': subLocationsUris.join(','),
        },
      }).json();

      const { resources: locations } = await api.get(`${API_RESOURCES.LOCATION}/`, {
        searchParams: {
          'filter[uri]': permanentContainer.location,
        },
      }).json();

      setBatch(batch);
      setPermanentContainer(permanentContainer);
      setIsPermanentContainerAlreadyLoaded(isPermanentContainerAlreadyLoaded);
      setLocations(locations);
      setSubLocations(subLocations);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => void getInitialData(batchUri), [batchUri]);

  const unsupportedMaterials = checkSupportedMaterialsMismatch(batch, permanentContainer);

  const hasDifferentLocations = batch?.location !== permanentContainer?.location || batch?.sub_location !== permanentContainer?.sub_location;

  useEffect(() => {
    if (isPermanentContainerAlreadyLoaded) {
      // Permanent Container is already loaded. Redirecting to Top Off confirmation page
      navigate(getRouteURI(routes.permanentContainerAlreadyLoaded,
        { uuid: getUuid(permanentContainer.uri) },
        { batch: batch.uri }));
    }
  }, [isPermanentContainerAlreadyLoaded]);

  const handleSubmitLoadMaterial = () => {
    if (hasDifferentLocations) {
      setShowRelocateModal(true);
      return;
    }

    return onActionPermanentContainerLoad(permanentContainer.uri, batch.uri);
  };

  const onActionPermanentContainerLoad = async (permanentContainerUri, batchUri) => {

    try {
      /* eslint-disable camelcase */
      const payload = {
        source_batch: batchUri,
        action_type: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
        source_material_container: permanentContainerUri,
        quantity: batch.quantity,
      };

      setSubmitting(true);
      await api.post('material-container-action/', {
        json: payload,
      });
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    navigate(getRouteURI(routes.permanentContainerSuccess,
      { uuid: permanentContainerUUID },
      { action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL }));

    return new Promise(() => {
    });
  };

  const hideRelocationWarningModal = () => setShowRelocateModal(false);

  const renderLocationMismatchText = () => {
    if (!batch || !permanentContainer || !locations || !subLocations) return null;

    const batchSubLocation = subLocations.find((subLocation) => subLocation.uri === batch.sub_location);
    const permanentContainerSubLocation = subLocations.find((subLocation) => subLocation.uri === permanentContainer.sub_location);
    const permanentContainerLocation = locations.find((location) => location.uri === permanentContainer.location);
    const permanentContainerSubLocationName = permanentContainerSubLocation?.name ? `(${permanentContainerSubLocation.name}).` :
    <strong>[Archived].</strong>;
    const batchSubLocationName = batchSubLocation?.name ? `(${batchSubLocation.name}).` : <strong>[Archived].</strong>;

    return (
      <div>
        <p>You are trying to <strong>Load Material</strong> but there is a Location mismatch.</p>
        <p>The Permanent
          Container <strong>{permanentContainer.name}</strong> is
          in <strong>{permanentContainerLocation?.name}</strong> / <strong>{permanentContainerSubLocationName}</strong> and
          the Batch <strong>({getShortUuid(batch.uri)})</strong> is
          in <strong>{batch.location_name}</strong> / <strong>{batchSubLocationName}</strong>
        </p>
        <p>Before Loading Material, you can relocate the entire Batch using the batch page or relocate the Permanent
          Container now to match the Batch location.
        </p>
      </div>
    );
  };

  const onActionRelocate = async (permanentContainerUri, locationUri, subLocationUri) => {
    try {
      /* eslint-disable camelcase */
      const payload = {
        metadata: {
          destination_location: locationUri,
          destination_sub_location: subLocationUri,
        },
        action_type: PERMANENT_CONTAINER_ACTIONS.RELOCATE,
        source_material_container: permanentContainerUri,
      };

      setSubmitting(true);
      await api.post('material-container-action/', {
        json: payload,
      }).json();
    } catch (error_) {
      console.error(error_);
      setSubmitError(true);
      setSubmitting(false);
    }

  };

  const onModalConfirm = async () => {
    await onActionRelocate(permanentContainer.uri, batch.location, batch.sub_location);
    return onActionPermanentContainerLoad(permanentContainer.uri, batch.uri);
  };

  return (
    <PermanentContainerActionPageWrapper
      id={getUuid(batchUri)}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL}
      isLoading={isLoading}
    >
      <div className="d-flex align-items-center justify-content-center alert alert-info" role="alert">
        <p className="mb0">
          <b>{batch?.quantity} {batch?.units}</b> of <b>{batch?.materials[0]?.name} (Batch {getShortUuid(batch?.uri)}) </b>
          will be loaded to Permanent Container - <b>{`"${permanentContainer?.name}"`}</b>
        </p>
        
        {unsupportedMaterials.length ? (
          <OverlayTrigger
            placement="right"
            overlay={(
              <Tooltip id="workstation-is-overloaded">
                Permanent Container &#39;{permanentContainer?.name}&#39; does not support&nbsp;
                {unsupportedMaterials.length > 1 ? 'materials' : 'material'}&nbsp;
                {unsupportedMaterials.map((material) => material).join(', ')}
              </Tooltip>)}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} className="d-block ml-2 warning-color" />
          </OverlayTrigger>
        ) : null}
      </div>

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isSubmitting || unsupportedMaterials.length}
        onClick={handleSubmitLoadMaterial}
      >
        <p className="d-flex align-items-center justify-content-center mb0">
          Confirm
          {isSubmitting && <Loader inline className="spacer-left" showText={false} />}
        </p>
      </button>

      <Modal
        isOpen={showRelocateModal}
        title="Locations Mismatch"
        dismissText="Cancel"
        confirmText="Relocate Permanent Container"
        onDismiss={hideRelocationWarningModal}
        onConfirm={onModalConfirm}
      >
        {renderLocationMismatchText()}
      </Modal>
    </PermanentContainerActionPageWrapper>
  );
};

LoadMaterial.propTypes = {
  user: userPropType,
};

LoadMaterial.defaultProps = {
  user: null,
};

export default LoadMaterial;
