import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import FeaturesContext from 'src/context/FeaturesContext';
import { api } from 'src/utils/api';
import { API_RESOURCES, MODEL_UNITS_VERBOSE } from 'src/utils/constants';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';
import { getLineItemWorkflowTypeObjectKey } from 'src/utils/lineItem';
import { getUuid } from 'src/utils/url';

import CollapsableCard from '../collapsable-card';

/**
 * Displays line item data for a piece
 * @param setThumbnail - recieves piece image from model data
 */
const LineItem = ({ lineItemID, setThumbnail }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [lineItem, setLineItem] = useState(null);
  const [material, setMaterial] = useState(null);
  const [model, setModel] = useState(null);
  const [workflow, setWorkflow] = useState(null);
  const { addToast } = useToasts();
  const { features } = useContext(FeaturesContext);
  const isPowderWorkflowFeatureEnabled = isFeatureEnabled(features, FEATURES.POWDER_WORKFLOW);

  /**
   * Fetch the line item and its associated data
   */
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const lineItem = await api.get(`${API_RESOURCES.LINE_ITEM}/${lineItemID}`).json();
        const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
        setLineItem(lineItem);
        setIsLoading(false);

        //retrieve the model to get piece's image
        const model = isPowderWorkflowFeatureEnabled ? null :
          await api.get(`${API_RESOURCES.MODEL}/${getUuid(lineItem[workflowTypeKey].model)}`).json();
        setModel(model);

        // eslint-disable-next-line camelcase
        if (model?.snapshot_content) {
          setThumbnail(model.snapshot_content);
        }

        if (lineItem[workflowTypeKey]?.materials && lineItem[workflowTypeKey].materials.base) {
          const material = await api.get(`${API_RESOURCES.MATERIAL}/${getUuid(lineItem[workflowTypeKey].materials.base)}`).json();
          setMaterial(material.name);
        }

        if (lineItem.workflow) {
          const workflow = await api.get(`${API_RESOURCES.WORKFLOW}/${getUuid(lineItem.workflow)}`).json();
          setWorkflow(workflow);
        }
      } catch (err) {
        addToast('There was an error. Please try again.', { appearance: 'error' });
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!lineItem) {
    return <NotFound id={lineItemID} />;
  }

  return (
    <CollapsableCard title={`Line Item Overview: ${lineItem.name}`}>
      <Table striped bordered className="mb-0 align-left">
        <tbody>
          <tr>
            <th>Base Material</th>
            <td>{material || 'N/A'}</td>
          </tr>
          <tr>
            <th>Quantity</th>
            <td>{lineItem.quantity || 'N/A'}</td>
          </tr>
          <tr>
            <th>Units</th>
            {/* eslint-disable-next-line camelcase */}
            <td>{model?.file_unit ? (MODEL_UNITS_VERBOSE[model.file_unit] || model.file_unit) : 'N/A'}</td>
          </tr>
          <tr>
            <th>Production Workflow</th>
            <td>{workflow && workflow.name || 'N/A'}</td>
          </tr>
        </tbody>
      </Table>
    </CollapsableCard>
  );
};

LineItem.propTypes = {
  lineItemID: PropTypes.string.isRequired,
  setThumbnail: PropTypes.func.isRequired,
};

export default LineItem;
