import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import { api } from 'src/utils/api';
import { API_RESOURCES } from 'src/utils/constants';

import CollapsableCard from '../collapsable-card';

const Order = ({ orderID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const order = await api.get(`${API_RESOURCES.ORDER}/${orderID}`).json();
        setOrder(order);
      } catch (err) {
        addToast('There was an error. Please try again.', { appearance: 'error' });
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!order) {
    return <NotFound id={orderID} />;
  }

  return (
    <CollapsableCard title={`Order Overview: ${order.name}`}>
      <Table striped bordered className="mb-0 align-left">
        <tbody>
          <tr>
            <th>Owner</th>
            <td>{order.order_owner_name || 'N/A'}</td>
          </tr>
          <tr>
            <th>PO</th>
            <td>{order.customer_po || 'N/A'}</td>
          </tr>
          <tr>
            <th>Customer</th>
            <td>{order.customer_name || 'N/A'}</td>
          </tr>
          <tr>
            <th>Created</th>
            <td>{order.created ? new Date(order.created).toLocaleDateString() : 'N/A'}</td>
          </tr>
          <tr>
            <th>Due Date</th>
            <td>{order.due_date ? new Date(order.due_date).toLocaleDateString() : 'N/A'}</td>
          </tr>
        </tbody>
      </Table>
    </CollapsableCard>
  );
};

Order.propTypes = {
  orderID: PropTypes.string.isRequired,
};

export default Order;
