import Sentry from './sentry';

export class ApiError extends Error {
  constructor({ code, title }, ...args) {
    super(title, ...args);
    this.name = code;
  }
}

export class ForbiddenError extends Error {
  /*
   * Used in props when some next.js route requirements do not match
   */
  constructor({ title }, ...args) {
    super(title, ...args);
    this.name = title;
  }
}

export class RunError extends Error {
  constructor(message, print, ...args) {
    super(message, ...args);
    this.name = this.constructor.name;

    // Sentry metadata
    this.level = 'info';
    this.extra = {
      print: print.uri,
    };
  }
}

export const reportError = (error, errorInfo = {}) => {
  if (error.name === 'HTTPError') {
    const { response } = error;

    // eslint-disable-next-line promise/prefer-await-to-then
    return response.text().then(responseBody => {
      let eventId;

      Sentry.withScope(scope => {
        const { url, status, statusText } = response;

        scope.setExtra('url', url);
        scope.setExtra('body', responseBody);
        scope.setExtra('status', status);
        scope.setExtra('statusText', statusText);

        for (const [key, value] of Object.entries(errorInfo)) {
          scope.setExtra(key, value);
        }

        eventId = Sentry.captureException(error);
      });

      return eventId;
    });
  }

  let eventId;

  Sentry.withScope(scope => {
    if (error.level) {
      scope.setLevel(error.level);
    }

    if (error.extra) {
      for (const [key, value] of Object.entries(error.extra)) {
        scope.setExtra(key, value);
      }
    }

    for (const [key, value] of Object.entries(errorInfo)) {
      scope.setExtra(key, value);
    }

    eventId = Sentry.captureException(error);
  });

  return Promise.resolve(eventId);
};
