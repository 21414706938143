import { WORKFLOW_TYPES } from './constants';

export const getLineItemWorkflowTypeObjectKey = lineItem => {
  switch (lineItem?.workflow_type) {
    case WORKFLOW_TYPES.POWDER_MANUFACTURING:
      return 'powder';
    default:
      return 'additive';
  }
};

export const getLineItemEstimatesTypeObjectKey = lineItem => {
  switch (lineItem?.workflow_type) {
    case WORKFLOW_TYPES.POWDER_MANUFACTURING:
      return 'estimates_powder';
    default:
      return 'estimates_additive';
  }
};
