import PropTypes from 'prop-types';
import React from 'react';

import WorkInstructions from './_work-instructions';


const PrintsGroupWithWorkInstructions = ({
                                           printsGroup,
                                           modelsByUri,
                                           lineItemsByUri,
                                           checklistsByPrintUri,
                                           reportsByPrintUri,
                                           uploadingStateByPrintUri,
                                           handleStepInput,
                                           handleStepChange,
                                           sendNCReviewForPiece,
                                           runTransformation,
                                           prints,
                                           sentToNCRItems,
                                           setSentToNCRItems,
                                           savedReportsByPrintUri,
                                           hasNCRNotSentOutsideOfThreshold,
                                           setIsAbleToCompleteRun,
                                           isFileUploading,
                                           isPowderWorkflow,
                                         }) => {
  // Using first print as the base one to render group
  const firstPrint = printsGroup.prints[0];

  const lineItem = lineItemsByUri[firstPrint.line_item];
  const model = (lineItem && !isPowderWorkflow) ? modelsByUri[lineItem.additive.model] : null;
  const checklist = checklistsByPrintUri[firstPrint.uri];
  const reportValues = reportsByPrintUri[firstPrint.uri];
  const savedReportValues = savedReportsByPrintUri[firstPrint.uri];
  const uploadingState = uploadingStateByPrintUri[firstPrint.uri];
  const hasIncorrectValues = hasNCRNotSentOutsideOfThreshold(checklist?.work_instructions);

  const onStepInput = (workInstructionUUID, value) => {
    handleStepInput(
      printsGroup.prints.map((print) => print.uri),
      workInstructionUUID,
      value,
    );
  };
  const onStepChange = (previousStepIndex) => {
    // TODO: fix steps synchronization between collapsed/expanded states
    handleStepChange(printsGroup.prints.map((print) => print.uri), previousStepIndex);
  };

  return (
    <div key={printsGroup.groupKey} className="mb-4">
      <div className="row work-instructions-body">
        <div className="col-5 work-instructions-body-model">
          <div className="thumbnail-box rounded-sm">
            <div className="thumbnail-box-inside">
              {
                // TODO: Show prints count badge
              }
              {model && (
                <img src={model.snapshot_content} alt="" className="img-fluid" />
              )}
            </div>
          </div>
        </div>
        <div className="col-7 work-instructions-body-content work-instructions-body-content-text">
          {checklist && (
            <WorkInstructions
              instructions={checklist.work_instructions}
              values={reportValues}
              uploading={uploadingState}
              beforeChange={onStepChange}
              singleGroup={printsGroup}
              sendNCReviewForPiece={sendNCReviewForPiece}
              runTransformation={runTransformation}
              allPrints={prints}
              sentToNCRItems={sentToNCRItems}
              setSentToNCRItems={setSentToNCRItems}
              savedReportsByPrintUri={savedReportsByPrintUri}
              hasIncorrectValues={hasIncorrectValues}
              savedValues={savedReportValues}
              setIsAbleToCompleteRun={setIsAbleToCompleteRun}
              isFileUploading={isFileUploading}
              onStepInput={onStepInput}
            />
          )}
          {!checklist && (
            <div>No Work Instructions for Pieces</div>
          )}
        </div>
      </div>
    </div>
  );
};

PrintsGroupWithWorkInstructions.propTypes = {
  printsGroup: PropTypes.shape({
    groupKey: PropTypes.string.isRequired,
    prints: PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        // eslint-disable-next-line camelcase
        line_item: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  modelsByUri: PropTypes.shape({}).isRequired,
  lineItemsByUri: PropTypes.shape({}).isRequired,
  checklistsByPrintUri: PropTypes.shape({}).isRequired,
  reportsByPrintUri: PropTypes.shape({}).isRequired,
  uploadingStateByPrintUri: PropTypes.shape({}).isRequired,
  handleStepInput: PropTypes.func.isRequired,
  handleStepChange: PropTypes.func.isRequired,
  sendNCReviewForPiece: PropTypes.func.isRequired,
  runTransformation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  prints: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sentToNCRItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSentToNCRItems: PropTypes.func.isRequired,
  savedReportsByPrintUri: PropTypes.shape({}).isRequired,
  hasNCRNotSentOutsideOfThreshold: PropTypes.func.isRequired,
  setIsAbleToCompleteRun: PropTypes.func.isRequired,
  isFileUploading: PropTypes.bool.isRequired,
  isPowderWorkflow: PropTypes.bool.isRequired,
};

PrintsGroupWithWorkInstructions.defaultProps = {};

export default PrintsGroupWithWorkInstructions;
