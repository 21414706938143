import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';


const CollapsableCard = ({ title, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      <div className={`collapsable-title ${isCollapsed ? 'collapsed' : 'expanded'}`} onClick={toggleCollapsed}>
        <div className="title-container">
          <strong>{title}</strong>
        </div>
        <FontAwesomeIcon
          icon={isCollapsed ? faChevronRight : faChevronDown} size="lg" pull="right"
          className="right mr-1" />
      </div>
      <div className={`collapsable-body ${isCollapsed ? 'collapsed' : 'expanded'}`}>
        {children}
      </div>
    </>
  );
};

CollapsableCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CollapsableCard;
