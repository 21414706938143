import ky from 'ky-universal';

import config from './config';
import Sentry from './sentry';

export function getAllByFilterWithApi(api) {
  return async (items, endpoint, options = {}, filter = 'uri') => {
    const chunkedItems = chunkForQueryFilter(items);
    const filterKey = `filter[${filter}]`;

    const requests = chunkedItems.map(chunk =>
      api
        .get(endpoint, {
          ...options,
          searchParams: {
            ...options.searchParams,
            [filterKey]: chunk.filter(Boolean).join(','),
          },
        })
        .json()
    );

    const responses = await Promise.all(requests);
    const data = responses.map(({ resources }) => resources);

    return [].concat(...data);
  };
}

export const chunkForQueryFilter = (items = [], itemsPerChunk = 15) => {
  if (items.length === 0) {
    return [];
  }

  const result = [];

  while (items.length > 0) {
    result.push(items.splice(0, itemsPerChunk));
  }

  return result;
};

export const getCookieFromContext = ({ req } = {}) => {
  if (req && req.headers && req.headers.cookie) {
    return req.headers.cookie;
  }

  return false;
};

export const api = ky.extend({
  credentials: 'include',
  prefixUrl: config.apiHost,
  timeout: 120 * 1000,
  retry: {
    limit: 5, // Maximum number of retries to perform. Default: 2.
    methods: ['get', 'post'], // HTTP methods to automatically retry.
    statusCodes: [408, 413, 429, 500, 502, 503, 504], // HTTP status codes to automatically retry.
    afterStatusCodes: [413, 429, 503], // HTTP status codes that when present in the response, it will wait for the Retry-After header and then retry.
    maxRetryAfter: undefined, // The maximum amount of time in milliseconds to wait before retrying the request. If Retry-After header is greater than maxRetryAfter, it will cancel the request
  },
  hooks: {
    beforeRequest: [
      request => {
        /*
         * We should avoid requests without slash at the end of pathname.
         * nginx returns 308 status code and application makes additional request for
         * api service with slash
         */
        let requestURL = new URL(request.url);
        if (!requestURL.pathname.endsWith('/')) {
          Sentry.captureEvent({
            message: 'The request is executed without the trailing slash in pathname',
            level: 'warning',
            extra: { uri: request.url },
          });
        }
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (!response.ok && options.onErrorToastCallback) {
          const message = await response.json();
          if (message.errors) {
            options.onErrorToastCallback(message.errors[0].title, { appearance: 'error' });
          }
        }
      },
    ],
  },
});

export const getAllByFilter = getAllByFilterWithApi(api);

export const apiWithContext = ctx => {
  const cookie = getCookieFromContext(ctx);

  const contextApi = api.extend({
    credentials: 'include',
    prefixUrl: config.apiHost,
    hooks: {
      beforeRequest: [
        request => {
          if (cookie) {
            request.headers.append('Cookie', cookie);
          }
        },
      ],
      afterResponse: [
        (request, options, response) => {
          if (ctx && response.headers.has('Set-Cookie')) {
            ctx.res.setHeader('Set-Cookie', response.headers.get('Set-Cookie'));
          }
        },
      ],
    },
  });

  contextApi.getAllByFilter = getAllByFilterWithApi(contextApi);

  return contextApi;
};

// Neede because fetch doesn’t support upload progress.
// WILL NOT WORK SERVER-SIDE
export const upload = (url, options) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open('PUT', url);
    xhr.setRequestHeader('content-type', options.contentType);

    xhr.addEventListener('abort', reject);
    xhr.addEventListener('error', reject);

    xhr.upload.addEventListener('progress', ({ loaded, total }) => {
      options.onUploadProgress(loaded / total);
    });

    xhr.addEventListener('load', () => {
      if (xhr.status >= 200 && xhr.status < 400) {
        resolve(xhr.response);
        return;
      }

      let errors = [];
      try {
        errors = JSON.parse(xhr.response).errors;
      } catch (_) {
        /* empty */
      }

      if (errors.length) {
        errors.map(error => {
          options.onErrorToastCallback(error.title, { appearance: 'error' });
        });
      }
      reject(xhr.status);
    });

    xhr.send(options.body);
  });
};
