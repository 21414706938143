import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Alert from 'src/components/alert';
import Loader from 'src/components/loader';
import Modal from 'src/components/modal';
import { ToolingStockCardFull } from 'src/components/ToolingStockCard';
import { api } from 'src/utils/api';
import {
  API_RESOURCES, RUN_ACTIONS, TOOLING_STOCK_ACTION_TYPES, TOOLING_STOCK_STATUSES,
} from 'src/utils/constants';
import routes from 'src/utils/routes';
import useQueryParams, { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import RunActionPageWrapper from './_action-wrapper';


const ScanToolingStock = ({ user }) => {
  const { uuid: runUUID } = useParams();
  const {
    toolingStockUUID,
  } = useQueryParams();
  const [pageState, setPageState] = useState({
    run: null,
    toolingStock: null,
    toolingType: null,
    runLocation: null,
    toolingStockLocation: null,
    toolingStockSubLocation: null,
  });
  const [showRelocateModal, setShowRelocateModal] = useState(false);
  const [confirmingToolingStock, setConfirmingToolingStock] = useState(false);
  const [toolingStockInappropriateStatus, setToolingStockInappropriateStatus] = useState(false);
  const [toolingStockHasNewStatus, setToolingStockHasNewStatus] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  const navigate = useNavigate();

  const getInitialData = async () => {
    if (!toolingStockUUID || !runUUID) return null;
    try {
      const currentRun = await api.get(`${API_RESOURCES.RUN}/${runUUID}/`).json();

      if (!currentRun) return null;

      const [
        runLocation,
      ] = await Promise.all([
        api.get(`${API_RESOURCES.LOCATION}/${getUuid(currentRun.location)}/`).json(),
      ]);

      const toolingStock = toolingStockUUID
        ? await api.get(`${API_RESOURCES.TOOLING_STOCK}/${toolingStockUUID}/`).json()
        : null;

      if (!toolingStock) {
        return null;
      }

      const promises = [
        api.get(`${API_RESOURCES.TOOLING_TYPE}/${getUuid(toolingStock.type)}/`).json(),
      ];
      if (toolingStock.location) {
        promises.push(api.get(`${API_RESOURCES.LOCATION}/${getUuid(toolingStock.location)}/`).json());
      }
      if (toolingStock.sub_location) {
        promises.push(
          api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(toolingStock.sub_location)}/`).json()
        );
      }
      // Fetch tooling stock location, sub-location, and tooling type in parallel
      const [
        toolingType,
        toolingStockLocation,
        toolingStockSubLocation,
      ] = await Promise.all(promises);


      setPageState({
        run: currentRun,
        toolingStock,
        toolingType,
        toolingStockLocation,
        toolingStockSubLocation,
        runLocation,
      });

      setToolingStockInappropriateStatus(
        toolingStock.status !== TOOLING_STOCK_STATUSES.IDLE &&
        toolingStock.status !== TOOLING_STOCK_STATUSES.NEW
      );

      setToolingStockHasNewStatus(
        toolingStock.status === TOOLING_STOCK_STATUSES.NEW
      );

      setIsLoading(false);
    } catch (error) {
      if (error.name === 'HTTPError' && error.response.status === 404) {
        setError('Failed to find batch.');
      } else {
        setError(error);
      }
    }
  };

  const checkLocationMismatch = () => {
    if (
      !pageState.toolingStockLocation ||
      !pageState.toolingStockLocation.uri
    ) return null;
    const runLocation = pageState.run.location;
    return runLocation !== pageState.toolingStockLocation.uri;
  };

  const handleAssignToolingStock = async (shouldRelocate) => {
    try {
      setConfirmingToolingStock(true);

      /* eslint-disable camelcase */
      const payload = {
        metadata: {
          run: pageState.run.uri,
          relocate: shouldRelocate,
        },
        tooling_stock: pageState.toolingStock.uri,
        action_type: TOOLING_STOCK_ACTION_TYPES.PUT_INTO_USE,
      };

      // If tooling stock is new, we want to move to Ready For Use first
      // BE doesn't allow moving directly from New -> In Use
      if (toolingStockHasNewStatus) {
        const newPayload = {
          tooling_stock: pageState.toolingStock.uri,
          action_type: TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE,
          metadata: {
            destination_location: pageState.run.location,
            destination_sub_location: null,
          },
        };

        await api.post(`${API_RESOURCES.TOOLING_STOCK_ACTION}/`, {
          json: newPayload,
        }).json();
      }

      await api.post(`${API_RESOURCES.TOOLING_STOCK_ACTION}/`, {
        json: payload,
      }).json();

    } catch (error_) {
      console.error(error_);
      setError(error_.message);
      setSubmitError(true);
      setConfirmingToolingStock(false);
      return;
    }
    setConfirmingToolingStock(false);
    navigate(getRouteURI(routes.run,
      { uuid: getUuid(pageState.run.uri) }));

    return new Promise(() => {
    });
  };

  const onConfirmToolingStock = async () => {
    if (pageState.run && pageState.toolingStock) {
      const shouldSuggestRelocate = checkLocationMismatch();

      if (shouldSuggestRelocate) {
        setShowRelocateModal(true);
        return;
      }
      return handleAssignToolingStock(false);
    }
  };

  const onRelocateModalConfirm = async () =>
    await handleAssignToolingStock(true);

  const onRelocateModalDismiss = async () =>
    await handleAssignToolingStock(false);

  const handleRefetchStockData = async () => {
    setIsRefetching(true);
    await getInitialData();
    setIsRefetching(false);
  };

  const renderLocationMismatchText = () =>
    (
      <div>
        <p>You are trying to assign a Tool to the Run, but there is a Locations mismatch. The
          Run <strong>{pageState.run?.name} ({getShortUuid(pageState.run?.uri)})</strong> is
          in <strong>{pageState.runLocation?.name}</strong> and the Tooling
          Stock <strong>{pageState.toolingStock?.name}</strong> ({getShortUuid(pageState.toolingStock?.uri)}) is
          in <strong>{pageState.toolingStockLocation?.name}</strong>.
        </p>

        <p className="mb0">
          You can <strong>Relocate and Continue</strong> to change the Tool&apos;s location
          or <strong>Continue</strong> to
          proceed without
          relocation.
        </p>
      </div>
    );

  useEffect(() => void getInitialData(), []);

  return (
    <RunActionPageWrapper
      id={runUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={RUN_ACTIONS.SCAN_TOOLING_STOCK}
      isLoading={isLoading}
    >
      <h2 className="header-margin">
        Scanned Tool
      </h2>

      {isRefetching ?
        <Loader /> :
        <ToolingStockCardFull
          toolingStock={pageState.toolingStock}
          toolingType={pageState.toolingType}
          location={pageState.toolingStockLocation?.name}
          subLocation={pageState.toolingStockSubLocation?.name}
        />}

      <h4 className="header-margin">Would you like to proceed?</h4>

      {toolingStockInappropriateStatus && (
        <Alert name="qr-instructions" variant="warning" className="text-left d-flex align-items-center">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr15 warning-color-small" />
          <div>
            <p className="header-margin">
              The scanned tool must have a status of &apos;Ready to Use&apos; before it can be assigned to the
              run.
            </p>
            <p className="mb0 font-size-14">Already changed? Please click&nbsp;
              <button
                type="button"
                className="btn btn-link btn-sm alert-link"
                onClick={handleRefetchStockData}
              >
                here
              </button>
              &nbsp;to refresh.
            </p>
          </div>
        </Alert>
      )}
      {toolingStockHasNewStatus && (
        <Alert name="qr-instructions" variant="warning" className="text-left d-flex align-items-center">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr15 warning-color-small" />
          <p>This tool has a status of <strong>New</strong>, rather than <strong>Ready for Use</strong>.
            Are you sure you want to continue?
          </p>
        </Alert>
      )}

      <Link to={getRouteURI(routes.scan,
        {},
        {
          runUri: pageState.run?.uri,
          action: RUN_ACTIONS.SCAN_TOOLING_STOCK,
          entity: API_RESOURCES.TOOLING_STOCK,
        })}
      >
        <button type="button" disabled={confirmingToolingStock} className="btn btn-default btn-action">
          Rescan
        </button>
      </Link>

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={confirmingToolingStock || toolingStockInappropriateStatus}
        onClick={onConfirmToolingStock}
      >
        <div className="d-flex align-items-center justify-content-center">
          Confirm
          {confirmingToolingStock && <Loader inline className="spacer-left" showText={false} />}
        </div>
      </button>

      <Modal
        isOpen={showRelocateModal}
        title="Locations Mismatch"
        dismissText="Continue"
        confirmText="Relocate and Continue"
        onConfirm={onRelocateModalConfirm}
        onDismiss={onRelocateModalDismiss}
      >
        {renderLocationMismatchText()}
      </Modal>
    </RunActionPageWrapper>
  );
};

ScanToolingStock.propTypes = {
  user: userPropType,
};

export default ScanToolingStock;
