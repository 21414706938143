import { faFastBackward, faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import MaterialBatchSuccessCardDetails from 'src/components/material-batch-success-card-details';
import NotFound from 'src/components/not-found';
import ScanButton from 'src/components/scan-button';
import { api } from 'src/utils/api';
import { API_RESOURCES, MATERIAL_BATCH_ACTIONS, MATERIAL_BATCH_ACTIONS_VERBOSE } from 'src/utils/constants';
import routes from 'src/utils/routes';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

export const ActionLink = ({ text, link }) => text ? (
  <Link className="success-link-style" to={link}>
    <span className="success-link-text">
      &nbsp;{text}&nbsp;<FontAwesomeIcon icon={faLink} className="mr-auto mb0" />&nbsp;
    </span>
  </Link>
) : null;

ActionLink.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};


const BatchSuccessPage = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { uuid: batchUUID } = useParams();
  const {
    action,
    sampleName,
    subLocation,
    printerUri,
    permanentContainerUri,
    initialBatch,
    initialBatchQuantity,
    blendedBatch,
    blendedBatchQuantity,
  } = Object.fromEntries(searchParams.entries()) ?? {};
  const [isLoading, setIsLoading] = useState(true);
  const [pageState, setPageState] = useState({
    id: batchUUID,
    batch: null,
    initialBatch: null,
    blendedBatch: null,
    batchContainers: null,
    batchLoadedToPrinter: null,
    subLocation: null,
    permanentContainer: null,
    permanentContainerLocation: null,
    permanentContainerSubLocation: null,
    blendedBatchSubLocation: null,
    initialSubLocation: null,
    printer: null,
    error: null,
  });

  const actionCategories = {
    unloadActions: [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER, MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER, MATERIAL_BATCH_ACTIONS.UNLOAD_RECLAIMED_MATERIAL],
    printerActions: [MATERIAL_BATCH_ACTIONS.MACHINE_LOAD, MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF],
    particularBatchActions: [MATERIAL_BATCH_ACTIONS.SCRAP_BATCH, MATERIAL_BATCH_ACTIONS.SPLIT_BATCH, MATERIAL_BATCH_ACTIONS.BLEND_BATCHES, MATERIAL_BATCH_ACTIONS.SIEVE, MATERIAL_BATCH_ACTIONS.TEST, MATERIAL_BATCH_ACTIONS.CREATE_INITIAL_BATCH],
  };

  const isUnloadAction = actionCategories.unloadActions.includes(action);
  const isPrinterAction = actionCategories.printerActions.includes(action) || isUnloadAction;
  const shouldShowSplitContainers = action === MATERIAL_BATCH_ACTIONS.SPLIT_BATCH;
  const shouldShowViewBatchButton = action !== MATERIAL_BATCH_ACTIONS.SCRAP_BATCH && action !== MATERIAL_BATCH_ACTIONS.SPLIT_BATCH
    && actionCategories.particularBatchActions.includes(action);

  const getActionTitle = () => {
    if (isUnloadAction) return 'Machine';
    return action === MATERIAL_BATCH_ACTIONS.RELOCATE ? 'Relocate' : 'Batch';
  };
  const actionTitle = getActionTitle();

  const getBatchUrl = () => {
    const uuid = (action === MATERIAL_BATCH_ACTIONS.SPLIT_BATCH
        ? getUuid(pageState.batch?.source_material_batches[0])
        : pageState.batch?.uuid
    );
    return getRouteURI(routes.materialContainer, {}, { batch: uuid });
  };

  const getPermanentContainerLink = (permanentContainer = pageState.permanentContainer) => permanentContainer
    ? getRouteURI(routes.permanentContainerDetails, { uuid: getUuid(permanentContainer.uri) })
    : null;

  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      const batchByUUID = await api.get(`material-batch/${batchUUID}/`).json();
      const batchSubLocation = !subLocation ? await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batchByUUID.sub_location)}/`).json() : null;
      const initialBatchAfterSplitUri = batchByUUID?.source_material_batches?.[0];

      const INITIAL_BATCH_URI_BY_ACTION = {
        [MATERIAL_BATCH_ACTIONS.SPLIT_BATCH]: initialBatchAfterSplitUri,
        [MATERIAL_BATCH_ACTIONS.BLEND_BATCHES]: initialBatch,
      };

      const initialBatchUri = INITIAL_BATCH_URI_BY_ACTION[action] || null;
      const initialFetchedBatch = initialBatchUri ? await api.get(`material-batch/${getUuid(initialBatchUri)}/`).json() : null;

      const INITIAL_SUB_LOCATION_URI_BY_ACTION = {
        [MATERIAL_BATCH_ACTIONS.SPLIT_BATCH]: initialFetchedBatch?.sub_location,
        [MATERIAL_BATCH_ACTIONS.BLEND_BATCHES]: initialFetchedBatch?.sub_location,
      };
      const initialBatchSubLocationUri = INITIAL_SUB_LOCATION_URI_BY_ACTION[action] || null;
      const initialBatchSubLocation = initialBatchSubLocationUri ? await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(initialBatchSubLocationUri)}/`).json() : null;

      const fetchedBlendedBatch = action === MATERIAL_BATCH_ACTIONS.BLEND_BATCHES && blendedBatch
        ? await api.get(`material-batch/${getUuid(blendedBatch)}/`).json()
        : null;

      const blendedBatchSubLocation = fetchedBlendedBatch ? await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(fetchedBlendedBatch.sub_location)}/`).json() : null;

      const getPrinterUri = printerUri || batchByUUID?.at_machine;
      const printer = isPrinterAction && getPrinterUri ?
        await api.get(`printer/${getUuid(getPrinterUri)}/`).json() : null;

      const printerBatches = printer && isPrinterAction ? await api.get(`${API_RESOURCES.MATERIAL_BATCH}/`, {
        searchParams: {
          'filter[at_machine]': printer.uri,
        },
      }).json() : null;

      const batchContainers = batchByUUID?.containers?.length ?
        await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
          searchParams: {
            'filter[uri]': batchByUUID.containers.join(','),
          },
        }).json() : null;

      const permanentContainer = permanentContainerUri
        ? await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${getUuid(permanentContainerUri)}/`).json()
        : null;

      const [permanentContainerLocation, permanentContainerSubLocation] = permanentContainer ? await Promise.all([
        api.get(`${API_RESOURCES.LOCATION}/${getUuid(permanentContainer.location)}/`).then(response => response.json()).catch(() => null),
        api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(permanentContainer.sub_location)}/`).then(response => response.json()).catch(() => null),
      ]) : [null, null];

      setPageState({
        id: batchUUID,
        batch: batchByUUID,
        initialBatch: initialFetchedBatch,
        blendedBatch: fetchedBlendedBatch,
        batchContainers: batchContainers?.resources,
        printer,
        permanentContainer,
        permanentContainerLocation: permanentContainerLocation?.name,
        permanentContainerSubLocation: permanentContainerSubLocation?.name,
        batchLoadedToPrinter: printerBatches?.resources?.[0] || null,
        action,
        sampleName,
        initialSubLocation: initialBatchSubLocation?.name,
        blendedBatchSubLocation: blendedBatchSubLocation?.name,
        subLocation: subLocation || batchSubLocation?.name,
        error: null,
      });
    } catch (error) {
      if (error.name === 'HTTPError') {
        setPageState((prevState) => ({
          ...prevState,
          id: batchUUID,
          error: {
            name: error.name,
            status: error.response && error.response.status,
          },
        }));
      }

    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, [batchUUID]);

  if (isLoading) {
    return <Loader />;
  }

  const ACTION_SUCCESS_MESSAGES = {
    [MATERIAL_BATCH_ACTIONS.MACHINE_LOAD]: () => (
      <p className="success-action-text">
        Batch
        <ActionLink
          text={getShortUuid(pageState.batch.uri)}
          link={getBatchUrl()}
        />
        was successfully loaded into Machine
        <ActionLink
          text={pageState.printer?.name}
          link={getRouteURI(routes.printerMaterial, { uuid: getUuid(pageState.printer.uri) })}
        />
      </p>
    ),
    [MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF]: () => (
      <p className="success-action-text">
        Batch
        <ActionLink
          text={getShortUuid(pageState.batch?.uri)}
          link={getBatchUrl()}
        />
        was successfully
        topped off into Machine
        <ActionLink
          text={pageState.printer?.name}
          link={getRouteURI(routes.printerMaterial, { uuid: getUuid(pageState.printer?.uri) })}
        />
      </p>
    ),
    [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER]: () => (
      <p className="success-action-text">
        <strong>{pageState.batch.quantity} {pageState.batch.units}&nbsp;</strong>
        of <strong>{pageState.batch.materials[0].name}&nbsp;</strong>
        unloaded from the hopper of Machine
        <ActionLink
          text={pageState.printer?.name}
          link={getRouteURI(routes.printerMaterial, { uuid: getUuid(pageState.printer?.uri) })}
        />
        to form Batch
        <ActionLink
          text={getShortUuid(pageState.batch?.uri)}
          link={getBatchUrl()}
        />
      </p>
    ),
    [MATERIAL_BATCH_ACTIONS.UNLOAD_RECLAIMED_MATERIAL]: () => (
      <p className="success-action-text">
        <strong>{pageState.batch.quantity} {pageState.batch.units}&nbsp;</strong>
        of <strong>{pageState.batch.materials[0].name}</strong> has been reclaimed from the build chamber of Machine
        <ActionLink
          text={pageState.printer?.name}
          link={getRouteURI(routes.printerMaterial, { uuid: getUuid(pageState.printer?.uri) })}
        />
        to form Batch
        <ActionLink
          text={getShortUuid(pageState.batch.uri)}
          link={getBatchUrl()}
        />
      </p>
    ),
    [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER]: () => (
      <p className="success-action-text">
        <strong>{pageState.batch.quantity} {pageState.batch.units}&nbsp;</strong>
        of <strong>{pageState.batch.materials[0].name}</strong> unloaded from the build chamber of Machine
        <ActionLink
          text={pageState.printer?.name}
          link={getRouteURI(routes.printerMaterial, { uuid: getUuid(pageState.printer?.uri) })}
        />
        to form Batch
        <ActionLink
          text={getShortUuid(pageState.batch.uri)}
          link={getBatchUrl()}
        />
      </p>
    ),
    [MATERIAL_BATCH_ACTIONS.SCRAP_BATCH]: () => (
      <p className="success-action-text">
        Batch
        <ActionLink
          text={getShortUuid(pageState.batch?.uri)}
          link={getBatchUrl()}
        />
        was successfully marked as scrap
      </p>
    ),
    [MATERIAL_BATCH_ACTIONS.RELOCATE]: () => (
      <p className="success-action-text">
        Batch
        <ActionLink
          text={getShortUuid(pageState.batch.uri)}
          link={getBatchUrl()}
        />
        was successfully relocated to&nbsp;
        <span className="badge badge-secondary">{pageState.batch.location_name}</span> /&nbsp;
        <span className="badge badge-secondary">{subLocation}</span>
      </p>
    ),
    [MATERIAL_BATCH_ACTIONS.TEST]: () => (
      <p className="success-action-text">
        Batch
        <ActionLink
          text={getShortUuid(pageState.batch.uri)}
          link={getBatchUrl()}
        />
        was successfully marked as In Testing&nbsp;
        {
          sampleName && (
            <p className="mb-3 mt5">
              The sample <span className="badge badge-secondary">{sampleName}</span> was successfully created
            </p>
          )
        }
      </p>
    ),
    [MATERIAL_BATCH_ACTIONS.CREATE_INITIAL_BATCH]: () => (
      <p className="success-action-text">
        Initial Batch
        <ActionLink
          text={getShortUuid(pageState.batch.uri)}
          link={getBatchUrl()}
        />
        was successfully created
      </p>
    ),
    [MATERIAL_BATCH_ACTIONS.SPLIT_BATCH]: () => (
      <p className="success-action-text">
        You have successfully split the Batch
        <ActionLink
          text={getShortUuid(pageState.batch?.source_material_batches[0])}
          link={getRouteURI(routes.materialContainer, {}, { batch: getUuid(pageState.batch?.source_material_batches[0]) })}
        />
      </p>
    ),
    [MATERIAL_BATCH_ACTIONS.SIEVE]: () => (
      <p className="success-action-text">
        Batch
        <ActionLink
          text={getShortUuid(pageState.batch.uri)}
          link={getBatchUrl()}
        />
        was sieved successfully&nbsp;
        {pageState.permanentContainer && (
          <>
            into the same permanent container
            <ActionLink
              text={pageState.permanentContainer.name}
              link={getPermanentContainerLink()}
            />
          </>
        )}
        and&nbsp;
        <strong>{pageState.batch.quantity} {pageState.batch.units}&nbsp;</strong>
        remains
      </p>
    ),
    [MATERIAL_BATCH_ACTIONS.BLEND_BATCHES]: () => (
      <p className="success-action-text">
        Initial Batch
        <ActionLink
          text={getShortUuid(initialBatch)}
          link={getRouteURI(routes.materialContainer, {}, { batch: getUuid(initialBatch) })}
        />
        of <strong>{initialBatchQuantity} {pageState.batch.units}&nbsp;</strong>
        was blended with the Batch
        <ActionLink
          text={getShortUuid(blendedBatch)}
          link={getRouteURI(routes.materialContainer, {}, { batch: getUuid(blendedBatch) })}
        /> of <strong>{blendedBatchQuantity} {pageState.batch.units}.&nbsp;</strong>

        <br />
        <br />

        New Batch
        <ActionLink
          text={getShortUuid(pageState.batch.uri)}
          link={getBatchUrl()}
        />
        of <strong>{pageState.batch.quantity} {pageState.batch.units}</strong> was created.
      </p>
    ),
  };

  const createMaterialBatchConfig = (title, batchProp, subLocationProp) => ({
    title,
    batch: pageState[batchProp],
    subLocation: pageState[subLocationProp],
  });

  const createMaterialBatchSuccessCardDetails = (config) => (
    <MaterialBatchSuccessCardDetails key={config.title || config.batch?.uri} {...config} />
  );

  const BATCH_ACTION_SUCCESS_CONFIG = {
    [MATERIAL_BATCH_ACTIONS.BLEND_BATCHES]: [
      createMaterialBatchConfig('New Batch', 'batch', 'subLocation'),
      createMaterialBatchConfig('Blended Batch 1', 'initialBatch', 'initialSubLocation'),
      createMaterialBatchConfig('Blended Batch 2', 'blendedBatch', 'blendedBatchSubLocation'),
    ],
    [MATERIAL_BATCH_ACTIONS.SIEVE]: [
      createMaterialBatchConfig('Sieved Batch', 'batch', 'subLocation'),
      permanentContainerUri ? {
        permanentContainer: pageState.permanentContainer,
        batch: pageState.batch,
        customLocation: pageState.permanentContainerLocation,
        subLocation: pageState.permanentContainerSubLocation,
      } : {},
    ],
    [MATERIAL_BATCH_ACTIONS.SPLIT_BATCH]: [
      createMaterialBatchConfig('Original Batch', 'initialBatch', 'initialSubLocation'),
      createMaterialBatchConfig('New Split Batch', 'batch', 'subLocation'),
    ],
    UNLOAD_MACHINE: [
      createMaterialBatchConfig('New Batch', 'batch', 'subLocation'),
      {
        printer: pageState.printer,
        batch: pageState.batchLoadedToPrinter,
        subLocation: pageState.subLocation,
      },
    ],
    LOAD_MACHINE: {
      printer: pageState.printer,
      batch: pageState.batchLoadedToPrinter,
      subLocation: pageState.subLocation,
    },
    DEFAULT: createMaterialBatchConfig('Batch', 'batch', 'subLocation'),
  };

  const renderCards = (config) => config.map(createMaterialBatchSuccessCardDetails);


  const ACTION_SUCCESS_DATA = {
    [MATERIAL_BATCH_ACTIONS.BLEND_BATCHES]: () => renderCards(BATCH_ACTION_SUCCESS_CONFIG[MATERIAL_BATCH_ACTIONS.BLEND_BATCHES]),
    [MATERIAL_BATCH_ACTIONS.SIEVE]: () => renderCards(BATCH_ACTION_SUCCESS_CONFIG[MATERIAL_BATCH_ACTIONS.SIEVE]),
    [MATERIAL_BATCH_ACTIONS.SPLIT_BATCH]: () => renderCards(BATCH_ACTION_SUCCESS_CONFIG[MATERIAL_BATCH_ACTIONS.SPLIT_BATCH]),
    [MATERIAL_BATCH_ACTIONS.RELOCATE]: () => createMaterialBatchSuccessCardDetails(BATCH_ACTION_SUCCESS_CONFIG.DEFAULT),
    [MATERIAL_BATCH_ACTIONS.TEST]: () => createMaterialBatchSuccessCardDetails(BATCH_ACTION_SUCCESS_CONFIG.DEFAULT),
    [MATERIAL_BATCH_ACTIONS.SCRAP_BATCH]: () => createMaterialBatchSuccessCardDetails(BATCH_ACTION_SUCCESS_CONFIG.DEFAULT),
    [MATERIAL_BATCH_ACTIONS.MACHINE_LOAD]: () => createMaterialBatchSuccessCardDetails(BATCH_ACTION_SUCCESS_CONFIG.LOAD_MACHINE),
    [MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF]: () => createMaterialBatchSuccessCardDetails(BATCH_ACTION_SUCCESS_CONFIG.LOAD_MACHINE),
    [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER]: () => renderCards(BATCH_ACTION_SUCCESS_CONFIG.UNLOAD_MACHINE),
    [MATERIAL_BATCH_ACTIONS.UNLOAD_RECLAIMED_MATERIAL]: () => renderCards(BATCH_ACTION_SUCCESS_CONFIG.UNLOAD_MACHINE),
    [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER]: () => renderCards(BATCH_ACTION_SUCCESS_CONFIG.UNLOAD_MACHINE),
  };


  if (pageState.error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={pageState.id} />
        </main>
      </>
    );
  }

  const containerPluralized = pageState.batch?.containers.length === 1 ? 'Container' : 'Containers';
  const isMaterialSummaryVisible = action === MATERIAL_BATCH_ACTIONS.MACHINE_LOAD &&
    !!pageState.batch?.containers?.length;


  const renderContainerTotal = (containers = pageState.batch?.containers) => {
    if ((!isMaterialSummaryVisible && !shouldShowSplitContainers) || !containers?.length) return null;
    return (
      <>
        <h4 className="header-margin success-containers-total">
          <span className="badge-circle">{containers.length}</span>
          &nbsp;{containerPluralized} Total:
        </h4>
        <ol className="batch-success-container-list">
          {containers.map((uri) => {
            const container = pageState.batchContainers.find((c) => c.uri === uri);
            return (
              <li key={uri}>
                {getShortUuid(uri)} <span>({container.quantity} {pageState.batch.units})</span>
              </li>
            );
          })}
        </ol>
      </>
    );
  };

  const renderBottomButtons = () => (
    <div className="success-buttons-bottom">
      {
        shouldShowViewBatchButton && (
          <Link to={getBatchUrl()}>
            <div className="btn btn-outline-primary btn-action mt-4">
              <FontAwesomeIcon icon={faFastBackward} size="3x" className="d-block m-auto" />
              View Batch
            </div>
          </Link>
        )
      }
      <ScanButton />
    </div>
  );

  return (
    <>
      <Header title={`${MATERIAL_BATCH_ACTIONS_VERBOSE[action]} Success`} user={user} />
      <main role="main" className="text-center">
        <div className="success-main">
          <div className="success-animation">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
          <p className="success-text">{actionTitle} Action Complete</p>

          <div className="success-main-content">
            {ACTION_SUCCESS_MESSAGES?.[action] && ACTION_SUCCESS_MESSAGES[action]()}

            <hr className="success-hr-line" />

            {ACTION_SUCCESS_DATA?.[action] && ACTION_SUCCESS_DATA[action]()}
            {renderContainerTotal()}
            {renderBottomButtons()}
          </div>
        </div>
      </main>
    </>
  );

};

BatchSuccessPage.propTypes = {
  user: userPropType,
};

BatchSuccessPage.defaultProps = {
  user: null,
};

export default BatchSuccessPage;
