import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FeaturesContext from 'src/context/FeaturesContext';

const FeaturesProvider = ({ children }) => {
  const [features, setFeatures] = useState([]);
  return (
    <FeaturesContext.Provider
      value={{
        features,
        setFeatures,
      }}
    >
      {children}
    </FeaturesContext.Provider>
  );
};

FeaturesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FeaturesProvider;
