import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FeaturesContext from 'src/context/FeaturesContext';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS,
  MATERIAL_BATCH_CUSTOM_STATUSES,
} from 'src/utils/constants';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../../utils/routes';
import ActionPage from './_action-wrapper';

const StartTest = ({ user }) => {
  const { uuid: batchUUID } = useParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [isMaterialTestPanelFeatureEnabled, setIsMaterialTestPanelFeatureEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [batch, setBatch] = useState(null);
  const [samplesCount, setSamplesCount] = useState(null);
  const navigate = useNavigate();
  const { features } = useContext(FeaturesContext);

  const getInitialData = async () => {
    try {
      const isMaterialTestPanelFeatureEnabled = isFeatureEnabled(features, FEATURES.MATERIAL_TEST_PANEL);
      const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`).json();
      const samples = isMaterialTestPanelFeatureEnabled && await api.get(`${API_RESOURCES.SAMPLE}/`, {
        searchParams: {
          'filter[material_batch]': batch.uri,
        },
      }).json();
      const samplesCount = samples.meta?.count || 0;

      setIsMaterialTestPanelFeatureEnabled(isMaterialTestPanelFeatureEnabled);
      setBatch(batch);
      setSamplesCount(samplesCount);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => void getInitialData(), [batchUUID]);


  const onActionStartTest = async (batchUri) => {
    const sampleName = isMaterialTestPanelFeatureEnabled
      ? `Batch ${getShortUuid(batchUUID)} - Sample ${samplesCount + 1}`
      : null;

    try {
      setSubmitting(true);
      await api.put(batchUri, {
        prefixUrl: false,
        json: {
          // eslint-disable-next-line camelcase
          custom_status: MATERIAL_BATCH_CUSTOM_STATUSES.IN_TESTING,
        },
      });

      if (isMaterialTestPanelFeatureEnabled) {
        // Currently calling these APIs if the feature is enabled only -> [sc-57204]
        const batchAction = await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`,
          {
            json: {
              'source_batch': batchUri,
              'action_type': 'test',
              'metadata': {
                'test_completion_date': new Date().toISOString(),
              },
            },
          }).json();

        await api.post(`${API_RESOURCES.SAMPLE}/`,
          {
            json: {
              'material_batch': batchUri,
              'source_action': batchAction.uri,
              'name': sampleName,
            },
          });
      }


    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(batchUri) },
      { action: MATERIAL_BATCH_ACTIONS.TEST }));

    return new Promise(() => {
    });
  };

  return (
    <ActionPage
      id={batchUUID}
      user={user}
      httpError={error}
      isLoading={isLoading}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.TEST}
    >
      <div className="alert alert-warning" role="alert">
        This will mark the batch as <span className="badge badge-secondary">In Testing</span>.
      </div>

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isSubmitting}
        onClick={() => onActionStartTest(batch.uri)}
      >
        Confirm
      </button>
    </ActionPage>
  );
};

StartTest.propTypes = {
  user: userPropType,
};

StartTest.defaultProps = {
  user: null,
};

export default StartTest;
