import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ErrorPage from '../pages/error-page.jsx';
import { reportError } from '../utils/errors';

class SentryErrorBoundary extends Component {
  state = {
    error: null,
    eventId: null,
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
  };

  static defaultProps = {
    userName: '',
    userEmail: '',
  };

  static getDerivedStateFromError(error) {
    return {
      error,
    };
  }

  componentDidCatch(error, errorInfo) {
    reportError(error, errorInfo);
  }

  render() {
    const { error, eventId } = this.state;

    if (error) {
      const { userName, userEmail } = this.props;
      return (
        <ErrorPage error={error} eventId={eventId} userName={userName} userEmail={userEmail} />
      );
    }

    return this.props.children;
  }
}

export default SentryErrorBoundary;
