export const keyArrayBy = (array, key, valueKey = null) => {
  return array.reduce((previousValue, currentValue) => {
    let objectKey;

    if (key instanceof Function) {
      // Run callback, is it's method
      objectKey = key(currentValue);
    } else {
      objectKey = currentValue[key];
    }

    previousValue[objectKey] = valueKey ? currentValue[valueKey] : currentValue;
    return previousValue;
  }, {});
};

export const groupBy = (array, key, valueKey = null) => {
  return array.reduce((result, currentValue) => {
    if (!Object.prototype.hasOwnProperty.call(result, currentValue[key])) {
      result[currentValue[key]] = [];
    }

    result[currentValue[key]].push(valueKey ? currentValue[valueKey] : currentValue);
    return result;
  }, {});
};

export const unique = array => {
  return array.filter((item, currentIndex, allItems) => allItems.indexOf(item) === currentIndex);
};
