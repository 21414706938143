import { useEffect, useState } from 'react';

const useMediaQuery = query => {
  const [matches, setMatches] = useState(() => window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);

    const updateMatches = event => setMatches(event.matches);
    mediaQueryList.addEventListener('change', updateMatches);

    // Clean up the event listener on unmount
    return () => {
      mediaQueryList.removeEventListener('change', updateMatches);
    };
  }, [query]);

  return matches;
};

export default useMediaQuery;
