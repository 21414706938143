import { faCodeMerge, faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse, { Panel } from 'rc-collapse';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Loader from 'src/components/loader';
import Modal from 'src/components/modal';
import { api } from 'src/utils/api';
import capitalize from 'src/utils/capitalize';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS,
  MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES,
  MATERIAL_CONTAINER_STATUSES,
} from 'src/utils/constants';
import routes from 'src/utils/routes';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';

import BatchPowderQualityWarningModal from '../../../material-container/sections/_powder_quality_warning_modal';
import SieveModal from '../sections/_sieve_modal';
import ActionPage from './_action-wrapper';


const BlendBatch = () => {
  const [searchParams] = useSearchParams();
  const { uuid: batchUUID } = useParams();
  const {
    blendedBatch: blendedBatchUri,
    skipSieving,
  } = Object.fromEntries(searchParams.entries()) ?? {};
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [differentBatchMaterialName, setDifferentBatchMaterialName] = useState(false);
  const [confirmationModalShown, setConfirmationModalShown] = useState(false);
  const [differentLocationModalShown, setDifferentLocationModalShown] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSieveModalShown, setIsSieveModalShown] = useState(false);
  const [isBatchQualityWarningModal, setIsBatchQualityWarningModal] = useState(false);
  const [unsievedBatches, setUnsievedBatches] = useState([]);
  const [blendBatchState, setBlendBatchState] = useState({
    id: batchUUID,
    originalBatch: null,
    originalBatchContainers: null,
    blendedBatch: null,
    blendedBatchContainers: null,
    user: null,
    blendedMaterialLots: null,
    originalMaterialLots: null,
    subLocations: null,
    error: null,
  });

  const navigate = useNavigate();

  const { addToast } = useToasts();

  const hasDifferentMaterialTypes = blendBatchState.originalBatch?.materials.filter((originalMaterial) =>
    !blendBatchState.blendedBatch?.materials.some(
      (blendedMaterial) => originalMaterial.name === blendedMaterial.name
    ),
  ) || [];
  const originalBatchHasPowderQualityWarningStatus = unsievedBatches.includes(getShortUuid(blendBatchState.originalBatch?.uuid));
  const batchToBlendHasPowderQualityWarningStatus = unsievedBatches.includes(getShortUuid(blendBatchState.blendedBatch?.uuid));

  const hasDifferentLocations =
    blendBatchState.originalBatch?.location !== blendBatchState.blendedBatch?.location
    && blendBatchState.originalBatch?.sub_location !== blendBatchState.blendedBatch?.sub_location;

  const handleDetectUnsievedBatches = () => {
    const batches = [blendBatchState.originalBatch, blendBatchState.blendedBatch];

    const batchesToUpdate = batches
      .filter((batch) => MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(batch?.powder_quality))
      .map((batch) => getShortUuid(batch?.uuid));

    setUnsievedBatches(batchesToUpdate);
  };

  useEffect(() => {
    setDifferentBatchMaterialName(!!hasDifferentMaterialTypes.length);
  }, [hasDifferentMaterialTypes.length]);

  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      const [originalBatch, blendedBatch] = await Promise.all([
        api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`).json(),
        api.get(blendedBatchUri, { prefixUrl: false }).json(),
      ]);

      const { EMPTY, ...containerStatusesExceptEmpty } = MATERIAL_CONTAINER_STATUSES;

      const { resources: originalBatchNonEmptyContainers } = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
        searchParams: {
          'filter[current_batch]': originalBatch.uri,
          'filter[status]': Object.values(containerStatusesExceptEmpty).join(','),
        },
      }).json();
      const { resources: blendedBatchNonEmptyContainers } = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
        searchParams: {
          'filter[current_batch]': blendedBatch.uri,
          'filter[status]': Object.values(containerStatusesExceptEmpty).join(','),
        },
      }).json();

      const { resources: originalMaterialLots } = await api.get(`${API_RESOURCES.MATERIAL_LOT}/`, {
        searchParams: {
          'filter[uri]': originalBatch.material_lots.join(','),
        },
      }).json();

      const { resources: blendedMaterialLots } = await api.get(`${API_RESOURCES.MATERIAL_LOT}/`, {
        searchParams: {
          'filter[uri]': blendedBatch.material_lots.join(','),
        },
      }).json();

      const subLocationsUris = blendedBatch ?
        [...new Set([originalBatch.sub_location, blendedBatch.sub_location])] :
        [];

      const { resources: subLocations } = await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
        searchParams: {
          'filter[uri]': subLocationsUris.join(','),
        },
      }).json();

      setBlendBatchState({
        id: batchUUID,
        originalBatch,
        originalBatchContainers: originalBatchNonEmptyContainers.map(
          (originalBatchNonEmptyContainer) => originalBatchNonEmptyContainer.uri,
        ),
        blendedBatch,
        blendedBatchContainers: blendedBatchNonEmptyContainers.map(
          (blendedBatchNonEmptyContainer) => blendedBatchNonEmptyContainer.uri,
        ),
        blendedMaterialLots,
        originalMaterialLots,
        subLocations,
      });
    } catch (error) {
      if (error.name === 'HTTPError') {
        setBlendBatchState((prevState) => ({ ...prevState, id: batchUUID }));
        setError({ name: error.name, status: error.response && error.response.status });
      }

      throw error;
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchInitialData();
  }, [batchUUID]);

  useEffect(() => {
    if (!skipSieving) {
      setIsBatchQualityWarningModal(
        originalBatchHasPowderQualityWarningStatus || batchToBlendHasPowderQualityWarningStatus
      );
    }
  }, [skipSieving, originalBatchHasPowderQualityWarningStatus, batchToBlendHasPowderQualityWarningStatus]);

  useEffect(() => {
    handleDetectUnsievedBatches();
  }, [blendBatchState]);

  const inContainersDiffers = (
    blendBatchState.originalBatch?.material_in_containers !== blendBatchState.blendedBatch?.material_in_containers
  );

  const onActionRelocate = async (batchUri, locationUri, subLocationUri) => {
    try {
      /* eslint-disable camelcase */
      const payload = {
        metadata: {
          destination_location: locationUri,
          destination_sub_location: subLocationUri,
        },
        action_type: MATERIAL_BATCH_ACTIONS.RELOCATE,
        source_batch: batchUri,
      };

      setSubmitting(true);
      await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
        json: payload,
      }).json();
    } catch (error_) {
      console.error(error_);
      setSubmitError(true);
      setSubmitting(false);
    }

  };
  const onActionBlendBatch = async (originalBatchUri, blendedBatchUri) => {
    let actionResult = null;
    try {
      const payload = {
        /* eslint-disable camelcase */
        metadata: {
          batch_to_blend: blendedBatchUri,
        },
        action_type: MATERIAL_BATCH_ACTIONS.BLEND_BATCHES,
        source_batch: originalBatchUri,
        /* eslint-enable camelcase */
      };

      setSubmitting(true);
      actionResult = await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
        json: payload,
      }).json();
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(actionResult.metadata.resulting_batch) },
      {
        action: MATERIAL_BATCH_ACTIONS.BLEND_BATCHES,
        blendedBatch: blendedBatchUri,
        blendedBatchQuantity: blendBatchState.blendedBatch.quantity,
        initialBatch: originalBatchUri,
        initialBatchQuantity: blendBatchState.originalBatch.quantity,
      }));

    return new Promise(() => {
    });
  };

  const handleSubmitBlendingBatches = () => {
    if (differentBatchMaterialName) {
      setConfirmationModalShown(true);
      return;
    }

    if (hasDifferentLocations) {
      setDifferentLocationModalShown(true);
      return;
    }

    return onActionBlendBatch(blendBatchState.originalBatch.uri, blendBatchState.blendedBatch.uri);
  };

  const onModalConfirm = () => {

    if (hasDifferentLocations) {
      setConfirmationModalShown(false);
      setDifferentLocationModalShown(true);
      return;
    }

    return onActionBlendBatch(blendBatchState.originalBatch.uri, blendBatchState.blendedBatch.uri);
  };

  const onLocationsMismatchModalConfirm = async () => {
    await onActionRelocate(blendBatchState.blendedBatch.uri, blendBatchState.originalBatch.location, blendBatchState.originalBatch.sub_location);
    return onActionBlendBatch(blendBatchState.originalBatch.uri, blendBatchState.blendedBatch.uri);
  };

  const onModalDismiss = () => setConfirmationModalShown(false);
  const onLocationsMismatchModalDismiss = () => setDifferentLocationModalShown(false);

  const handleRedirectToInitialBatch = () => {
    navigate(getRouteURI(routes.materialContainer, {}, { batch: encodeURIComponent(blendBatchState.originalBatch.uuid) }));
  };

  const handleCloseBatchQualityWarningModal = () => {
    setIsBatchQualityWarningModal(false);
  };

  const renderBatchDetails = (options) => {
    const {
      currentBatch,
      currentLots,
      batchType,
      shouldShowWarningQualityIcon,
    } = options || {};
    if (!currentLots.length) {
      return null;
    }
    const batchContainers = currentBatch.containers;
    return (
      <div className="col-xs-6 blend-batch-item">
        <Collapse accordion>
          <Panel
            key="1"
            header={(
              <div className="h5 blend-batch-title">
                <div>
                  Batch <span className="badge badge-secondary">{getShortUuid(currentBatch.uri)}</span>
                </div>
                <div>
                  <OverlayTrigger
                    placement="top"
                    overlay={(
                      <Tooltip id="blend-batch-tooltip">
                        {batchType === 'original' ? 'Original' : 'Blended'} Batch
                      </Tooltip>
                    )}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-1 ml-2" />
                  </OverlayTrigger>
                </div>
                {
                  shouldShowWarningQualityIcon && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={(
                        <Tooltip id="batch-quality-statustooltip">
                          Consider sieving this batch or use a Batch that is New or already Sieved.
                        </Tooltip>
                      )}
                    >
                      <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1 ml-2 warning-color" />
                    </OverlayTrigger>
                  )
                }
              </div>
            )}
          >
            {!!batchContainers?.length && (
              <div className="h6 header-margin">
                {currentBatch.quantity} {capitalize(currentBatch.units)}
              </div>
            )}
            {currentLots.map((currentLot) => {
              return (
                <Collapse key={currentLot.uri} defaultActiveKey="1">
                  <Panel
                    key="2"
                    header={(
                      <div className="blend-batch-accordion-header">
                        Lot: {currentLot.name} -
                        <span className="badge badge-secondary">{getShortUuid(currentLot.uri)}</span>
                      </div>
                    )}
                    id="header-test"
                  >
                    <ol className="blend-batch-container-list">
                      <li><b>Material Name:</b> {currentLot.material_name}</li>
                    </ol>
                  </Panel>
                </Collapse>
              );
            })}
          </Panel>
        </Collapse>
      </div>
    );
  };

  const renderLocationMismatchText = () => {
    const originalBatchSubLocation = blendBatchState.subLocations.find(
      (subLocation) => subLocation.uri === blendBatchState.originalBatch.sub_location
    );
    const blendedBatchSubLocation = blendBatchState.subLocations.find(
      (subLocation) => subLocation.uri === blendBatchState.blendedBatch.sub_location
    );

    return (
      <>
        You are trying to Blend Batches but there is a Locations mismatch. The Original Batch
        ({getShortUuid(blendBatchState.originalBatch.uri)}) is
        in {blendBatchState.originalBatch.location_name} ({originalBatchSubLocation?.name}) and the Batch
        ({getShortUuid(blendBatchState.blendedBatch.uri)}) is
        in {blendBatchState.blendedBatch.location_name} ({blendedBatchSubLocation?.name}).

        Before Blending Batches, the Batch ({getShortUuid(blendBatchState.originalBatch.uri)}) will be Relocated
        to the matching
        Locations. Are you sure you would like to relocate and continue?
      </>
    );
  };

  const handleNextAction = async (isSieveAction = false) => {
    if (isSieveAction) {
      addToast(`Batch ${getShortUuid(blendBatchState.blendedBatch.uuid)} was successfully Sieved`, { appearance: 'success' });
      // Invalidate page data to refresh sieve information
      await fetchInitialData();
    }
    setIsSieveModalShown(false);
    setIsBatchQualityWarningModal(false);
  };

  const renderBlendBatchesPowderQualityWarningActionIcon = () => {
    let batchesToSieve = null;


    if (originalBatchHasPowderQualityWarningStatus && batchToBlendHasPowderQualityWarningStatus) {
      // If both conditions are true, concatenate both batch UUIDs
      batchesToSieve = `batches ${getShortUuid(blendBatchState.originalBatch.uuid)} and ${getShortUuid(blendBatchState.blendedBatch.uuid)}`;
    } else if (originalBatchHasPowderQualityWarningStatus) {
      // If only the first condition is true, use the original batch UUID
      batchesToSieve = `batch ${getShortUuid(blendBatchState.originalBatch.uuid)}`;
    } else if (batchToBlendHasPowderQualityWarningStatus) {
      // If only the second condition is true, use the blended batch UUID
      batchesToSieve = `batch ${getShortUuid(blendBatchState.blendedBatch.uuid)}`;
    } else {
      return null;
    }

    if (!batchesToSieve) return null;

    return (
      <OverlayTrigger
        placement="bottom"
        overlay={(
          <Tooltip id="batch-quality-statustooltip">
            Consider sieving {batchesToSieve} or use a Batch that is New or already Sieved.
          </Tooltip>
        )}
      >
        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1 warning-color" />
      </OverlayTrigger>
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ActionPage
      id={blendBatchState.id}
      user={blendBatchState.user}
      httpError={error}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.BLEND_BATCHES}
    >
      <div>
        <div className="d-flex align-items-center mb-4 mt-4" style={{ justifyContent: 'space-evenly' }}>
          <FontAwesomeIcon icon={faExclamationTriangle} className="d-block mb-3 warning-blending-icon" />
          <div>
            <h4><span className="badge badge-secondary">{getShortUuid(blendBatchState.originalBatch.uri)}</span>
            </h4>
            <FontAwesomeIcon icon={faCodeMerge} className="d-block merge-icon mr-auto ml-auto" />
            <h4><span className="badge badge-secondary">{getShortUuid(blendBatchState.blendedBatch.uri)}</span>
            </h4>
          </div>
        </div>
        <p className="h6 mb-4 mt-4">
          You are blending material lots to match this batch. Percentage of material from each lot will be an
          estimate only
        </p>
        {inContainersDiffers && (<p className="h5">(The resulting batch will not be stored in containers.)</p>)}
      </div>

      <div className="row batch-container-split-list-row mb-5 flex-nowrap blend-batch-list">
        {renderBatchDetails({
            currentBatch: blendBatchState.originalBatch,
            currentLots: blendBatchState.originalMaterialLots,
            batchType: 'original',
            shouldShowWarningQualityIcon: originalBatchHasPowderQualityWarningStatus,
          }
        )}
        {renderBatchDetails(
          {
            currentBatch: blendBatchState.blendedBatch,
            currentLots: blendBatchState.blendedMaterialLots,
            batchType: 'blended',
            shouldShowWarningQualityIcon: batchToBlendHasPowderQualityWarningStatus,
          }
        )}
      </div>

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isSubmitting}
        onClick={handleSubmitBlendingBatches}
      >
        {renderBlendBatchesPowderQualityWarningActionIcon()}
        Blend Batches
      </button>
      <Link to={`/material-container?batch=${encodeURIComponent(blendBatchState.id)}`}>
        <button type="button" className="btn btn-default btn-action">
          Cancel
        </button>
      </Link>
      <Modal
        isOpen={differentLocationModalShown}
        title="Locations Mismatch"
        dismissText="Cancel"
        confirmText="Relocate"
        onConfirm={onLocationsMismatchModalConfirm}
        onDismiss={onLocationsMismatchModalDismiss}
      >
        {renderLocationMismatchText()}
      </Modal>

      <Modal
        isOpen={confirmationModalShown}
        title="Are you sure?"
        dismissText="Cancel"
        confirmText="Blend Batches"
        onConfirm={onModalConfirm}
        onDismiss={onModalDismiss}
      >
        You are blending material lots to match this batch. Percentage of material from each lot will be an
        estimate only.
      </Modal>

      {
        isBatchQualityWarningModal && (
          <BatchPowderQualityWarningModal
            action={MATERIAL_BATCH_ACTIONS.BLEND_BATCHES}
            additionalAction={() => setIsSieveModalShown(true)}
            redirectAction={handleRedirectToInitialBatch}
            onSubmit={handleNextAction}
            onClose={handleCloseBatchQualityWarningModal} />
        )
      }

      {
        isSieveModalShown && (
          <SieveModal
            initialBatch={blendBatchState.blendedBatch}
            hideModal={() => setIsSieveModalShown(false)}
            redirectToNextAction={() => handleNextAction(true)}
          />
        )
      }
    </ActionPage>
  );
};

export default BlendBatch;
