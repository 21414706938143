import { faCamera, faExternalLink, faFileUpload, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import MultilineText from 'src/components/multiline-text';
import {
  ALLOWED_DOCUMENT_UPLOAD_FORMATS,
  WORK_INSTRUCTION_REPORT_UNITS_VERBOSE,
  WORK_INSTRUCTION_TYPES,
} from 'src/utils/constants';

import WorkInstructionThreshold from './_work-instruction_threshold';

const AdditionalInstructionLink = ({ url }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon
      className="instructions-external-link-icon"
      icon={faExternalLink}
    />
  </a>
);
AdditionalInstructionLink.propTypes = {
  url: PropTypes.string.isRequired,
};

/* Response from the BE will contain of the full string: "Stored 10KG at Front Shelf"
   We need to parse the string and get the [amount] and [location] values from it
   In order to show the values in the inputs in UI.
*/
const parseStorageReport = (string) => {
  const initialData = {
    amount: '',
    location: '',
  };


  if (typeof string !== 'string') {
    return initialData;
  }
  const match = string.match(/Stored\s+(.+)\s+at\s+(.+)/);

  if (!match) {
    return initialData;
  }

  return {
    amount: match[1],
    location: match[2],
  };
};

/* Formats the object containing the amount and location into a string.
   Returns null if the amount or location is not defined. */
const formatStorageReport = (data) => {
  if (!data || !data.amount || !data.location) {
    return null;
  }

  return `Stored ${data.amount} at ${data.location}`;
};

const WorkStep = ({
                    value,
                    type,
                    description,
                    uuid,
                    required,
                    uploading,
                    onChange,
                    thresholdData,
                    reportUnits,
                    choices,
                    additionalInstructionUrl,
                    savedValue,
                    currentInstruction,
                    checkDifferentValue,
                    reportFile,
                  }) => {
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(type === WORK_INSTRUCTION_TYPES.PICTURE && value ? value.content : null);
  const [rangeStart, setRangeStart] = useState(Array.isArray(value) ? value[0] : '');
  const [rangeEnd, setRangeEnd] = useState(Array.isArray(value) ? value[1] : '');
  const [storedOutputData, setStoredOutputData] = useState(parseStorageReport(value));

  const hasRequiredTypeToShow = savedValue && [
    WORK_INSTRUCTION_TYPES.TEXT,
    WORK_INSTRUCTION_TYPES.NUMBER,
    WORK_INSTRUCTION_TYPES.RANGE,
    WORK_INSTRUCTION_TYPES.CHECKBOX].includes(type);

  const isRange = type === WORK_INSTRUCTION_TYPES.RANGE
    && Array.isArray(savedValue);
  const isCheckbox = type === WORK_INSTRUCTION_TYPES.CHECKBOX && typeof savedValue === 'boolean';

  const hasDifferentValue = checkDifferentValue(value, savedValue);

  const RenderValueToShow = () => {

    if (isRange) {
      return <strong>{savedValue.join(' - ')}</strong>;
    }

    if (isCheckbox) {
      return (
        <strong>
          {savedValue ? 'Checked' : 'Not Checked'}
        </strong>
      );
    }

    return <strong>{savedValue}</strong>;
  };

  const id = `instruction-${uuid}`;

  function handleRangeStartChange({ target }) {
    setRangeStart(target.value);
    onChange([target.value, rangeEnd]);
  }

  function handleRangeEndChange({ target }) {
    setRangeEnd(target.value);
    onChange([rangeStart, target.value]);
  }

  function handleOnChange({ target }) {
    if (target.type === 'checkbox') {
      onChange(target.checked);
      return;
    }

    onChange(target.value);
  }

  function handleFileChange({ target }) {
    const [file] = target.files;
    setFile(file);
    onChange(file);

    if (!file?.type?.startsWith('image/')) {
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', ({ target: { result } }) => setImage(result));
    reader.readAsDataURL(file);
  }

  function handleStoredAtLocationChange({ target }, field) {
    const outputData = {
      ...storedOutputData,
      [field]: target.value,
    };

    setStoredOutputData(outputData);


    const stringified = formatStorageReport(outputData);

    if (stringified) {
      onChange(stringified);
    }
  }


  let workstepContent = '';

  const reportUnitsAddon = reportUnits && (
    <div className="input-group-append">
      <span className="input-group-text">{WORK_INSTRUCTION_REPORT_UNITS_VERBOSE[reportUnits] || reportUnits}</span>
    </div>
  );

  switch (type) {
    case WORK_INSTRUCTION_TYPES.TEXT:
    case WORK_INSTRUCTION_TYPES.NUMBER:
      workstepContent = (
        <>
          <div className="input-group">
            <input
              value={value}
              type={type === WORK_INSTRUCTION_TYPES.NUMBER ? 'number' : 'text'}
              className="form-control"
              id={id}
              aria-describedby={type === WORK_INSTRUCTION_TYPES.NUMBER && `${id}-help`}
              onChange={handleOnChange}
            />
            {reportUnitsAddon}
          </div>
          {type === WORK_INSTRUCTION_TYPES.NUMBER && (
            <small id={`${id}-help`} className="form-text text-muted">
              Enter a number.
            </small>
          )}
        </>
      );
      break;
    case WORK_INSTRUCTION_TYPES.RANGE:
      workstepContent = (
        <>
          <div className="d-flex justify-content-center">
            <div className="input-group mr-3">
              <input
                value={rangeStart}
                type="number"
                className="form-control"
                style={{ width: '40%' }}
                aria-describedby={`${id}-help`}
                onChange={handleRangeStartChange}
              />
              {reportUnitsAddon}
            </div>
            <div className="input-group">
              <input
                value={rangeEnd}
                type="number"
                className="form-control"
                style={{ width: '40%' }}
                aria-describedby={`${id}-help`}
                onChange={handleRangeEndChange}
              />
              {reportUnitsAddon}
            </div>
          </div>
          <small id={`${id}-help`} className="form-text text-muted">
            Enter a number range.
          </small>
        </>
      );
      break;
    case WORK_INSTRUCTION_TYPES.CHECKBOX:
      workstepContent = (
        <div className="form-group form-check mb-0">
          <input
            checked={Boolean(value)}
            type="checkbox"
            className="form-check-input"
            id={id}
            onChange={handleOnChange}
          />
          <label className="form-check-label" htmlFor={id}>{description}</label>
          {required && <span className="required-text">* Required</span>}
          {additionalInstructionUrl && (
            <AdditionalInstructionLink url={additionalInstructionUrl} />
          )}
        </div>
      );
      break;
    case WORK_INSTRUCTION_TYPES.PICTURE:
    case WORK_INSTRUCTION_TYPES.DOCUMENT:
      /* eslint-disable no-case-declarations */
      const isPicture = type === WORK_INSTRUCTION_TYPES.PICTURE;
      const icon = isPicture ? faCamera : faFileUpload;
      const fileTypes = isPicture
        ? 'image/*'
        : ALLOWED_DOCUMENT_UPLOAD_FORMATS.map((format) => `.${format}`).join(', ');
      const label = isPicture ? 'Take a picture' : 'Upload a document';
      /* eslint-enable no-case-declarations */

      workstepContent = (
        <>
          {uploading ? (
            <div className="progress work-instruction-progress">
              <div
                className="progress-bar bg-info progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow={uploading.progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{
                  width: `${uploading.progress}%`,
                }}
              />
            </div>
          ) : (
            <div className="custom-file">
              <input
                type="file" accept={fileTypes} className="custom-file-input"
                id="customFile"
                onChange={handleFileChange} />
              <label className="custom-file-label plain" htmlFor="customFile">
                <FontAwesomeIcon icon={icon} />{' '}
                {file ? file.name : (value ? value.name : label)}
              </label>
            </div>
          )}
          {isPicture && image && (
            <div className="work-instruction-preview-image mt-3">
              <img src={image} alt="" />
            </div>
          )}
        </>
      );
      break;
    case WORK_INSTRUCTION_TYPES.SINGLE_SELECT_DROPDOWN:
      workstepContent = (
        <select
          style={{ width: '90%' }}
          onChange={handleOnChange}
        >
          {required
            ? <option disabled selected value="">Choose...</option>
            : <option value="">None</option>}
          {choices.map((choice) => (
            <option key={choice} value={choice}>{choice}</option>
          ))}
        </select>
      );
      break;
    case WORK_INSTRUCTION_TYPES.OUTPUT_AT_LOCATION:
      workstepContent = (
        <>
          <div className="d-flex justify-content-center">
            <div className="input-group mr-3">
              <input
                name="storedAmount"
                value={storedOutputData.amount}
                type="text"
                placeholder="Amount"
                className="form-control"
                aria-describedby={`${id}-help`}
                onChange={(event) =>
                  handleStoredAtLocationChange(event, 'amount')}
              />
            </div>
            <div className="input-group">
              <input
                name="storedLocation"
                value={storedOutputData.location}
                type="text"
                placeholder="Location"
                className="form-control"
                aria-describedby={`${id}-help`}
                onChange={(event) =>
                  handleStoredAtLocationChange(event, 'location')}
              />
            </div>
          </div>
          <small id={`${id}-help`} className="form-text text-muted">
            Stored [amount] at [location]
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip id="work-instruction-output-at-location-type" className="work-instruction-tooltip">
                  Please select amount (you can also type Units) and location (where it is stored).
                  <br />
                  <br />
                  For example:
                  <br />
                  <br />
                  <strong>Stored 10 at at Back Corner Shelf</strong><br />
                  <strong>Stored 5 items at The Office</strong><br />
                  <strong>Stored 4KG at Front Shelf</strong>
                </Tooltip>)}
            >
              <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
            </OverlayTrigger>
          </small>
        </>
      );
      break;

    default:
      break;
  }

  return (
    <form className="work-instruction mb-0">
      {type !== WORK_INSTRUCTION_TYPES.CHECKBOX && (
        <label className="word-break w-full" htmlFor={id}>
          <MultilineText>
            {description}
          </MultilineText>
          {reportFile && (
            <>
              <p className="mt-1 mb-0 text-small">Current value:</p>
              <p className="mb-1 text-small"><em>{reportFile}</em></p>
            </>
          )}
          <div>
            {required && <span className="required-text">* Required</span>}
            {additionalInstructionUrl && (
              <AdditionalInstructionLink url={additionalInstructionUrl} />
            )}
          </div>
        </label>
      )}
      {workstepContent}
      {
        currentInstruction?.uuid === uuid && (hasRequiredTypeToShow && hasDifferentValue) && (
          <span className="work-instruction-not-submitted-value">
            <span style={{ color: 'red' }}>
              New value not submitted. Click check to submit.
            </span>
            <p>Current value: <RenderValueToShow /></p>
          </span>
        )
      }
      <div className="mt-2">
        <WorkInstructionThreshold
          value={value}
          thresholdData={thresholdData}
        />
      </div>
    </form>
  );
};

WorkStep.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })]),
  type: PropTypes.oneOf(Object.values(WORK_INSTRUCTION_TYPES)).isRequired,
  description: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  uploading: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
    progress: PropTypes.number.isRequired,
  })]).isRequired,
  thresholdData: PropTypes.shape({
    threshold: PropTypes.shape({}),
    action: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func,
  reportUnits: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.string),
  additionalInstructionUrl: PropTypes.string,
  savedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)]),
  currentInstruction: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  checkDifferentValue: PropTypes.func.isRequired,
  reportFile: PropTypes.oneOfType([
    PropTypes.instanceOf(null),
    PropTypes.bool,
    PropTypes.string]),
};

WorkStep.defaultProps = {
  value: '',
  additionalInstructionUrl: null,
  reportUnits: null,
  onChange: () => {
  },
  choices: [],
  reportFile: null,
};

export default WorkStep;
