export const isUUID = uuid => {
  // Regexp to detect UUID style.
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(uuid);
};

export const isShortUUID = uuid => {
  // Regexp to detect short UUID style (the first 8 characters of a standard UUID).
  const regexExpShort = /^[0-9a-fA-F]{8}$/gi;

  return regexExpShort.test(uuid);
};
