import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import logoImg from 'public/static/icon-192.png';
import React, { useContext, useEffect, useState } from 'react';
import FeaturesContext from 'src/context/FeaturesContext';
import config from 'src/utils/config';
import { BUREAU_BARCODE_FORMAT } from 'src/utils/constants';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';
import { getShortUuid, getUuid } from 'src/utils/url';

import BarcodeQrCodeOutput from './BarcodeQrCodeOutput';
import Feature from './feature';
import IntlDate from './intl-date.jsx';
import IntlNumber from './intl-number.jsx';
import TruncatedTitle from './TruncatedTitle';

const Traveler = ({
  print,
  order,
  lineItem,
  model,
  workflow,
  orderOwner,
  printingProcessStepRun,
  piece,
  isPowderWorkflow,
  scanningMode,
}) => {
  const [qrUrl, setQrUrl] = useState('');
  const [userLocale, setUserLocale] = useState('en-US');

  const lineItemUri = lineItem && lineItem.uri;
  const customFieldValue = lineItem?.custom_field_values[0]?.value;

  const { features } = useContext(FeaturesContext);
  const isAmazonUser = isFeatureEnabled(features, FEATURES.AMAZON_ORDER_FIELDS);

  useEffect(() => {
    if (lineItemUri) {
      const url = new URL(`${window.location.origin}/summary`);
      url.searchParams.set('line_item', getUuid(lineItemUri));
      url.searchParams.set('copy', print.copy);

      setQrUrl(String(url));
      setUserLocale(window.navigator.languages[0]);
    }
  }, [lineItemUri, print.copy]);

  let formattedDate = 'N/A';
  let orderUrl = null;
  let pieceUrl = null;

  if (order && isAmazonUser) {
    formattedDate = order.due_date && <IntlDate date={order.due_date} locale={userLocale} />;
    orderUrl = `${config.rapidfabHost}/#/records/order/${getUuid(order.uri)}`;
  }

  if (piece && isAmazonUser) {
    pieceUrl = `${config.rapidfabHost}/#/records/piece/${getUuid(piece.uri)}`;
  }

  return (
    <div className='traveler'>
      {!isPowderWorkflow && lineItem && lineItem.additive.no_model_upload && (
        <div className='model hide-on-print'>
          <img src='/no_circle.png' alt='No' />
          <h5 style={{ color: 'gray' }}>Piece has No Model data</h5>
        </div>
      )}
      {model && (
        <div className='model'>
          <img src={model.snapshot_content} alt='' />
        </div>
      )}
      <Feature featureName={FEATURES.QR_PRINTS_TRAVELER}>
        <div className='print-info'>
          <h1>
            {(piece && (
              <TruncatedTitle
                inline
                title={piece.name}
                maxTextLength={25}
                maxTextLengthMobile={20}
                tooltipPlacement='bottom'
              />
            )) ||
              'N/A'}{' '}
            {lineItem?.customer_id && `(${lineItem.customer_id})`}
            {pieceUrl && (
              <a href={pieceUrl} className='spacer-left' target='_blank' rel='noreferrer'>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </a>
            )}
          </h1>
          <dl>
            <Feature isInverted featureName={FEATURES.POWDER_WORKFLOW}>
              <dt>Material:</dt>
              <dd>{print.material_name}</dd>
            </Feature>
            <dt>Workflow:</dt>
            <dd>{workflow ? workflow.name : 'N/A'}</dd>
            <dt>Quantity:</dt>
            <dd>
              Piece <IntlNumber number={print.copy} /> of{' '}
              {lineItem ? <IntlNumber number={lineItem.quantity} /> : 'N/A'}
            </dd>

            <hr className='hide-on-print' />
            <dt>Order:</dt>
            <dd>
              {order ? `${order.name}${order.customer_id && ` (${order.customer_id})`}` : 'N/A'}
              {orderUrl && (
                <a href={orderUrl} className='spacer-left' target='_blank' rel='noreferrer'>
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </a>
              )}
            </dd>
            <dt>Customer:</dt>
            <dd>
              {(order && order.customer_name) || 'N/A'}{' '}
              {order?.customer_po && `(${order.customer_po})`}
            </dd>
            <dt>Shipping:</dt>
            <dd>{(order && order.shipping.address) || 'N/A'}</dd>
            <dt>Due Date:</dt>
            <dd>{formattedDate}</dd>
          </dl>
        </div>
      </Feature>

      <Feature
        isInverted
        featureNames={[FEATURES.BEEHIVE3D_ORDER_FIELDS, FEATURES.QR_PRINTS_TRAVELER]}
      >
        <div className='print-info'>
          <h1>
            {order ? `${order.name} (${order.customer_id || getShortUuid(order.uri)})` : 'N/A'}
          </h1>

          <dl>
            <Feature featureName={FEATURES.BOEING_ORDER_FIELDS}>
              <dt>Part Name:</dt>
              <dd>{(lineItem && lineItem.name) || 'N/A'}</dd>
            </Feature>
            <dt>Customer:</dt>
            <dd>{(order && order.customer_name) || 'N/A'}</dd>
            <Feature featureName={FEATURES.SINTAVIA_ORDER_FIELDS}>
              <dt>Owner:</dt>
              <dd>{orderOwner ? `${orderOwner.name} (${orderOwner.username})` : 'N/A'}</dd>
              <dt>Lot:</dt>
              <dd>{printingProcessStepRun ? printingProcessStepRun.name : 'N/A'}</dd>
            </Feature>
            <dt>Due Date:</dt>
            <dd>{formattedDate}</dd>
            <dt>Shipping:</dt>
            <dd>{(order && order.shipping.address) || 'N/A'}</dd>
            <Feature isInverted featureName={FEATURES.POWDER_WORKFLOW}>
              <dt>Material:</dt>
              <dd>{print.material_name || 'N/A'}</dd>
            </Feature>
            <dt>Workflow:</dt>
            <dd>{workflow ? workflow.name : 'N/A'}</dd>
            <dt>Quantity:</dt>
            <dd>
              <IntlNumber number={print.copy} /> /{' '}
              {lineItem ? <IntlNumber number={lineItem.quantity} /> : 'N/A'}
            </dd>
          </dl>
        </div>
      </Feature>
      <Feature featureName={FEATURES.BEEHIVE3D_ORDER_FIELDS}>
        <div className='print-info'>
          <h1>{(piece && piece.name) || 'N/A'}</h1>
          <dl>
            <dt>Part:</dt>
            <dd>
              {(lineItem && lineItem.customer_id) || 'N/A'} (Revision: {customFieldValue || 'N/A'})
            </dd>
            <Feature isInverted featureName={FEATURES.POWDER_WORKFLOW}>
              <dt>Material:</dt>
              <dd>{print.material_name}</dd>
            </Feature>
            <hr className='hide-on-print' />
            <dt>Order:</dt>
            <dd>
              {order ? `${order.name} (${order.customer_id || getShortUuid(order.uri)})` : 'N/A'}
            </dd>
            <dt>Customer:</dt>
            <dd>{(order && order.customer_name) || 'N/A'}</dd>
            <dt>PO Number:</dt>
            <dd>{(order && order.customer_po) || 'N/A'}</dd>
            <dt>Due Date:</dt>
            <dd>{formattedDate}</dd>
            <dt>Owner:</dt>
            <dd>{(order && order.order_owner_name) || 'N/A'}</dd>
          </dl>
        </div>
      </Feature>

      <BarcodeQrCodeOutput url={qrUrl} scanningMode={scanningMode} />

      <Feature isInverted featureName={FEATURES.BEEHIVE3D_ORDER_FIELDS}>
        <footer>
          <p>
            Powered by <img className='logo' src={logoImg} alt='' /> Authentise
          </p>
        </footer>
      </Feature>
    </div>
  );
};

// API results are camelcase ¯\_(ツ)_/¯
/* eslint-disable camelcase */
Traveler.propTypes = {
  print: PropTypes.shape({
    uri: PropTypes.string,
    copy: PropTypes.number,
    material_name: PropTypes.string,
  }).isRequired,
  order: PropTypes.shape({
    due_date: PropTypes.string,
    customer_name: PropTypes.string,
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    shipping: PropTypes.shape({
      address: PropTypes.string,
    }),
    customer_id: PropTypes.string,
    order_owner_name: PropTypes.string,
    customer_po: PropTypes.string,
  }),
  lineItem: PropTypes.shape({
    additive: PropTypes.shape({
      no_model_upload: PropTypes.bool,
    }).isRequired,
    quantity: PropTypes.number.isRequired,
    uri: PropTypes.string.isRequired,
    name: PropTypes.string,
    custom_field_values: PropTypes.string,
    customer_id: PropTypes.string,
  }),
  model: PropTypes.shape({
    snapshot_content: PropTypes.string,
  }),
  workflow: PropTypes.shape({
    name: PropTypes.string,
  }),
  orderOwner: PropTypes.shape({
    name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }),
  printingProcessStepRun: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  piece: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
  }),
  isPowderWorkflow: PropTypes.bool,
  scanningMode: PropTypes.string,
};

Traveler.defaultProps = {
  orderOwner: null,
  printingProcessStepRun: null,

  // Next empty values added to cover some edge-cases with deleted entities
  // which should not be usually accessed in real life examples

  // Order may already be deleted
  order: null,
  // Model may be null, when line item is already deleted
  model: null,
  // Workflow may be null, when line item is already deleted
  workflow: null,
  // Line Item may already be deleted
  lineItem: null,
  piece: null,
  isPowderWorkflow: false,
  scanningMode: BUREAU_BARCODE_FORMAT.QR,
};

/* eslint-enable camelcase */

export default Traveler;
