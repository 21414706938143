import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import Alert from 'src/components/alert';
import Header from 'src/components/header.jsx';
import Loader from 'src/components/loader.jsx';
import Qr from 'src/components/qr.jsx';
import UserContext from 'src/context/UserContext';
import { api } from 'src/utils/api';
import { CONTACTLESS_LOGIN_ENABLED } from 'src/utils/constants';
import routes from 'src/utils/routes';
import userPropType from 'src/utils/user-prop-type';


const LogonScanPage = ({

                         user,
                       }) => {
  const [urlError, setUrlError] = useState(false);
  const [successfulScan, setSuccessfulScan] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);
  const [, setCookie] = useCookies([CONTACTLESS_LOGIN_ENABLED]);
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  async function handleScan(data) {
    setSuccessfulScan(true);
    setShowInstructions(false);

    const { username, contactless_secret: contactlessSecret } = data;

    try {
      const payload = {
        username,
        'contactless_secret': contactlessSecret,
        'logon_type': 'qr',
      };
      const userData = await api.post('sessions/', {
        json: payload,
      }).json();
      if (userData) {
        setCookie(CONTACTLESS_LOGIN_ENABLED, true, { path: '/' });
        setUser({
          ...userData,
          initialLogin: true,
        });
        navigate(routes.home);
      }
    } catch (error) {
      if (error.response) {
        const json = await error.response.json();
        const jsonError = json.errors[0];
        return handleError(jsonError);
      }
      return handleError(error);
    }
  }

  function handleError(error) {
    setSuccessfulScan(false);
    setUrlError(error);
  }

  const instructionText = `Scan User Login QR code to Login`;

  return (
    <>
      <Header title="User QR code Login" user={user} />

      <main role="main">
        <div className="qr-reader text-white bg-dark rounded-sm overflow-hidden mb-3">
          <Qr
            isLogonScan
            hidden={successfulScan}
            onScan={handleScan}
            onError={handleError}
          >
            <Loader inline text="data" className="mt-3" />
          </Qr>
        </div>
        {urlError && (
          <Alert name="qr-error" variant="danger" className="text-center">
            {urlError.title}
          </Alert>
        )}
        {!urlError && showInstructions && (
          <>
            <Alert name="qr-instructions" variant="secondary" className="text-center">
              <span>{instructionText}</span>
            </Alert>
            <Alert variant="info" className="small" name="remove-print-scan-info">
              If you do not have a User Login Code or do not know where to find it,
              please contact a member of the support team or contact us at&nbsp;
              <a href="mailto:support@authentise.com">support@authentise.com</a>.
            </Alert>
          </>
        )}
      </main>
    </>
  );
};

LogonScanPage.propTypes = {
  user: userPropType.isRequired,
};

LogonScanPage.allowLoggedOut = true;

export default LogonScanPage;
