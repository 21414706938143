import { TIME_ENTRY_CATEGORIES } from './constants';
import { FEATURES, isFeatureEnabled } from './features';

export function getEnabledTimeEntryCategories(features) {
  const isRunTimeTrackingFeatureEnabled = isFeatureEnabled(features, FEATURES.RUN_TIME_TRACKING);
  const isUserTimeTrackingFeatureEnabled = isFeatureEnabled(
    features,
    FEATURES.USER_RUN_TIME_TRACKING
  );
  const timeEntryCategories = [];
  if (isRunTimeTrackingFeatureEnabled) {
    timeEntryCategories.push(TIME_ENTRY_CATEGORIES.MACHINE_RUNNING);
  }
  if (isUserTimeTrackingFeatureEnabled) {
    timeEntryCategories.push(TIME_ENTRY_CATEGORIES.ATTENDING);
  }
  return timeEntryCategories;
}
