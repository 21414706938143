import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Alert from 'src/components/alert.jsx';
import Loader from 'src/components/loader';
import Modal from 'src/components/modal.jsx';

const WorkstationModal = ({ onClose, onSubmit, initialWorkstation, choices, isOpen }) => {
  const [workstationType, setWorkstationType] = useState(initialWorkstation || '');
  const [isLoading, setIsLoading] = useState(false);
  const [saveError, setSaveError] = useState(null);

  const hideModal = () => {
    setIsLoading(false);
    setSaveError(null);
    onClose();
  };

  const handleOnChange = ({ target }) => {
    setWorkstationType(target.value);
  };

  const onModalDismiss = () => {
    hideModal();
  };

  const onModalConfirm = async () => {
    if (!workstationType) {
      return;
    }
    setIsLoading(true);

    try {
      await onSubmit({ workstationType });
      hideModal();
    } catch (error) {
      setSaveError(error);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Workstation"
      dismissText="Cancel"
      confirmText="Save"
      onConfirm={onModalConfirm}
      onDismiss={onModalDismiss}
    >
      {saveError && (
        <Alert variant="danger" className="small" name="workstation">
          There was an error saving the workstation. Please try again.
        </Alert>
      )}
      {isLoading && <Loader inline />}
      <select className="form-control" value={workstationType} onChange={handleOnChange}>
        {choices.map((choice) => (
          <option key={choice.value} value={choice.value}>{choice.label}</option>
        ))}
      </select>
    </Modal>
  );
};

WorkstationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  initialWorkstation: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  choices: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
};

export default WorkstationModal;
