import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import { RUN_ACTIONS } from 'src/utils/constants';
import routes from 'src/utils/routes';
import useQueryParams, { getRouteURI } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

const RunActionPage = ({ user }) => {
  const [actionNotFound, setActionNotFound] = useState(false);
  const { uuid: id } = useParams();
  const {
    type,
    runUri,
    toolingStockUUID,
  } = useQueryParams();
  const navigate = useNavigate();

  useEffect(() => {
    let pageName = null;
    const queryParams = {};


    switch (type) {
      case RUN_ACTIONS.SCAN_TOOLING_STOCK:
        pageName = 'scan-tooling-stock';
        queryParams.runUri = runUri;
        queryParams.toolingStockUUID = toolingStockUUID;
        queryParams.initialAction = RUN_ACTIONS.SCAN_TOOLING_STOCK;
        break;
      default:
        setActionNotFound(true);
        break;
    }

    if (pageName) {
      navigate(getRouteURI(
          `${routes.runAction}/${pageName}`, { uuid: id }, queryParams),
        { replace: true });
    }

  }, [type]);

  if (actionNotFound) {
    return (
      <>
        <Header title="Run Action Not Found" user={user} />
        <main role="main" className="text-center">
          <NotFound id={id} />
        </main>
      </>
    );
  }

  return (
    <>
      <Header title="Run Action" user={user} />
      <main role="main" className="text-center">
        <Loader />
      </main>
    </>
  );
};

RunActionPage.propTypes = {
  user: userPropType,
};

RunActionPage.defaultProps = {
  user: null,
};

export default RunActionPage;
