import PropTypes from 'prop-types';
import React from 'react';
import { STATUS_COLOR_CODE_TYPE_TO_COLOR_MAP, STATUS_COLOR_CODE_TYPES } from 'src/utils/constants';

const StatusColorCode = ({ status, type, sizeClassName }) => {
  const statusColor = STATUS_COLOR_CODE_TYPE_TO_COLOR_MAP[type]
    ? // Use color mapping by color code type
      STATUS_COLOR_CODE_TYPE_TO_COLOR_MAP[type][status]
    : // Or hide the component
      null;

  if (!statusColor) {
    // No color statuses to display
    return null;
  }

  return (
    <span style={{ backgroundColor: statusColor }} className={`${sizeClassName}-status-dot`} />
  );
};

StatusColorCode.defaultProps = {
  status: '',
  type: STATUS_COLOR_CODE_TYPES.BATCH_STATUS,
  sizeClassName: 'small',
};
StatusColorCode.propTypes = {
  status: PropTypes.string,
  type: PropTypes.oneOf(Object.values(STATUS_COLOR_CODE_TYPES)),
  sizeClassName: PropTypes.string,
};

export default StatusColorCode;
