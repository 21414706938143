import { validate as validEmail } from 'email-validator';

export const isValidEmail = email => {
  const trimmedEmail = `${email}`.trim();

  if (!trimmedEmail) {
    throw new TypeError('Please enter your email address.');
  }

  if (!validEmail(trimmedEmail)) {
    throw new TypeError('Invalid email address.');
  }

  return true;
};

// This is a simple required validator for now. If we add a password reset or
// signup form, we should expand to check for more things, but keep it the same
// for logging in. - Brad
export const isValidPassword = password => {
  if (!password) {
    throw new TypeError('Please enter your password.');
  }

  return true;
};

export const isValidDate = date => {
  const parsed = Date.parse(date);
  return isNaN(date) && !isNaN(parsed);
};

export const validateMultipleZeros = event => {
  const inputValue = event.target.value;

  if (/^0{2,}$/.test(inputValue)) {
    // If the input is multiple zeros, reset to a single zero
    event.target.value = '0';
  } else if (/^0[0-9]+/.test(inputValue)) {
    // If the input starts with zero followed by other digits, strip leading zeros
    event.target.value = inputValue.replace(/^0+/, '');
  }
};

/**
 * Determines the step value for a slider based on the quantity provided.
 *
 * @param {number} quantity - The quantity to determine the slider step for.
 * @returns {number} - The step value for the slider.
 */
export const getSliderStep = quantity => {
  if (!quantity) return 1;
  // If the value is less than 1, return 0.01 as the step
  if (quantity < 1) {
    return 0.01;
  }

  // Determine the number of decimal places in the quantity
  const decimalPlaces = quantity.toString().split('.')[1]?.length || 0;

  // If there are no decimal places, return 1
  if (decimalPlaces === 0) {
    return 1;
  }

  // Calculate step size based on the number of decimal places
  const stepSize = Math.pow(10, -decimalPlaces);

  return stepSize;
};
