import PropTypes from 'prop-types';
import React from 'react';

const SelectableUser = ({ user, updateMentionedUsers, isMentioned }) => {
  const getInitials = str => {
    const words = str.split(' ');
    if (words.length === 1) {
      return str[0].toUpperCase();
    }

    const firstWord = words[0].toUpperCase();
    const lastWord = words[words.length - 1].toUpperCase();

    return `${firstWord[0]}${lastWord[0]}`;
  };

  return (
    <div
      className='single-user__row'
      onClick={() => updateMentionedUsers(user.username, isMentioned)}
    >
      <div className='comment__avatar'>
        <span>{getInitials(user.name)}</span>
      </div>
      <div className='name-container'>
        <p>
          {user.name} (<small>{user.username}</small>)
        </p>
      </div>
      <div className='selector__container'>
        <input type='checkbox' checked={isMentioned} />
      </div>
    </div>
  );
};

SelectableUser.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string, // used for email
    uri: PropTypes.string,
  }).isRequired,
  updateMentionedUsers: PropTypes.string.isRequired,
  isMentioned: PropTypes.bool.isRequired,
};

export default SelectableUser;
