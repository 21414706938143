import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from 'src/components/header';

import routes from '../utils/routes';
import userPropType from '../utils/user-prop-type';


const HomePage = ({ user }) => {
  if (user) {
    return (
      <>
        <Helmet>
          <title>Authentise</title>
        </Helmet>
        <Header back="" user={user} />
        <main role="main">
          <div>
            Hi, {user.name}!
          </div>
          <p>
            <Link to={routes.scan}>Scan</Link>
          </p>
        </main>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Authentise</title>
      </Helmet>
      <Header back="" />
      <main role="main">
        <div>Welcome to Authentise QR.</div>
        <p>
          <Link to={routes.login}>Log in to get started</Link>
        </p>
      </main>
    </>
  );
};

HomePage.defaultProps = {
  user: null,
};

HomePage.propTypes = {
  user: userPropType,
};

export default HomePage;
