import PropTypes from 'prop-types';
import React from 'react';

const IntlDate = ({ date, locale, withTime, disableSeconds, ...otherProps }) => {
  const options = withTime
    ? {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        ...(!disableSeconds && { second: 'numeric' }),
      }
    : {};

  const dateObject = new Date(date);
  const formatted = new Intl.DateTimeFormat(locale, options).format(dateObject);

  return (
    <time {...otherProps} dateTime={dateObject.toISOString()} data-testid='date-time'>
      {formatted}
    </time>
  );
};

IntlDate.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  locale: PropTypes.string,
  withTime: PropTypes.bool,
  disableSeconds: PropTypes.bool,
};

IntlDate.defaultProps = {
  locale: 'default',
  withTime: false,
  disableSeconds: false,
};

export default IntlDate;
