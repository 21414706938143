import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Alert from 'src/components/alert';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import Qr from 'src/components/qr';
import { api } from 'src/utils/api';
import { API_RESOURCES } from 'src/utils/constants';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../utils/routes';

const ScannedContainerPage = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { uuid: materialContainerUuid } = useParams();
  const { scannedContainers } = Object.fromEntries(searchParams.entries()) ?? {};
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [scanningRequested, setScanningRequested] = useState(false);
  const [loadingScanData, setLoadingScanData] = useState(false);
  const [scanError, setScanError] = useState(null);
  const [scannedContainersList, setScannedContainersList] = useState([]);

  const getInitialData = async () => {
    setIsLoading(true);
    try {
      const container = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${materialContainerUuid}/`).json();

      if (container.current_batch) {
        setError({
          name: 'Container already assigned to a batch',
          text: 'Container already assigned to a batch',
        });
        return;
      }

      // Next.js doesn't convert query to array only if one container in query,
      // we need to enforce conversion to array
      const scannedContainersList = scannedContainers || [getUuid(container.uri)];
      setScannedContainersList(Array.isArray(scannedContainersList) ? scannedContainersList : [scannedContainersList]);
    } catch (error) {
      setError({
        name: error.name,
        status: error.response && error.response.status,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => void getInitialData(), [materialContainerUuid]);

  function handleError(error) {
    setScanError(error);
  }

  async function handleScan(url, resourceName, resourceUri) {
    setScanError(null);
    setLoadingScanData(true);

    if (scannedContainersList.includes(getUuid(resourceUri))) {
      handleError(new Error('This container is already scanned.'));
      return;
    }

    const container = await api.get(resourceUri, { prefixUrl: false }).json();
    if (container.current_batch) {
      handleError(new Error(`This container is already in batch ${getShortUuid(container.current_batch)}. Try with another container`));
      return;
    }

    const updatedList = [...scannedContainersList, getUuid(resourceUri)];
    setScannedContainersList(updatedList);

    setLoadingScanData(false);
    setScanningRequested(false);
  }

  if (isLoading) {
    return (
      <>
        <Header title="Loading..." back="/scan" user={user} />
        <main role="main" className="text-center">
          <Loader inline text="container" />
        </main>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={materialContainerUuid} text={error.text} />
        </main>
      </>
    );
  }

  const containerText = scannedContainersList.length > 1 ? 'Containers' : 'Container';

  const UseAmountScannedButton = () => {
    /*
     * This component is always used to scan non-batched containers.
     * We always navigate to "initial batch create" confirmation page
     */

    return (
      <div className="optionButtons">
        <Link to={getRouteURI(routes.materialContainerCreateInitialBatch,
          { uuid: materialContainerUuid },
          {
            scannedContainers: scannedContainersList,
          })}
        >
          <button
            disabled={scannedContainersList.length === 0}
            type="button"
            className="btn btn-primary btn-action"
          >
            Use Amount Scanned
          </button>
        </Link>
      </div>
    );
  };


  return (
    <>
      <Header title="Scan Material QR" user={user} />
      <main role="main" className="text-center">
        <div className="qr-reader text-white bg-dark rounded-sm overflow-hidden mb-3">
          <Qr
            requestedEntity={API_RESOURCES.MATERIAL_CONTAINER}
            hidden={!scanningRequested}
            onScan={handleScan}
            onError={handleError}
          >
            {loadingScanData && (<Loader inline text="data" className="mt-3" />)}
            {!loadingScanData && (
              <div className="alert">
                <div>Container scanned successfully</div>
                <button
                  type="button"
                  className="btn btn-lg btn-primary mt-4"
                  onClick={() => setScanningRequested(true)}
                >
                  Scan another container
                </button>
              </div>
            )}
          </Qr>
        </div>
        {scanError && (
          <Alert name="qr-error" variant="danger" className="text-center">
            {scanError.message}
          </Alert>
        )}
        <div>
          <h2 className="header-margin">
            <span className="badge-circle">{scannedContainersList.length}</span>
            &nbsp;{containerText} Scanned
          </h2>
          <h4 className="header-margin">Containers scanned so far:</h4>
          <ol className="batch-container-list">
            {
              scannedContainersList.map((scannedContainerUuid) => (
                <li key={scannedContainerUuid}>
                  {getShortUuid(scannedContainerUuid)}
                </li>
              ))
            }
          </ol>
        </div>
        <div className="row btn-group">
          <UseAmountScannedButton />
        </div>
      </main>
    </>
  );
};

ScannedContainerPage.propTypes = {
  user: userPropType,
};

ScannedContainerPage.defaultProps = {
  user: null,
};

export default ScannedContainerPage;
