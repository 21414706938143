import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'src/components/modal';
import { MATERIAL_BATCH_ACTIONS } from 'src/utils/constants';

const BatchPowderQualityWarningModal = ({ action, onSubmit, onClose, additionalAction, redirectAction }) => {
  const actionText = {
    [MATERIAL_BATCH_ACTIONS.MACHINE_LOAD]: 'Load',
    [MATERIAL_BATCH_ACTIONS.BLEND_BATCHES]: 'Blend',
    [MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF]: 'Top Off',
  };

  const onModalDismiss = () => {
    if (redirectAction) {
      redirectAction();
    }
    onClose();
  };

  const onModalConfirm = async () => {
    try {
      await onSubmit();
      onClose();
    } catch (error) {
      return;
    }
  };

  const onAdditionalAction = async () => {
    try {
      await additionalAction();
    } catch (error) {
      return;
    }
  };

  return (
    <Modal
      isOpen
      title={(
        <>
          <FontAwesomeIcon icon={faExclamationTriangle} color="#FFC008" className="mr-1" />
          <span>Warning</span>
        </>
      )}
      dismissText="Cancel"
      confirmText={`${actionText[action]} Anyway`}
      additionalActionText="Sieve"
      confirmClass="btn-warning text-white"
      onConfirm={onModalConfirm}
      onDismiss={onModalDismiss}
      onAdditionalAction={onAdditionalAction}
    >
      <label className="form-check-label">
        The batch you&apos;re trying to <strong>{actionText[action]}</strong> might not be suitable for use.
        Consider sieving this batch, or opt for a batch that is either New or has already been Sieved.
      </label>
    </Modal>
  );
};

BatchPowderQualityWarningModal.propTypes = {
  action: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  additionalAction: PropTypes.func.isRequired,
  redirectAction: PropTypes.func,
};

BatchPowderQualityWarningModal.defaultProps = {
  redirectAction: null,
};

export default BatchPowderQualityWarningModal;
