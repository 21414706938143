import PropTypes from 'prop-types';
import { useContext } from 'react';
import FeaturesContext from 'src/context/FeaturesContext';
import {
  FEATURES,
  isAllFeaturesEnabled,
  isFeatureEnabled,
  isSomeFeaturesEnabled,
} from 'src/utils/features';

const Feature = ({ children, featureName, featureNames, isInverted, featureNamesEnforceAll }) => {
  if ((!featureName && !featureNames) || (featureName && featureNames)) {
    throw new Error('You have to provide only featureName or featureNames props');
  }

  const { features } = useContext(FeaturesContext);

  let isEnabled;

  if (featureNames) {
    isEnabled = featureNamesEnforceAll
      ? /* If `featureNamesEnforceAll` is true; check if ALL `featureNames` are enabled */
        isAllFeaturesEnabled(features, featureNames)
      : /* If an array of features of is passed in and `featureNamesEnforceAll` is false; return
     true if at least ONE of the features is enabled */
        isSomeFeaturesEnabled(features, featureNames);
  } else {
    isEnabled = isFeatureEnabled(features, featureName);
  }

  if (isInverted) {
    isEnabled = !isEnabled;
  }

  if (isEnabled) {
    return children;
  }

  return null;
};

Feature.propTypes = {
  children: PropTypes.node.isRequired,
  featureName: PropTypes.oneOf(Object.values(FEATURES)).isRequired,
  isInverted: PropTypes.bool,
  featureNames: PropTypes.arrayOf(PropTypes.oneOf(Object.values(FEATURES))),
  featureNamesEnforceAll: PropTypes.bool,
};

Feature.defaultProps = {
  isInverted: false,
  featureNames: null,
  featureNamesEnforceAll: false,
};

export default Feature;
