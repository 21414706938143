import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import config from 'src/utils/config';

if (!config.test) ReactModal.setAppElement('#app');

const Modal = ({
  isOpen,
  onConfirm,
  onDismiss,
  onAdditionalAction,
  confirmText,
  dismissText,
  additionalActionText,
  confirmClass,
  title,
  children,
  scrollable,
  onAfterOpen,
}) => {
  const [confirming, setConfirming] = useState(false);

  const handleConfirm = async () => {
    setConfirming(true);
    await onConfirm();
    setConfirming(false);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      className={{
        base: 'modal fade d-block',
        afterOpen: 'show',
        beforeClose: 'before-close',
      }}
      closeTimeoutMS={250}
      onRequestClose={onDismiss}
      onAfterOpen={onAfterOpen}
    >
      <div
        className={`modal-dialog modal-dialog-centered ${scrollable ? 'modal-dialog-scrollable' : ''}`}
        role='document'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{title}</h5>
            <button type='button' className='close' aria-label='Close' onClick={onDismiss}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>{children}</div>
          {(confirmText || dismissText) && (
            <div className='modal-footer'>
              {dismissText && (
                <button
                  type='button'
                  className='btn btn-secondary'
                  disabled={confirming}
                  onClick={onDismiss}
                >
                  {dismissText}
                </button>
              )}
              {additionalActionText && (
                <button
                  type='button'
                  className='btn btn-primary'
                  disabled={confirming}
                  onClick={onAdditionalAction}
                >
                  {additionalActionText}
                </button>
              )}
              {confirmText && (
                <button
                  type='button'
                  className={`btn ${confirmClass} position-relative`}
                  disabled={confirming}
                  onClick={handleConfirm}
                >
                  <div
                    className={`modal-spinner ${confirming ? 'd-flex' : 'd-none'} align-items-center justify-content-center`}
                  >
                    <div
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    />
                  </div>
                  <span className={`${confirming ? 'invisible' : 'visible'}`}>{confirmText}</span>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  dismissText: PropTypes.string,
  confirmClass: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  scrollable: PropTypes.bool,
  onAfterOpen: PropTypes.func,
  additionalActionText: PropTypes.string,
  onAdditionalAction: PropTypes.func,
};

Modal.defaultProps = {
  confirmText: null,
  dismissText: null,
  confirmClass: 'btn-primary',
  scrollable: false,
  onAfterOpen: null,
  additionalActionText: null,
  onAdditionalAction: null,
};

export default Modal;
