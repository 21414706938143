import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { getShortUuid } from 'src/utils/url';

const SingleComment = ({ comment }) => {
  return (
    <div className='comment'>
      <div className='comment__avatar'>
        <span>
          {comment.author_name
            ?.toUpperCase()
            .split(' ')
            .map(word => word[0])}
        </span>
      </div>
      <div className='comment__body'>
        <div className='comment__header__info'>
          <div className='comment__header__info__name'>
            <strong>{comment.author_name}</strong>
          </div>

          <div className='comment__header__info__date'>
            <i>
              <span>{new Date(comment.created).toLocaleDateString()}</span>
            </i>
          </div>
          <div className='comment__body__content'>
            <ReactMarkdown>{comment.text}</ReactMarkdown>
          </div>
        </div>
      </div>
      <div className='order__info'>
        <span>
          {comment.related_table_name}({getShortUuid(comment.related_uuid)})
        </span>
      </div>
    </div>
  );
};

SingleComment.propTypes = {
  comment: PropTypes.shape({
    // eslint-disable-next-line camelcase
    author_name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    related_table_name: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    related_uuid: PropTypes.string.isRequired,
  }),
};

export default SingleComment;
