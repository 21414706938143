import { faArrowsSpin, faArrowUpShortWide, faObjectGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import StatusColorCode from 'src/components/status-color-dot';
import { MATERIAL_BATCH_STATUS_VERBOSE, STATUS_COLOR_CODE_TYPES } from 'src/utils/constants';
import { getRouteURI, getShortUuid } from 'src/utils/url';

import routes from '../../../../utils/routes';


const MaterialBatchCardDetails = ({ batch }) => {
  if (!batch) return null;

  return (
    <div className="material-batch-card-details">
      <div className="material-batch-card-details-header">
        <p>Batch</p>
        <Link to={getRouteURI(routes.materialContainer, {}, { batch: batch.uuid })}>
          <p className="material-batch-card-details-header-batch mb0">{getShortUuid(batch.uuid)}</p>
        </Link>
      </div>
      <div className="material-batch-card-details-body">
        <div className="d-flex align-items-center justify-content-between mb8">
          <div className="d-flex align-items-center">
            <p className="mb0 d-flex align-items-center">
              <StatusColorCode status={batch.status} type={STATUS_COLOR_CODE_TYPES.BATCH_STATUS} sizeClassName="small" />
            </p>
            <p className="mb0 spacer-left">{MATERIAL_BATCH_STATUS_VERBOSE[batch.status]}</p>
          </div>
          <p className="mb0">
            <FontAwesomeIcon className="spacer-right" icon={faArrowUpShortWide} />
            {batch.quantity} {batch.units}
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb0">
            <FontAwesomeIcon className="spacer-right" icon={faObjectGroup} />
            {batch.materials[0].name}
          </p>
          <p className="mb0">
            <FontAwesomeIcon className="spacer-right" icon={faArrowsSpin} />
            {batch.usage_cycles}
          </p>
        </div>
      </div>
    </div>
  );
};

MaterialBatchCardDetails.propTypes = {
  batch: PropTypes.shape({
    uuid: PropTypes.string,
    units: PropTypes.string,
    status: PropTypes.string,
    quantity: PropTypes.number,
    // eslint-disable-next-line camelcase
    powder_quality: PropTypes.string,
    // eslint-disable-next-line camelcase
    usage_cycles: PropTypes.number,
    materials: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  }),
};

export default MaterialBatchCardDetails;
