import {
  faBarsProgress,
  faLocationDot,
  faShareFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import StatusColorCode from 'src/components/status-color-dot';
import TruncatedTitle from 'src/components/TruncatedTitle';
import FeaturesContext from 'src/context/FeaturesContext';
import { RUN_STATUS_LABELS, STATUS_COLOR_CODE_TYPES } from 'src/utils/constants';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';
import routes from 'src/utils/routes';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';

const RunCard = ({ run, location, workstation }) => {
  const [locationShown, setLocationShown] = useState(false);
  const { features } = useContext(FeaturesContext);
  const isMaterialManagementEnabled = isFeatureEnabled(features, FEATURES.MATERIAL_MANAGEMENT);

  if (!run) return null;

  const toggleLocation = () => setLocationShown(previous => !previous);

  const renderWorkstationName = () => {
    if (!run.workstation_name && !run.printer) return 'Workstation not assigned';
    if (isMaterialManagementEnabled) {
      return (
        <Link to={getRouteURI(routes.printerMaterial, { uuid: getUuid(run.printer) })}>
          {run.workstation_name}
        </Link>
      );
    }
    return run.workstation_name;
  };

  return (
    <div className='scanned-run-card'>
      <div className='scanned-run-header'>
        <div className='scanned-run-title-content'>
          <div className='scanned-run-title-icon-content'>
            <FontAwesomeIcon icon={faShareFromSquare} className='scanned-run-title-icon' />
          </div>
          <div>
            <div className='scanned-run-title'>
              <Link className='text-white' to={getRouteURI(routes.run, { uuid: getUuid(run.uri) })}>
                <TruncatedTitle title={run.name} maxTextLength={28} maxTextLengthMobile={22} />
              </Link>
            </div>
            <div className='scanned-run-uuid'>({getShortUuid(run.uri)})</div>
          </div>
        </div>
      </div>
      <div className='scanned-run-body'>
        <div className='scanned-run-body-item'>
          <p>
            <FontAwesomeIcon icon={faPrint} className='spacer-right' />
            {renderWorkstationName()}
          </p>
          <div>
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip id='workstation-in-progress'>In Progress</Tooltip>}
            >
              <p>
                <FontAwesomeIcon icon={faBarsProgress} className='spacer-right' />
                {workstation
                  ? `${workstation.in_progress?.length || 0} of ${workstation.in_progress_max || 0}`
                  : 'N/A'}
              </p>
            </OverlayTrigger>
          </div>
        </div>
        <div className='scanned-run-body-item'>
          <div className='text-left'>
            <div className='cursor-pointer'>
              <p className='mb0 badge badge-secondary' onClick={toggleLocation}>
                {!locationShown ? 'Show' : 'Hide'} Location
              </p>
            </div>
            {locationShown ? (
              <div className='tooling-stock-card-location-mobile'>
                {location ? (
                  <div>
                    <div className='d-flex align-items-center'>
                      <FontAwesomeIcon className='spacer-right' icon={faLocationDot} />
                      <TruncatedTitle
                        title={location}
                        titleClassName='mb0 font-medium'
                        maxTextLength={25}
                        maxTextLengthMobile={50}
                      />
                    </div>
                  </div>
                ) : (
                  'N/A'
                )}
              </div>
            ) : null}
          </div>
          <div className='d-flex align-items-center'>
            <p className='mb0 d-flex align-items-center'>
              <StatusColorCode
                status={run.status}
                type={STATUS_COLOR_CODE_TYPES.RUN_STATUS}
                sizeClassName='small'
              />
            </p>
            <p className='mb0 spacer-left'>{RUN_STATUS_LABELS[run.status]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

RunCard.propTypes = {
  /* eslint-disable camelcase */
  run: PropTypes.shape({
    status: PropTypes.string,
    name: PropTypes.string,
    uri: PropTypes.string,
    printer: PropTypes.string,
    workstation_name: PropTypes.string,
  }).isRequired,
  location: PropTypes.string.isRequired,
  workstation: PropTypes.shape({
    in_progress: PropTypes.array,
    in_progress_max: PropTypes.number,
  }).isRequired,
};

export default RunCard;
