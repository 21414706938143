import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Alert from 'src/components/alert.jsx';
import Modal from 'src/components/modal.jsx';
import FeaturesContext from 'src/context/FeaturesContext';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';

const PauseModal = ({ onSubmit, onClose }) => {
  const [notes, setNotes] = useState('');
  const [isRunStillRunning, setIsRunStillRunning] = useState(false);
  const [saveError, setSaveError] = useState(null);

  const { features } = useContext(FeaturesContext);

  const isRunTimeTrackingFeatureEnabled = isFeatureEnabled(features, FEATURES.RUN_TIME_TRACKING);

  const hideModal = () => {
    setNotes('');
    setIsRunStillRunning(false);
    setSaveError(null);
    onClose();
  };

  const handleNotesChange = ({ target }) => {
    setNotes(target.value);
  };

  const handleIsRunStillRunningChange = ({ target }) => {
    setIsRunStillRunning(Boolean(target.checked));
  };

  const onModalDismiss = () => {
    hideModal();
  };

  const onModalConfirm = async () => {
    try {
      await onSubmit(notes, isRunStillRunning);
      hideModal();
    } catch (error) {
      setSaveError(error);
    }
  };

  return (
    <Modal
      isOpen
      title="Pause Run"
      dismissText="Cancel"
      confirmText="Submit"
      onConfirm={onModalConfirm}
      onDismiss={onModalDismiss}
    >
      {saveError && (
        <Alert variant="danger" className="small">
          There was an error pausing run. Please try again.
        </Alert>
      )}
      {isRunTimeTrackingFeatureEnabled && (
        <div className="form-group form-check mb-15">
          <input
            type="checkbox"
            className="form-check-input"
            id="isRunStillRunning"
            checked={isRunStillRunning}
            onChange={handleIsRunStillRunningChange}
          />
          <label className="form-check-label" htmlFor="isRunStillRunning">Machine still running</label>
        </div>
      )}
      <textarea
        className="form-control"
        placeholder="Enter Pause Cause"
        value={notes}
        onChange={handleNotesChange}
      />
    </Modal>
  );
};

PauseModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PauseModal;
