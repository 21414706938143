import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const MultilineText = ({ children }) => {
  return (
    // False positive to eslint: https://github.com/yannickcr/eslint-plugin-react/issues/2512
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {children.split(/(?:\r\n|\r|\n)/g).map((text, index) => {
        return (
          <Fragment key={text}>
            {index !== 0 && <br />}
            {text}
          </Fragment>
        );
      })}
    </>
  );
};

MultilineText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MultilineText;
