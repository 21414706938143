import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  faArrowsSpin,
  faArrowUpShortWide,
  faLocationDot,
  faObjectGroup,
} from '@fortawesome/free-solid-svg-icons';
import { faExpandAlt, faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BatchCardExpandedContainer from 'src/components/BatchCardExpandedContainer';
import BatchPowderQualityStatusIcon from 'src/components/BatchPowderQualityStatusIcon';
import StatusColorCode from 'src/components/status-color-dot';
import TruncatedTitle from 'src/components/TruncatedTitle';
import { MATERIAL_BATCH_STATUS_VERBOSE, STATUS_COLOR_CODE_TYPES } from 'src/utils/constants';
import routes from 'src/utils/routes';
import { getRouteURI, getShortUuid } from 'src/utils/url';

export const BatchCardSmall = ({ batch, togglePreview, subLocation, shouldShowBatchLink }) => (
  <div className='batch-card-small'>
    <div className='batch-card-small-main-details'>
      <div className='batch-card-small-main-details-info'>
        <div className='d-flex align-items-center'>
          <p className='mb0 d-flex align-items-center'>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id='tooltip'>
                  <p className='mb0'>{MATERIAL_BATCH_STATUS_VERBOSE[batch.status]}</p>
                </Tooltip>
              }
            >
              <p className='d-flex align-items-center mb0'>
                <StatusColorCode
                  status={batch.status}
                  type={STATUS_COLOR_CODE_TYPES.BATCH_STATUS}
                  sizeClassName='small'
                />
              </p>
            </OverlayTrigger>
          </p>
          <span className='batch-card-small-main-details-title'>Batch</span>
        </div>
        <div>
          {shouldShowBatchLink ? (
            <Link
              className='batch-card-main-details-main-link'
              to={getRouteURI(routes.materialContainer, {}, { batch: batch.uuid })}
            >
              <FontAwesomeIcon icon={faExternalLink} className='spacer-right' />
              <p className='mb0'>{getShortUuid(batch.uuid)}</p>
            </Link>
          ) : (
            <p className='mb0 batch-card-main-details-main-link'>{getShortUuid(batch.uuid)}</p>
          )}
        </div>
      </div>
      {batch.powder_quality && (
        <div className='batch-card-small-main-details-powder-quality'>
          <BatchPowderQualityStatusIcon qualityStatus={batch?.powder_quality} />
        </div>
      )}
    </div>
    <div className='batch-card-small-secondary-details'>
      <div className='batch-card-small-center-details'>
        <p className='mt10 mb10 batch-card-small-secondary-details-units'>
          {batch.quantity} {batch.units}
          <div className='batch-card-header-secondary-icon'>
            <FontAwesomeIcon className='spacer-left' icon={faArrowUpShortWide} />
          </div>
        </p>
        <p className='mt10 mb10 batch-card-small-secondary-details-units'>
          {batch.usage_cycles}
          <div className='batch-card-header-secondary-icon'>
            <FontAwesomeIcon className='spacer-left' icon={faArrowsSpin} />
          </div>
        </p>
        <p className='mb10 batch-card-small-secondary-details-units'>
          <TruncatedTitle
            inline
            title={batch.materials[0].name}
            titleClassName='mb0'
            maxTextLength={25}
            maxTextLengthMobile={20}
          />
          <div className='batch-card-header-secondary-icon'>
            <FontAwesomeIcon className='spacer-left' icon={faObjectGroup} />
          </div>
        </p>
      </div>
      <div className='batch-card-small-right-details'>
        <div>
          <OverlayTrigger
            placement='left'
            overlay={
              <Tooltip id='tooltip'>
                <p className='mb5'>
                  <strong>Location:</strong> {batch.location_name}
                </p>
                <p className='mb5'>
                  <strong>Sub-Location:</strong> {subLocation || 'N/A'}
                </p>
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon={faLocationDot} className='text-white' />
          </OverlayTrigger>
        </div>
        <button type='button' className='btn btn-link btn-sm text-white' onClick={togglePreview}>
          <FontAwesomeIcon icon={faExpandAlt} />
        </button>
      </div>
    </div>
  </div>
);

BatchCardSmall.defaultProps = {
  shouldShowBatchLink: true,
};

BatchCardSmall.propTypes = {
  batch: PropTypes.shape({
    uuid: PropTypes.string,
    units: PropTypes.string,
    status: PropTypes.string,
    quantity: PropTypes.number,
    /* eslint-disable camelcase */
    powder_quality: PropTypes.string,
    usage_cycles: PropTypes.number,
    location_name: PropTypes.string,
    materials: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  }).isRequired,
  subLocation: PropTypes.string.isRequired,
  togglePreview: PropTypes.func.isRequired,
  shouldShowBatchLink: PropTypes.bool,
};

export const BatchCardPreview = ({
  batch,
  subLocation,
  shouldShowBatchLink,
  isExpanded,
  loadedPrinter,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  const togglePreview = () => setExpanded(prev => !prev);

  if (!batch) return null;

  return expanded ? (
    <BatchCardExpandedContainer
      batch={batch}
      togglePreview={togglePreview}
      subLocation={subLocation}
      loadedPrinter={loadedPrinter}
      shouldShowBatchLink={shouldShowBatchLink}
    />
  ) : (
    <BatchCardSmall
      batch={batch}
      togglePreview={togglePreview}
      subLocation={subLocation}
      shouldShowBatchLink={shouldShowBatchLink}
    />
  );
};

BatchCardPreview.defaultProps = {
  shouldShowBatchLink: true,
  isExpanded: false,
  loadedPrinter: null,
};

BatchCardPreview.propTypes = {
  batch: PropTypes.shape({}).isRequired,
  subLocation: PropTypes.string.isRequired,
  shouldShowBatchLink: PropTypes.bool,
  isExpanded: PropTypes.bool,
  loadedPrinter: PropTypes.shape({}),
};
