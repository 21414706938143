import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from 'src/utils/api';
import { API_RESOURCES, MATERIAL_BATCH_ACTIONS } from 'src/utils/constants';
import routes from 'src/utils/routes';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import { getSliderStep, validateMultipleZeros } from 'src/utils/validation';

import ActionPage from './_action-wrapper';

export const formatUnloadQuantityInMachineNumber = (number) => {
  if (!number || isNaN(number)) return 0;
  if (number % 1 === 0) { // Check if it's an integer
    // If it is an integer, return it as a string without decimal places
    return number.toString();
  } else {
    // If it's not an integer, return it as a string with two decimal places
    return number?.toFixed(2);
  }
};

export const renderRemainingValueClass = (remainingQuantity) => {
  if (remainingQuantity === 0) {
    return 'text-success';
  }

  if (remainingQuantity < 0) {
    return 'text-danger';
  }

  return 'text-warning';
};

export const preventNegativeOnKeyDownValue = (event) => {
  if (event.key === '-') {
    event.preventDefault();
  }
};

const MachineUnloadHopper = ({ user }) => {
  const { uuid: batchUUID } = useParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [batch, setBatch] = useState(null);
  const [unloadHopperValue, setUnloadHopperValue] = useState(0);
  const [subLocation, setSubLocation] = useState(undefined);
  const [remainingQuantity, setRemainingQuantity] = useState(undefined);

  const navigate = useNavigate();

  const getInitialData = async () => {
    setIsLoading(true);
    try {
      const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`).json();
      const subLocation = await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batch.sub_location)}/`).json();
      setBatch(batch);
      setSubLocation(subLocation);
      setRemainingQuantity(batch.quantity);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => void getInitialData(), [batchUUID]);

  const onActionUnloadedHopper = async (batchUri, value) => {
    let actionResult = null;
    // TODO:
    // After the update, make sure that the Batch created from this Unload has had 0 cycles added to it's Usage Cycles
    // (Unloading "Unused" material should NOT increase the usage cycles for that unloaded material.).
    try {
      const payload = {
        /* eslint-disable camelcase */
        source_batch: batchUri,
        action_type: MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER,
        metadata: {
          unload_amount: value,
        },
      };
      setSubmitting(true);
      actionResult = await materialActionRequest(payload).json();
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    const { metadata: { resulting_batch: unloadedBatch } } = actionResult;

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(unloadedBatch) },
      {
        action: MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER,
        batch: getUuid(unloadedBatch),
        quantity: value,
        remainingQuantity,
        subLocation: subLocation.name,
        printerUri: batch.at_machine,
      }));

    return new Promise(() => {
    });
  };

  const materialActionRequest = (payload) => {
    return api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
      json: payload,
    });
  };

  const setRemainingQuantityNumberHandler = (changeEvent) => {
    validateMultipleZeros(changeEvent);

    const inputValue = changeEvent.target.value;
    const inputQuantity = inputValue === '' ? '' : parseFloat(inputValue);

    if (inputQuantity >= 0) {
      setUnloadHopperValue(inputQuantity);
      setRemainingQuantity(parseFloat((batch.quantity - inputQuantity).toFixed(2)));
    }
  };

  const setRemainingQuantityRangeHandler = (changeEvent) => {
    const inputValue = changeEvent.target.value;
    const inputQuantity = inputValue === '' ? 0 : parseFloat(inputValue);
    if (inputQuantity >= 0) {
      setUnloadHopperValue(inputQuantity);
      setRemainingQuantity(parseFloat((batch.quantity - inputQuantity).toFixed(2)));
    }
  };

  const clearInputOnClick = () => {
    if (unloadHopperValue === 0) {
      setUnloadHopperValue('');
    }
  };

  const remainingPercentage = batch?.quantity ? Math.round(remainingQuantity / batch.quantity * 100) : undefined;

  return (
    <ActionPage
      id={batchUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER}
      isLoading={isLoading}
    >
      {batch &&
        <>
          <div className="alert alert-info" role="alert">
            <b>Please specify unused amount to remove. Unused material will not receive an additional Usage Cycle.</b>
          </div>

          <p className="font-bold">Total Quantity in Machine:&nbsp;
            <span className={renderRemainingValueClass(remainingQuantity)}>
              {formatUnloadQuantityInMachineNumber(remainingQuantity)} ({batch?.units})
            </span>
          </p>
          <hr className="flex-grow-1" />

          <div className="alert">
            <div>
              <label>Quantity:</label>
              &nbsp;<input
                name="remainingQuantity"
                min={0}
                style={{ width: '70px' }}
                max={batch?.quantity}
                type="number"
                value={unloadHopperValue}
                onKeyDown={preventNegativeOnKeyDownValue}
                onChange={setRemainingQuantityNumberHandler}
                onClick={clearInputOnClick}
            />
              &nbsp;<span>{batch?.units} ({remainingPercentage}%)</span>
            </div>
            <div>
              <span>0 ({batch?.units})</span>
              &nbsp;<input
                name="remainingQuantityRange"
                min={0}
                step={getSliderStep(batch.quantity)}
                max={batch.quantity}
                type="range"
                value={unloadHopperValue}
                onChange={setRemainingQuantityRangeHandler}
            />
              &nbsp;<span>{batch?.quantity} ({batch?.units})</span>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary btn-block"
            disabled={isSubmitting || (remainingQuantity < 0 || remainingQuantity === batch?.quantity)}
            onClick={() => onActionUnloadedHopper(batch.uri, unloadHopperValue)}
          >
            Save
          </button>
        </>}
    </ActionPage>
  );
};

MachineUnloadHopper.propTypes = {
  user: userPropType,
};

MachineUnloadHopper.defaultProps = {
  user: null,
};

export default MachineUnloadHopper;
