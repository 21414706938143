import { faExclamationTriangle, faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getRouteURI, getUuid } from 'src/utils/url';

import routes from '../../../../utils/routes';
import WorkInstructions from './_work-instructions';

const PrintWithWorkInstructions = ({
                                     print,
                                     piece,
                                     lineItem,
                                     model,
                                     checklist,
                                     reportValuesByUUID,
                                     onStepInput,
                                     onStepChange,
                                     uploadingState,
                                     sendNCReviewForPiece,
                                     runTransformation,
                                     setSentToNCRItems,
                                     sentToNCRItems,
                                     savedReportsByPrintUri,
                                     hasIncorrectValues,
                                     savedReportValues,
                                     setIsAbleToCompleteRun,
                                     reportFilesPrintURI,
                                     isFileUploading,
                                     label,
                                   }) => {
  const reportFilesByPrint = reportFilesPrintURI[print.uri] || {};

  return (
    <div key={print.uri} className="mb-4">
      {
        // "no-gutters" - padding is removed to cover WI content width decrease below
      }
      <div className={`${!model ? 'd-flex justify-content-center' : ''} row no-gutters work-instructions-body`}>
        {model && (
          <div className="col-5 work-instructions-body-model">
            {
              // Adding spacing between columns manually
            }
            <div className="thumbnail-box rounded-sm mr-3">
              <div className="thumbnail-box-inside">
                <img src={model.snapshot_content} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        )}
        <div className="col-7 work-instructions-body-content">
          {
            // margin is needed for `Next` button to fit into the page
            // (issue is visible on screens < 600px)
          }
          <div className="mr-3 work-instructions-body-content-text">
            <div>Order: {print.name}</div>
            <div>Line Item: <span className="line-item-instructions">{lineItem && lineItem.name}</span></div>
            {piece && piece.name &&
              <div>Piece:&nbsp;
                <Link to={getRouteURI(routes.pieceOverview, { uuid: getUuid(piece.uri) })}>
                  {piece.name}
                </Link>
                <Link to={getRouteURI(routes.pieceOverview, { uuid: getUuid(piece.uri) })}>
                  <FontAwesomeIcon className="link-icon" icon={faLink} />
                </Link>
                {label &&
                  <OverlayTrigger
                    placement="bottom"
                    overlay={(
                      <Tooltip>
                        <b>{label.name}</b>
                        <p>{label.description}</p>
                      </Tooltip>)}
                  >
                    <span className="ml-1 warning-color">
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </span>
                  </OverlayTrigger>}
              </div>}

            {/* Pice overview link  */}
            {checklist ? (
              <WorkInstructions
                instructions={checklist.work_instructions}
                values={reportValuesByUUID}
                savedValues={savedReportValues}
                uploading={uploadingState}
                beforeChange={onStepChange}
                print={print}
                sendNCReviewForPiece={sendNCReviewForPiece}
                runTransformation={runTransformation}
                setSentToNCRItems={setSentToNCRItems}
                sentToNCRItems={sentToNCRItems}
                savedReportsByPrintUri={savedReportsByPrintUri}
                hasIncorrectValues={hasIncorrectValues}
                setIsAbleToCompleteRun={setIsAbleToCompleteRun}
                reportFilesByPrint={reportFilesByPrint}
                isFileUploading={isFileUploading}
                onStepInput={onStepInput}
              />

            ) : (
              <div>No Work Instructions for the Piece</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PrintWithWorkInstructions.propTypes = {
  print: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    piece: PropTypes.string.isRequired,
  }).isRequired,
  piece: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  lineItem: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  model: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    snapshot_content: PropTypes.string.isRequired,
  }),
  checklist: PropTypes.shape({
    // eslint-disable-next-line camelcase
    work_instructions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  reportValuesByUUID: PropTypes.shape({}),
  onStepInput: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  uploadingState: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  sendNCReviewForPiece: PropTypes.func.isRequired,
  runTransformation: PropTypes.arrayOf(PropTypes.shape({})),
  setSentToNCRItems: PropTypes.func.isRequired,
  sentToNCRItems: PropTypes.arrayOf(PropTypes.string),
  savedReportsByPrintUri: PropTypes.shape({}).isRequired,
  hasIncorrectValues: PropTypes.bool.isRequired,
  savedReportValues: PropTypes.shape({}).isRequired,
  setIsAbleToCompleteRun: PropTypes.func.isRequired,
  reportFilesPrintURI: PropTypes.shape({}).isRequired,
  isFileUploading: PropTypes.bool.isRequired,
  label: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

PrintWithWorkInstructions.defaultProps = {
  model: null,
  reportValuesByUUID: {},
  uploadingState: false,
  checklist: null,
  runTransformation: [],
  label: null,
};

export default PrintWithWorkInstructions;
