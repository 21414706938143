import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ActionContext from 'src/components/action-context';
import MaterialBatchSuccessCardDetails from 'src/components/material-batch-success-card-details';
import { preventNegativeOnKeyDownValue } from 'src/pages/material-batch/[uuid]/action/machine-unload-hopper';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS, PERMANENT_CONTAINER_ACTIONS,
} from 'src/utils/constants';
import routes from 'src/utils/routes';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import { getSliderStep, validateMultipleZeros } from 'src/utils/validation';

import ActionPage from './_action-wrapper';

const Sieve = ({ user }) => {
  const { uuid: batchUUID } = useParams();
  const [batch, setBatch] = useState(undefined);
  const [batchPermanentContainerData, setBatchPermanentContainerData] = useState({
    container: null,
    location: null,
    subLocation: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [remainingQuantity, setRemainingQuantity] = useState('');
  const navigate = useNavigate();

  const isEmptySieveValue = remainingQuantity === undefined || remainingQuantity === '';
  const isIncorrectSieveValue = isNaN(remainingQuantity)
    || remainingQuantity > batch?.quantity;

  const {
    containerActionData,
  } = useContext(ActionContext);

  const resetSieveBatchIntoPermanentContainerState = () => {
    // Reset the context state to its initial state
    containerActionData.resetActionContainerState();
  };

  const fetchInitialData = async () => {
    try {
      const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`).json();
      if (batch && batch.containers.length === 1) {
        /* We have only one container attached to the batch,
         and we need to fetch the container to check if it is disposable or not. */

        const permanentContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${getUuid(batch.containers[0])}/`).json();

        /* Only if the Container is not disposable and has the same batch - we can consider it suitable to
           sieve the batch amount to this container */

        if (!permanentContainer?.disposable) {
          const [permanentContainerLocation, permanentContainerSubLocation] = await Promise.all([
            api.get(`${API_RESOURCES.LOCATION}/${getUuid(permanentContainer.location)}/`).then(response => response.json()),
            api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(permanentContainer.sub_location)}/`).then(response => response.json()),
          ]);

          setBatchPermanentContainerData({
            container: permanentContainer,
            location: permanentContainerLocation,
            subLocation: permanentContainerSubLocation,
          });
        }

      }
      setBatch(batch);
    } catch (error) {
      setError(error.name);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => void fetchInitialData(), []);

  const setRemainingQuantityHandler = (event) => {
    validateMultipleZeros(event);

    const validatedValue = event.target.value;
    const inputQuantity = validatedValue === '' ? '' : parseFloat(validatedValue);
    setRemainingQuantity(inputQuantity);
  };


  const onActionSieve = async (batchUri, remainingQuantity) => {
    const payload = {
      /* eslint-disable camelcase */
      metadata: {
        remaining_quantity: remainingQuantity,
      },
      action_type: MATERIAL_BATCH_ACTIONS.SIEVE,
      source_batch: batchUri,
    };

    setSubmitting(true);
    await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
      json: payload,
    });
  };

  const sieveIntoSameContainer = async (batchUri, remainingQuantity) => {
    try {
      await onActionSieve(batchUri, remainingQuantity);

      const containerizeActionPayload = [{
        uri: batchPermanentContainerData.container.uri,
        quantity: remainingQuantity,
      }];

      const containerizePayload = {
        action_type: MATERIAL_BATCH_ACTIONS.CONTAINERIZE_BATCH,
        source_batch: batchUri,
        metadata: {
          containers: containerizeActionPayload,
        },
      };

      await api.post('material-batch-action/', {
        json: containerizePayload,
      });

    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(batchUri) },
      { action: MATERIAL_BATCH_ACTIONS.SIEVE, permanentContainerUri: batchPermanentContainerData.container.uri }));

    return new Promise(() => {
    });
  };

  const sieveWithoutContainer = async (batchUri, remainingQuantity) => {
    try {
      await onActionSieve(batchUri, remainingQuantity);
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(batchUri) },
      { action: MATERIAL_BATCH_ACTIONS.SIEVE }));

    return new Promise(() => {
    });
  };

  const remainingPercentage = batch?.quantity
    ? Math.round(remainingQuantity / batch.quantity * 100)
    : undefined;

  return (
    <ActionPage
      id={batchUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.SIEVE}
      isLoading={isLoading}
    >
      <div className="alert alert-info" role="alert">
        <b>
          <span className="mb15 d-block">You are sieving batch {getShortUuid(batch?.uri)}.</span>
          Please record the amount of material remaining after completing the sieve action.
          After being sieved, the batch will be marked as suitable for loading and blending with other batches.
        </b>
      </div>
      <div className="alert">
        <div>
          &nbsp;<input
            name="remainingQuantity"
            min="0"
            className="sieve-input-field"
            max={batch?.quantity}
            type="number"
            placeholder="Enter Remaining Quantity"
            value={remainingQuantity}
            onKeyDown={preventNegativeOnKeyDownValue}
            onChange={setRemainingQuantityHandler}
        /> &nbsp;
          <span>{batch?.units}
            <span className={isIncorrectSieveValue ? 'text-danger' : ''}>&nbsp;({remainingPercentage || 0}%)</span>
          </span>
          {
            isIncorrectSieveValue && (
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip id="sieve-value-exceeded">
                    Remaining sieve quantity exceeds the batch quantity
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon icon={faTriangleExclamation} color="#FFC008" className="spacer-left" />
              </OverlayTrigger>
            )
          }
        </div>
        <div>
          <span>0 ({batch?.units})</span>
          &nbsp;<input
            name="remainingQuantityRange"
            min="0"
            step={getSliderStep(batch?.quantity)}
            max={batch?.quantity}
            type="range"
            value={remainingQuantity || 0}
            onChange={setRemainingQuantityHandler}
        />
          &nbsp;<span>{batch?.quantity} ({batch?.units})</span>
        </div>
      </div>

      {
        batchPermanentContainerData.container && (
          <>
            <p className="mb15"><strong>Current container:</strong></p>
            <MaterialBatchSuccessCardDetails
              permanentContainer={batchPermanentContainerData.container}
              batch={batch}
              customQuantity={batchPermanentContainerData.quantity}
              customLocation={batchPermanentContainerData.location?.name}
              subLocation={batchPermanentContainerData.subLocation?.name}
              customMainStyle={{ marginTop: '0' }}
            />
            <button
              type="submit"
              className="btn btn-lg btn-primary btn-block"
              disabled={isSubmitting || isIncorrectSieveValue || isEmptySieveValue}
              onClick={() => sieveIntoSameContainer(batch.uri, remainingQuantity)}
            >
              Sieve into current container
            </button>
          </>
        )
      }

      <Link
        to={getRouteURI(routes.scan,
          {},
          {
            'material-batch': batch?.uri,
            entity: API_RESOURCES.MATERIAL_CONTAINER,
            action: PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH,
            sieveQuantity: remainingQuantity,
            initialSieveAction: true,
          })}
        className="link-btn"
      >
        <button
          type="button"
          disabled={isSubmitting || isIncorrectSieveValue || isEmptySieveValue}
          className="btn btn-lg btn-secondary btn-block"
          onClick={resetSieveBatchIntoPermanentContainerState}
        >
          Sieve into another container
        </button>
      </Link>

      <button
        type="submit"
        className="btn btn-lg btn-light btn-block link-btn"
        disabled={isSubmitting || isIncorrectSieveValue || isEmptySieveValue}
        onClick={() => sieveWithoutContainer(batch.uri, remainingQuantity)}
      >
        Sieve without container
      </button>
      <Link to={`/material-container?batch=${batchUUID}`}>
        <button type="button" className="btn btn-default btn-action">
          Cancel
        </button>
      </Link>
    </ActionPage>
  );
};

Sieve.propTypes = {
  user: userPropType,
};

Sieve.defaultProps = {
  user: null,
};

export default Sieve;
